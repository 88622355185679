<template>
  <div class="charts" id="charts">

  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: {
        tooltip: {},
        legend: {},
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [],
      },
      dataZoom: {
        PC: {
          type: 'slider',
          minValueSpan: 16,
        },
        MOBIE: {
          type: 'slider',
          maxValueSpan: 8,
          minValueSpan: 8,
        }
      },
      echart: null,
    }
  },
  watch: {
    active: {
      handler(val) {
        if (val == 'chart') {
          this.$nextTick(() => {
            this.initCharts();
            this.handerData(this.tableData);
          })
        }
      },
      deep: true,
      // immediate: true
    },
    '$store.getters.deviceInfo': {
      handler(val) {
        this.$nextTick(() => {
          if (this.echart) {
            this.echart.resize();
            this.resetOption();
          }
        });
      },
      deep: true,
      immediate: true,
    },

  },
  mounted() {

  },
  methods: {
    initCharts() {
      if (!this.echart) {
        this.echart = echarts.init(
          document.getElementById("charts")
        );
      }

    },
    resetOption() {
      if (this.echart) {
        this.option.dataZoom = this.dataZoom[this.$store.getters.deviceInfo.type]
        this.echart.setOption(this.option);
      }
    },
    handerData(val) {
      if (!val.length) {
        return;
      }
      let xAxis_data = [];
      let b1L1 = {
        name: "b1L1",
        type: "bar",
        barMaxWidth: "24",
        barWidth: "6",
        data: []
      };
      let b2L2 = {
        name: "b2L2",
        type: "bar",
        barMaxWidth: "24",
        barWidth: "6",
        data: []
      };
      let b3L3 = {
        name: "b3L3",
        type: "bar",
        barMaxWidth: "24",
        barWidth: "6",
        data: []
      };
      val.forEach(item => {
        xAxis_data.push(item.id);
        b1L1.data.push(item.b1L1);
        b2L2.data.push(item.b2L2)
        b3L3.data.push(item.b3L3)
      });
      this.option.series = [
        b1L1, b2L2, b3L3
      ]
      this.option.xAxis.data = xAxis_data;
      this.resetOption();
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  height: 500px;
}
</style>
<template>
  <div class="receiverList">
    <div class="query">
      <div class="input-width">
        <el-input
          :placeholder="$t('public.please_input')"
          v-model="queryData.keyWords"
          @keyup.enter.native="searchList"
        >
          <template slot="prepend">
            <el-select
              v-model="queryData.type"
              style="width: 120px"
              @change="selectChange"
            >
              <el-option
                v-for="item in $t('option.receiverList.query_options')"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template slot="append">
            <el-button icon="el-icon-search" @click="searchList"></el-button>
          </template>
        </el-input>
      </div>
      <div style="display: flex; align-items: center; max-width: 400px">
        <div style="margin-left: 40px; min-width: 80px">连接状态：</div>
        <el-select
          v-model="statusSelect"
          placeholder="请选择"
          @change="statusChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div class="pageTitle" style="line-height: 40px">
        {{ $t("label.receiverList.page_title") }}
      </div>
      <div class="operate-btn">
        <el-button
          type="success"
          style="background-color: #539d56; border-color: #539d56"
          class="btn"
          size="small"
          @click="distribution"
          >{{ $t("label.receiverList.distribution") }}</el-button
        >
        <el-button
          class="btn"
          size="small"
          type="success"
          style="
            background-color: #539d56;
            border-color: #539d56;
            margin-right: 10px;
          "
          @click="toggleShowAddSn"
        >
          {{ $t("dialog.userList.add_equip") }}
        </el-button>
        <el-upload
          class="upload-demo"
          action=""
          :limit="1"
          :file-list="fileList"
          :show-file-list="false"
          :http-request="uploadReceiverFile"
        >
          <el-button
            type="success"
            style="background-color: #539d56; border-color: #539d56"
            class="btn"
            size="small"
          >
            {{ $t("dialog.userList.batch_add_equip") }}
          </el-button>
        </el-upload>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        ref="tableData"
        stripe
        @selection-change="handleSelectionChange"
        :height="tableHeight"
      >
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.receiverList.${column}`)"
          align="center"
          :min-width="column == 'operate' ? '150px' : '100px'"
        >
          <template slot-scope="{ row }">
            <template v-if="column == 'status'">
              <span v-if="row.status === 1" style="color: #3e916a">
                {{ $t("label.receiverList.link") }}
              </span>
              <span v-else style="color: #ce0000">
                {{ $t("label.receiverList.break") }}
              </span>
            </template>
            <template v-else-if="column == 'operate'">
              <el-button
                type="success"
                size="small"
                @click="jumpDetail(row)"
                :style="
                  row.status == 1
                    ? 'background-color: #3E916A;border-color: #3E916A'
                    : ''
                "
                :disabled="row.status != 1"
                >{{ $t("label.receiverList.config") }}</el-button
              >
            </template>
            <template v-else>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        :total="total"
        popper-class="mySelectType"
        :current-page="page"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <EditSn
      :show="showAddSn"
      @toggleShow="toggleShowAddSn"
      @refush="getReceiverList"
    ></EditSn>
    <DisReceiver
      :show="showDis"
      :selectValue="selectValue"
      @searchTableData="searchTableData"
      @toggleShow="closeDis"
      @refush="getReceiverList"
    ></DisReceiver>
  </div>
</template>

<script>
// import { receiverList, receiverAddList } from "@/api/index";
import EditSn from "../user/component/editSN.vue";
import DisReceiver from "./component/disReceiverList.vue";
export default {
  data() {
    return {
      options: [
        {
          value: "1",
          label: "连接",
        },
        {
          value: "0",
          label: "断开",
        },
      ],
      statusSelect: "",
      queryData: {
        type: "sn",
        keyWords: "",
      },
      columns: ["index", "sn", "status", "equip_ownership", "operate"],
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      searchType: "sn",
      sn: "",
      ownership: "",
      fileList: [],
      showAddSn: false,
      showDis: false,
      choose: [],
      selectValue: [],
      tableHeight: 450,
    };
  },
  components: { EditSn, DisReceiver },
  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        // console.log(val);
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // receiverList
    this.getReceiverList();
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
        window.innerHeight - this.$refs.tableData.$el.offsetHeight + 180;
      this.tableHeight = height;
    });
  },
  methods: {
    //
    statusChange(e) {
      this.getReceiverList();
    },
    // 跳转流速球详情
    jumpDetail(e) {
      // 在新标签页中打开
      let page = this.$router.resolve({
        name: "info", // 路由名称
        // query: { id: id, e: 0 }, // 传递参数
      });
      window.open(page.href, "_blank");
    },
    // 改变搜索条件搜索
    selectChange(e) {
      this.searchType = e;
    },
    // 搜索
    searchList() {
      this.page = 1;
      if (this.searchType === "sn") {
        (this.sn = this.queryData.keyWords), (this.ownership = "");
      } else {
        (this.sn = ""), (this.ownership = this.queryData.keyWords);
      }
      this.getReceiverList();
    },
    getReceiverList() {
      // let param = {
      //   page: this.page,
      //   page_size: this.pageSize,
      //   sn: this.sn,
      //   belong: this.ownership,
      // };
    },
    // 批量添加设备
    uploadReceiverFile(file) {

      let formData = new FormData();
      formData.append("file", file.file);
      // receiverAddList(formData).then((res) => {
      //   // console.log(res,"-=-=-=")
      //   if (res.code === 200) {
      //     this.$message({
      //       type: "success",
      //       message: this.$t("label.receiverList.add_info_success"),
      //       duration: 2000,
      //     });
      //     this.getReceiverList();
      //   } else {
      //     this.$message({
      //       type: "warning",
      //       message: res.msg,
      //       duration: 2000,
      //     });
      //   }
      //   this.fileList = [];
      // });
      this.page = 1;
    },
    // 选择设备
    handleSelectionChange(e) {
      this.choose = e;
    },
    // 分配流速球
    distribution() {
      this.selectValue = [];
      if (this.choose && this.choose.length === 0) {
        this.$message({
          type: "warning",
          message: this.$t("label.receiverList.choose_receiver_warning"),
          duration: 2000,
        });
      } else {
        this.showDis = true;
        this.choose.forEach((item) => {
          this.selectValue.push(item.id);
        });
      }
    },
    toggleShowAddSn() {
      this.showAddSn = !this.showAddSn;
    },
    closeDis() {
      this.showDis = false;
    },
    // 分页
    sizeChange(e) {
      this.pageSize = e;
      this.getReceiverList();
    },
    // 页数
    currentChange(e) {
      this.page = e;
      // console.log("页数", e);
      this.getReceiverList();
    },
    // 提交清除数据的选中状态
    searchTableData() {
      this.$refs.tableData.clearSelection(); //清除选中的数据
    },
  },
};
</script>

<style lang="scss" scoped>
.receiverList {
  padding: 20px;

  .query {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .input-width {
    max-width: 400px;
  }
  .operate-btn {
    display: flex;
  }
  .btn {
    min-width: 80px;
    height: 32px;
  }
  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }
  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: 0px;
    right: 20px;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding-bottom: 15px;
  }
  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    .el-select .el-input__inner:focus {
      border-color: #3b9167;
    }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }
    .el-button--success.is-disabled {
      background-color: #c0c4cc;
      border-color: #c0c4cc;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }
}
</style>
<style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>

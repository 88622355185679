<template>
  <div class="locationInfo" :class="[deviceInfo.type]">
    <div
      class="flex locationInfo-item"
      v-for="item in detailList"
      :key="item.label"
    >
      <span class="locationInfo-item-label"> {{ $t(item.label) }}: </span>
      <span class="locationInfo-item-value">
        <template v-if="item.value == 'loc'">
          {{
            detail[item.value] === undefined || detail[item.value] === null
              ? "暂无"
              : $t("label.info.status_option")[detail[item.value]]
          }}
        </template>
        <template v-else>
          {{
            detail[item.value] === undefined || detail[item.value] === null
              ? "暂无"
              : detail[item.value]
          }}{{ item.unit }}
        </template>
      </span>
    </div>
    <!-- <div class="locationInfo-btns">
			<el-button
				v-for="btn in btns"
				:key="btn.type"
				type="success"
				class="btn cancel"
				size="small" 
				@click="openMessageDialog(btn.type)"
			>
				{{ $t(btn.label) }}
			</el-button>
		</div> -->
    <!-- <ConfirmDialog
			:title="message.title"
			:message="message.content"
			:show="showMessageDialog"
			@toggleShow="closeDia"
			@submit="closeDia"
			@option="toReceiverDiagnosis"
		></ConfirmDialog> -->
  </div>
</template>

<script>
// import ConfirmDialog from "../../components/public/confirmDialog";
import {} from "@/api/index";
export default {
  // components: { ConfirmDialog },
  data() {
    return {
      detailList: [
        {
          label: "label.info.lng",
          value: "lon",
          unit: "°",
        },
        {
          label: "label.info.lat",
          value: "lat",
          unit: "°",
        },
        {
          label: "label.info.hight",
          value: "height",
          unit: "m",
        },
        {
          label: "label.info.status",
          value: "loc",
        },
        {
          label: "label.info.satellite_num",
          value: "satellite_num",
        },
        {
          label: "label.info.HDOP",
          value: "hdop",
        },
        // {
        //   label: "label.info.local_time",
        //   value: "localtime",
        // },
        {
          label: "label.info.UTC_time",
          value: "utc_time",
        },
      ],
      detail: {
        model: "",
        sn: "",
        pn: "",
        base_fireware_version: "",
        net_fireware_version: "",
        inter_fireware_version: "",
      },
      // showMessageDialog: false,
      message: {
        title: this.$t("public.tip"),
        content: "",
      },
      // btns: [
      // 	{
      // 		label: "label.info.reset",
      // 		type: "reset",
      // 	},
      // 	{
      // 		label: "label.info.init",
      // 		type: "init",
      // 	},
      // 	{
      // 		label: "label.info.restart",
      // 		type: "restart",
      // 	},
      // ],
      // btnType:'',
      // message_success: "",
      // message_err: "",
    };
  },
  mounted() {},
  activated() {
    this.setTimer();
  },
  deactivated() {
    this.setTimer(true);
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  methods: {
    setTimer(clear = false) {
      if (clear) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.getReceiverInfo();
      this.timer = setInterval(() => {
        this.getReceiverInfo();
      }, 5000);
    },
		// 获取流速球信息集
		getReceiverInfo() {
			let param = {
				sn: this.$store.getters.currentSn,
        model: 1
			}
			this.$api.deviceDetail(param).then(res => {
				// console.log('111',res)
				if (res.code === 200) {
					let temp = this.$tool.transformDate(new Date(),'-')
					this.detail ={
						... res.data,
						localtime:`${temp.nowDate} ${temp.nowTime}`
					};
					this.$store.commit("SET_RECEIVER", res.data)
					// console.log(this.detail)
				}
			})
		},
		// openMessageDialog(type) {
		// 	this.btnType = type
		// 	switch (type) {
		// 		case "reset":
		// 			this.message.content = this.$t("message.info.reset");
		// 			break;
		// 		case "init":
		// 			this.message.content = this.$t("message.info.init");
		// 			break;
		// 		case "restart":
		// 			this.message.content = this.$t("message.info.restart");
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// 	if (this.message.content) {
		// 		this.showMessageDialog = true;
		// 	}
		// },
		// closeDia() {
		// 	this.showMessageDialog = false;
		// },
		// 流速球诊断
		// toReceiverDiagnosis() {
		// let param = [{
		// 	deviceid: JSON.parse(localStorage.getItem('receiverInfo')).sn, //设备sn号
		// 	num: 1
		// }]
		// let message = ''
		// if(this.btnType === 'reset'){
		// 	this.message_success = this.$t('label.info.reset_success')
		// 	this.message_err = this.$t('label.info.reset_err')
		// 	param[0].num = 1
		// }else if(this.btnType === 'init'){
		// 	this.message_success = this.$t('label.info.init_success')
		// 	this.message_err = this.$t('label.info.init_err')
		// 	param[0].num = 4
		// }else if(this.btnType === 'restart'){
		// 	this.message_success = this.$t('label.info.restart_success')
		// 	this.message_err = this.$t('label.info.restart_err')
		// 	param[0].num = 2
		// }
		// console.log(param)
		// receiverDiagnosis(param).then(res => {
		// 	if(res.data[0]?.status){
		// 		this.$message({
		// 			type:'success',
		// 			message:this.message_success,
		// 			duration: 2000
		// 		})
		// 	}else {
		// 		this.$message({
		// 			type:'warning',
		// 			message:res.msg,
		// 			duration: 2000
		// 		})
		// 	}
		// })
		// }
	},
};
</script>

<style lang="scss" scoped>
.locationInfo {
  padding: 20px;

  &-item {
    line-height: 32px;
    font-size: 14px;

    &-value {
      line-height: 32px;
    }
  }

  &-btns {
    justify-content: center;
    margin-top: 30px;

    .btn {
      min-width: 110px;
      height: 32px;
    }

    .save {
      background-color: $main_color;
      border-color: $main_color;
    }

    .cancel {
      border-color: $main_color;
      background-color: #fff;
      color: $main_color;
    }
  }
}

.MOBIE {
  .locationInfo-item {
    justify-content: space-between;

    & > span {
      width: 50%;
    }

    &-value {
      text-align: right;
      word-break: break-all;
    }

    &-label {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
}
</style>
<template>
	<div class="satelliteConfig">
		<div class="satelliteConfig-form">
			<el-form
				ref="form"
				:model="form"
				label-width="120px"
				label-position="left"
				:rules="rules"
			>
				<el-form-item prop="cutoff_angle" :label="$t('label.satelliteConfig.cutoff_angle') + '：'">
					<el-input
						style="width: 100%"
						v-model="form.cutoff_angle"
						:placeholder="$t('label.satelliteConfig.cutoff_angle')"
					></el-input>
				</el-form-item>
				<div
					class="flex satellites"
					v-for="satellite in satellites"
					:key="satellite.label"
				>
					<div class="satellites-label" style="width: 120px">
						{{ satellite.label }}
					</div>
					<div class="satellites-radio">
						<el-radio-group v-model="satellite.value">
							<el-radio :label="true">{{
								$t("label.satelliteConfig.enable")
							}}</el-radio>
							<el-radio :label="false">{{
								$t("label.satelliteConfig.forbidden")
							}}</el-radio>
						</el-radio-group>
					</div>
				</div>
				<el-form-item
					:label="$t('label.satelliteConfig.difference_age') + '：'"
					prop="difference_age"
				>
					<el-input
						style="width: 100%"
						v-model="form.difference_age"
						:placeholder="$t('label.satelliteConfig.difference_age')"
					></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="flex model-btns">
			<el-button type="primary" class="btn save" size="small" @click="save">
				{{ $t("label.model.save") }}
			</el-button>
			<el-button type="success" class="btn cancel" size="small" @click="cancel">
				{{ $t("label.model.cancel") }}
			</el-button>
		</div>
	</div>
</template>

<script>
	import { receiverProConfig, IOList } from "@/api/index"
	export default {
		data() {
			var cutoffAngle = (rule, value, callback) => {
				// console.log(value)
				if (Number(value)>=0 && Number(value)<=90){
					callback();
				} else {
					return callback(new Error('请输入0-90之间的整数！'));
				}
			}
			var differenceAge = (rule, value, callback) => {
				// console.log(value)
				if (Number(value)>=1 && Number(value)<=600){
					callback();
				} else {
					return callback(new Error('请输入1-600之间的整数！'));
				}
			}
			return {
				formItem: [
					{
						label: "label.model.lonlat_format",
						key: "lonlat_format",
						inputType: "select",
						selectKey: "option.model.lonlat_format_options",
					},
					{
						label: "label.model.longitude",
						key: "longitude",
						inputType: "input",
					},
					{
						label: "label.model.latitude",
						key: "latitude",
						inputType: "input",
					},
					{
						label: "label.model.elevation",
						key: "elevation",
						inputType: "input",
					},
				],
				satellites: [
					{
						label: "BEIDOU",
						value: true,
					},
					{
						label: "GLONASS",
						value: "",
					},
					{
						label: "GPS",
						value: "",
					},
					{
						label: "GALILEO",
						value: "",
					},
					{
						label: "QZSS",
						value: "",
					},
					{
						label: "SBAS",
						value: "",
					},
				],
				form: {
					start_model: "",
					cutoff_angle: "10", // 卫星截至角
					difference_age: 20, // 差分龄期
				},
				rules: {
					cutoff_angle: [
						{ validator: cutoffAngle, trigger: 'blur' }
					],
					difference_age: [
						{ validator: differenceAge, trigger: 'blur' }
					],
				}
			};
		},
		activated(){
			this.initData()
			this.$refs['form'].clearValidate()
		},
		methods:{
			initData(){
				// let param = {
				// 	sn: JSON.parse(localStorage.getItem('receiverInfo')).sn
				// }
				// IOList(param).then(res=>{
				// 	console.log(res)
				// 	let data = []
				// 	if(res.code === 200){
				// 		data = res.data.config_list.filter(item=>{
				// 			return item.server_id == 0
				// 		})
				// 		this.form.difference_age = data[0].cfi == 255 ? 20 : data[0].cfi // 差分龄期
				// 		this.form.cutoff_angle = data[0].sate_angle // 卫星截止角
				// 		this.satellites[0].value = data[0].beidou== 1 ? true : false
				// 		this.satellites[1].value = data[0].glonass== 1 ? true : false
				// 		this.satellites[2].value = data[0].gps== 1 ? true : false
				// 		this.satellites[3].value = data[0].galileo== 1 ? true : false
				// 		this.satellites[4].value = data[0].qzss== 1 ? true : false
				// 		this.satellites[5].value = data[0].sbas== 1 ? true : false
				// 		// console.log(data)
				// 	}
					
				// })
			},
			// 保存
			save(){
				this.$refs['form'].validate((valid) => {
					if (valid) {
						// let param = {
						// 	deviceid:[JSON.parse(localStorage.getItem('receiverInfo')).sn],
						// 	pos_config:{
						// 		sate_angle:Number(this.form.cutoff_angle), // 卫星截止角
						// 		beidou:this.satellites[0].value ? 1 : 0,
						// 		glonass:this.satellites[1].value ? 1 : 0,
						// 		gps:this.satellites[2].value ? 1 : 0,
						// 		galileo:this.satellites[3].value ? 1 : 0,
						// 		qzss:this.satellites[4].value ? 1 : 0,
						// 		sbas:this.satellites[5].value ? 1 : 0,
						// 		cfi:Number(this.form.difference_age), // 差分龄期
						// 	},
						// }
						// // console.log(param)
						// receiverProConfig(param).then(res=>{
						// 	if(res.code === 200){
						// 		this.$message({
						// 			// message: '保存修改成功！',
						// 			message: this.$t('label.satelliteConfig.save_success_info'),
						// 			type: 'success',
						// 			duration: 2000
						// 		});
						// 		this.initData()
						// 	}else {
						// 		// this.$message.error('网络错误，请重新保存！');
						// 		this.$message({
						// 			message: res.msg,
						// 			type: 'error',
						// 			duration: 2000
						// 		});
						// 	}
						// })
					} else {
						return false;
					}
				});
				 
				
			},
			// 取消
			cancel(){
				this.initData()
			}
		}
	};
</script>

<style lang="scss" scoped>
	.satelliteConfig {
		padding: 16px;
		&-form {
			max-width: 500px;
		}
		.satellites {
			line-height: 40px;
			&-label {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				color: #606266;
				line-height: 22px;
			}
			&::v-deep .el-radio__input.is-checked .el-radio__inner {
				border-color: $main_color;
				background: $main_color;
			}
			&::v-deep .el-radio__input.is-checked + .el-radio__label {
				color: $main_color;
			}
		}
		.btn {
			width: 180px;
			height: 32px;
			max-width: 48%;
		}
		.save {
			background-color: $main_color;
			border-color: $main_color;
		}
		.cancel {
			border-color: $main_color;
			background-color: #fff;
			color: $main_color;
			margin-left: 30px;
		}
	}
</style>
<template>
	<div class="list" infinite-wrapper="infinite-wrapper">
		<card-item v-for="item in list" :key="item.id" :source="item" :selects="selects"></card-item>
		<loading ref="loading" @getData="getMore"></loading>
	</div>
</template>

<script>
import CardItem from "./card.vue";
export default {
	name: "List",
	components: {
		CardItem
	},
	props: {
		all: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			list: [],
			selects: [],
			loading: false,
			finished: false,
			params: null,
		};
	},
	provide() {
		return {
			_handlerSelect: this.handlerSelect,
		};
	},
	watch: {
		selects: {
			deep: true,
			handler(val) {
				this.$emitter.emit("select", val);
				this.$emit(
					"selection-change",
					val.map((f) => {
						return {
							id: f,
						};
					})
				);
			},
		},
		all: {
			handler(val) {
				if (val) {
					if (this.selects.length !== this.list.length) {
						this.selectAll();
					}
				} else {
					this.selects = [];
				}
			},
			deep: true,
		},
	},
	inject: ["_getTableData"],
	mounted() {
		// this.getData();
	},
	methods: {
		getMore($store) {
			this.getData().then(res => {
				if (res.data.length) {
					if (this.params.total === 0) {
						$store.complete();
					} else {
						$store.loaded();
					}
				} else {
					$store.complete();
				}
			});
		},
		getData() {
			if (this._reSet) {
				this.params.page_num = 1;
				this._reSet = false;
			} else {
				if (this.params) {
					this.params.page_num += 1;
				}
			}
			return this._getTableData(this.params).then((res) => {
				this.list.push(...res.data);
				this.params = res.params;
				if (this.all) {
					this.selectAll();
				}
				return res
			});
		},
		reSet() {
			this.list = [];
			this.params.total = 0;
			this._reSet = true;
			// this.getData(true);
			this.$refs.loading.reSet();
		},
		selectAll() {
			this.selects = this.list.map((f) => f.id);
		},
		handlerSelect(e) {
			if (this.selects.includes(e)) {
				this.selects = this.selects.filter((f) => f != e);
			} else {
				this.selects.push(e);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
	flex: 1;
	overflow: hidden;
	padding-top: 2px;
	overflow-y: auto;
	padding: 0 20px;
	padding-right: 10px;
	// &>div {
	// 	height: 100%;

	// 	padding-right: 10px;
	// }

	.loading-spinner {
		color: #969799;
		font-size: 14px;
		line-height: 50px;
		text-align: center;
	}
}
</style>
<template>
	<div class="baseChart" :id="id"></div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		name: "BaseChart",
		props: {
			// domId
			id: {
				type: [String, Number],
				required: true,
				default: "baseChart",
			},
			option: {
				type: Object,
				default: () => {
					return {
						renderer: "svg",
					};
				},
			},
		},
		data() {
			return {
				scale: 1,
			};
		},
		created() {
			// 初始化承接echarts实例化对象
			this.echarts = null;
		},
		beforeDestroy() {
			if (this.echarts) {
				this.echarts.dispose(); // 销毁图表对象，释放图表资源
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.initChart();
			});
		},
		methods: {
			getCurrentImage(
				val = {
					type: "png",
					backgroundColor: "#000",
					fileName: "图表",
				}
			) {
				if (this.echarts) {
					this.exportImage(
						this.echarts.getDataURL({
							type: val.type || "png",
							backgroundColor: val.backgroundColor || "#000",
						}),
						val.fileName
					);
				}
			},
			exportImage(urlText, name = "图表") {
				return new Promise((res) => {
					let a = document.createElement("a");
					let event = new MouseEvent("click");
					// 自定义下载后图片的名称
					a.download = name;
					a.href = urlText;
					a.dispatchEvent(event);
					res(true);
				});
			},
			initChart() {
				if (!this.echarts && this.id) {
					this.echarts = new echarts.init(
						document.getElementById(this.id),
						"",
						this.option
					); // 创建ECharts实例，参数是要使用的
					this.echarts.setOption({
						tooltip: {
							show: false,
						},
						textStyle: {
							fontSize: 12,
							color: "#D8E5F7",
						},
					});
					this.$emit("load", this.echarts);
				}
			},
			/* 根据屏幕计算fontsize */
			fontSize(res) {
				return Number(this.$tool.toFixedFn(Number(res) * Number(this.scale), 2));
			},
			reSize(val) {
				this.scale = val;
				if (this.echarts) {
					this.echarts.resize();
					this.$emit("reSize", val);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.baseChart {
		width: 100%;
		height: 100%;
	}
</style>

<template>
  <base-popup id="TrackPointDetail" :title="detail.name" :hasTitle="false">
    <div class="flex-dc trackPointDetail">
      <div class="title">
        {{ lang.trackDetail }}
      </div>
      <div class="cells">
        <div class="flex cell" v-for="(cell, index) in items" :key="`${cell.key}_${index}`">
          <span class="label">
            {{ lang[cell.label] }}：
          </span>
          <span class="value omit-1">
            {{ detail[cell.key] || '-' }}{{ cell.unit }}
          </span>
        </div>
      </div>
    </div>
  </base-popup>
</template>

<script>
import BasePopup from "../../basePopup";
import mapPopup from "./mapPopup"
export default {
  name: 'TrackPointDetail',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    },
    lang: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      items: mapPopup.trackPointDetail
    };
  },
  components: {
    BasePopup
  }
};
</script>

<style lang="scss" scoped>
#TrackPointDetail {
  width: 200px;
  height: 160px;
  color: rgba(51, 51, 51, 1);

  .trackPointDetail {
    height: 100%;
    padding: 20px;
    padding-top: 0;

    .title {
      padding: 12px 0;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
    }

    .cells {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;

      .cell {
        justify-content: space-between;
        font-size: 12px;
        line-height: 24px;

        .label {
          color: #666666;
        }

        .value {
          color: #333333;
        }
      }
    }
  }
}
</style>

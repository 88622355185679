<template>
	<div class="flex positionMap">
		<div class="mapContent">
			<base-map
				:id="domId"
				@load="handlerMapLoad"
				@measure="handlerMeasure"
				ref="map"
			></base-map>
			<div
				class="selects"
				v-if="isShowBall || isShowTrack"
				:class="[deviceInfo.type, openSelect ? 'open' : '']"
			>
				<el-form
					ref="form"
					:label-position="deviceInfo.type != 'PC' ? 'left' : 'right'"
					:label-width="deviceInfo.type != 'PC' ? '90px' : 'auto'"
					size="small"
					:inline="true"
					:model="queryData"
				>
					<el-form-item :label="`${$t('label.positionMap.sn')}：`">
						<el-select
							v-model="queryData.sns"
							:filter-method="filterOption"
							:reserve-keyword="true"
							filterable
							multiple
							:multiple-limit="1"
							style="width: 180px"
							:placeholder="`${$t('label.positionMap.sn')}`"
							@change="handlerSnFilter"
							@visible-change="clearKeyWord"
						>
							<!-- <div class="cell checkAll" v-if="showOption.length">
                <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" v-model="checkAll">
                  {{ $t("label.positionMap.checkAll") }}
                </el-checkbox>
              </div> -->
							<el-checkbox-group v-model="queryData.sns">
								<el-option
									v-for="item in showOption"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
									<div class="cell">
										<el-checkbox
											:label="item.value"
											style="pointer-events: none"
										/>
										<span class="selectsLabel">{{ item.label }}</span>
									</div>
								</el-option>
							</el-checkbox-group>
						</el-select>
					</el-form-item>
					<template v-if="isShowTrack">
						<el-form-item label :class="[deviceInfo.type]">
							<el-button
								size="small"
								class="btn"
								:class="[deviceInfo.type]"
								:loading="loading"
								@click="getBallTrackList"
							>
								{{ $t("label.positionMap.query") }}
							</el-button>
							<el-button
								size="small"
								v-if="deviceInfo.type == 'PC'"
								:style="{
									'--baseColor': '#EF9A41',
								}"
								class="btn"
								@click="resetQuery"
								>{{ $t("label.positionMap.reset") }}</el-button
							>
						</el-form-item>
					</template>
					<!-- <el-form-item :label="`${$t('label.positionMap.linkStatus')}：`">
            <el-select v-model="queryData.status" style="width: 180px"
              :placeholder="`${$t('label.positionMap.linkStatus')}`" @change="handlerBallStatus">
              <el-option v-for="item in $t('option.positionMap.status')" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item> -->
					<!-- <el-form-item :label="`${$t('label.positionMap.selectTask')}：`">
            <el-select v-model="queryData.task" style="width: 180px"
              :placeholder="`${$t('label.positionMap.selectTask')}`">
              <el-option v-for="item in $t('option.positionMap.tasks')" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>-->
				</el-form>
				<div
					class="flex selects-icon"
					@click="toggleSelectOpen"
					v-if="deviceInfo.type != 'PC'"
				>
					<i class="el-icon-caret-bottom"></i>
				</div>
			</div>
			<add-points
				ref="addPoint"
				v-if="isShowSection && ability.includes('section_add')"
			></add-points>
		</div>
		<div
			class="flex-dc list"
			v-if="isShowSection"
			:class="[deviceInfo.type, openSide ? 'open' : '']"
		>
			<div class="title" @click="toggleSideOpen">
				{{ $t("label.positionMap.title") }}
			</div>
			<div
				class="points"
				v-infinite-scroll="getSectionList"
				infinite-scroll-immediate="false"
			>
				<template v-if="list.length">
					<points-item
						:ability="ability"
						v-for="(item, index) in list"
						:data="item"
						:index="index"
						:key="index"
					></points-item>
				</template>
				<template v-else>
					<el-empty
						:description="
							$t('public.noData', { name: $t('label.positionMap.title') })
						"
					></el-empty>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import BaseMap from "../baseMap";
	import AddPoints from "./components/addPoints.vue";
	import PointsItem from "./components/pointsItem.vue";
	import {
		lineString,
		featureCollection,
		point,
		center,
		lineIntersect,
		nearestPoint,
	} from "@turf/turf";
	import trackPointDetail from "./components/trackPointDetail.vue";
	import intersectPointDetail from "./components/intersectPointDetail.vue";
	import ballDetail from "./components/ballDetail.vue";
	import sectionLineDetail from "./components/sectionLineDetail.vue";
	import { Baidu_GPS } from "../../../utils/coord";
	import MixinTool from "./mixins";
	const mixinTool = new MixinTool(["ball", "track", "section"]);
	const ball = require("../../../assets/images/public/ball.png");
	const offline = require("../../../assets/images/public/offline.png");
	const point_1 = require("../../../assets/images/public/point_1.png");
	const point_2 = require("../../../assets/images/public/point_2.png");
	const arrowIcon = require("../../../assets/images/public/arrow.png");
	const defaultLine = (id, source = "track") => {
		if (!id) {
			return null;
		}
		return {
			id,
			type: "line",
			source,
			metadata: {
				type: "track",
			},
			layout: {},
			paint: {
				"line-color": ["get", "color"],
				"line-width": 4,
				"line-blur": 0.5,
				"line-opacity": [
					"case",
					["boolean", ["feature-state", "hover"], false],
					1,
					0.5,
				],
			},
			isRecord: false, // 是否记录实时轨迹
			allSn: [],
		};
	};
	export default {
		name: "MainMap",
		components: {
			BaseMap,
			PointsItem,
			AddPoints,
		},
		props: {
			domId: {
				type: String,
				default: "FlowBallR12PositionMap",
			},
			modules: {
				type: Array,
				default: () => {
					return [];
				},
			},
			ability: {
				type: Array,
				default: () => {
					return [];
				},
			},
			autoLocate: {
				type: Boolean,
				default: true,
			},
			autoSectionLoad: {
				type: Boolean,
				default: false,
			},
		},
		mixins: mixinTool.getMixins(),
		data() {
			return {
				list: [],
				options: [],
				line: [],
				queryData: {
					sns: [],
					status: "",
					keyWord: "",
					dateRange: [],
				},
				loading: false,
				checkAll: false,
				ball: [],
				locationPos: null,
				originTrackData: null,
				openSelect: false,
				openSide: false,
				modulesInit: [], // 模块初始化方法
				mapLoad: false, // 地图初始化完毕
			};
		},
		provide() {
			return {
				_getMap: this.getMap,
			};
		},
		inject: {
			_custom_create_popup: {
				name: "_custom_create_popup",
				default: () => {},
			},
			_sn_select: {
				name: "_sn_select",
				default: () => {},
			},
			_openDetail:{
				name: "_openDetail",
				default: () => {},
			}
		},
		computed: {
			isIndeterminate() {
				// console.log(this.queryData.sns);
				if (
					this.queryData.sns.length &&
					this.options.length != this.queryData.sns.length
				) {
					return true;
				} else {
					return false;
				}
			},
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
			showOption() {
				if (this.queryData.keyWord) {
					return this.options.filter(
						(f) => f.value.indexOf(this.queryData.keyWord) !== -1
					);
				} else {
					return this.options;
				}
			},
			isShowSection() {
				return this.modules.includes("section");
			},
			isShowBall() {
				return this.modules.includes("ball");
			},
			isShowTrack() {
				return this.modules.includes("track");
			},
		},
		watch: {
			"queryData.sns": {
				handler(val) {
					if (val.length) {
						if (this.options.length == val.length) {
							this.checkAll = true;
						}
					} else {
						this.checkAll = false;
					}
				},
				deep: true,
				immediate: true,
			},
			"queryData.dateRange": {
				handler(val) {
					if (!val || !val.length) {
						this.minDate = null;
					}
				},
				deep: true,
				immediate: true,
			},
			modulesInit: {
				handler(val) {
					if (val.length && this.modules.length) {
						this.init();
					} 
					
				},
				deep: true,
				immediate: true,
			},
		},
		activated() {
			if (this.map) {
				this.map.resize();
			}
		},

		mounted() {},
		methods: {
			async init() {
				for (let index = 0; index < this.modulesInit.length; index++) {
					// const initFn = this.modules[index];
					await this.modulesInit[index]();
				}
				// this.$emit("inited", this.map);
				this.$nextTick(() => {
					if (this.autoLocate) {
						this.localTimer = setTimeout(() => {
							if (this.localTimer) {
								this.$message.warning(
									this.$t("message.positionMap.location_auth_error")
								);
								clearTimeout(this.localTimer);
								this.localTimer = null;
							}
						}, 300000);
						this.location();
					}
				});
			},
			filterOption(e) {
				this.queryData.keyWord = e;
			},
			clearKeyWord(e) {
				if (!e) {
					this.queryData.keyWord = "";
				}
			},
			toggleSideOpen() {
				this.openSide = !this.openSide;
			},
			toggleSelectOpen() {
				this.openSelect = !this.openSelect;
			},
			// 获取全部sn
			getAllSN() {
				return this.$api
					.ballSnList({
						sn: "",
						model: 1,
					})
					.then((res) => {
						this.allSn = [...new Set(res.data.sn)] || [];
						this.options = this.allSn.map((f) => {
							return {
								label: f,
								value: f,
							};
						});
					});
			},
			handleCheckAllChange(e) {
				// console.log(e);
				if (e) {
					this.queryData.sns = this.options.map((f) => f.value);
				} else {
					this.queryData.sns = [];
				}

				this.handlerSnFilter(this.queryData.sns);
			},
			async handlerMapLoad(map) {
				this.map = map;
				await this.getAllSN();
				await this.setDefaultLayer();
				this.$emit("inited",map)
			},
			location(e) {
				this.$message.info("定位获取中...");
				window._Geolocation.getCurrentPosition((res) => {
					let coords = Baidu_GPS(res.latitude, res.longitude);
					res.coords = {
						longitude: coords.lon,
						latitude: coords.lat,
					};
					if (this.localTimer) {
						clearTimeout(this.localTimer);
						this.localTimer = null;
					}
					this.locationPos = [res.coords.longitude, res.coords.latitude];
					this.map.setCenter([res.coords.longitude, res.coords.latitude]);
					if (this.locationPos) {
						if (!this.map.getSource("localPoint")) {
							this.map.addSource("localPoint", {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: [point(this.locationPos)],
								},
							});
						} else {
							this.map.getSource("localPoint").setData({
								type: "FeatureCollection",
								features: [point(this.locationPos)],
							});
						}
						if (!this.map.getLayer("localPoints")) {
							this.map.addLayer({
								id: "localPoints",
								metadata: {
									type: "track",
								},
								type: "circle",
								source: "localPoint",
								paint: {
									"circle-color": "rgba(83, 157, 86, 1)",
									"circle-radius": 6,
									"circle-stroke-width": 2,
									"circle-stroke-color": "rgba(236, 236, 236, 1)",
								},
							});
						}
					}
				});
			},
			// 处理地图点击事件
			handlerMapClick(e) {
				const features = this.map.queryRenderedFeatures(e.point, {
					layers: [
						"trackLine",
						"trackPoints",
						"sectionLine",
						"sectionPoints",
						"ballPoint",
						"intersectPoints",
					],
				});
				if (features.length) {
					if (this.ballPopup) {
						this.ballPopup.remove();
						this.ballPopup = null;
					}
					if (features[0].layer.id == "ballPoint" && !this.TrackPopup) {
						let center = [
							features[0].properties.lon || 0,
							features[0].properties.lat || 0,
						];
						this.ballPopup = this.createPopup(
							ballDetail,
							{
								detail: {
									lon: this.$tool.toFixedFn(center[0], 8, false),
									lat: this.$tool.toFixedFn(center[1], 8, false),
									...features[0].properties,
									type: "R12",
									status:
										features[0].properties.online_status == 1
											? this.$t("public.online")
											: this.$t("public.offline"),
								},
								type: "ballPoint",
								lang: this.$t("popup.positionMap"),
							},
							center,
							{ bottom: [0, 0] }
						);
					}
					if (this.TrackPopup) {
						this.TrackPopup.remove();
						this.TrackPopup = null;
					}
					if (features[0].layer.id == "trackPoints" && !this.TrackPopup) {
						let center = [
							features[0].properties.lng || 0,
							features[0].properties.lat || 0,
						];
						this.TrackPopup = this.createPopup(
							trackPointDetail,
							{
								detail: {
									...features[0].properties,
									speed:
										this.$tool.toFixedFn(
											Number(features[0].properties.speed),
											2
										) + "",
									angle:
										this.$tool.toFixedFn(
											Number(features[0].properties.angle),
											1
										) + "",
									lon: this.$tool.toFixedFn(center[0], 8, false),
									lat: this.$tool.toFixedFn(center[1], 8, false),
								},
								type: "trackPoints",
								lang: this.$t("popup.positionMap"),
							},
							center,
							{ bottom: [0, 0] }
						);
					}
					if (this.intersectPopup) {
						this.intersectPopup.remove();
						this.intersectPopup = null;
					}
					if (features[0].layer.id == "intersectPoints" && !this.intersectPopup) {
						let center = JSON.parse(features[0].properties.intersect);
						let nearPoint = features[0].properties.nearPoint
							? JSON.parse(features[0].properties.nearPoint)
							: {};
						this.intersectPopup = this.createPopup(
							intersectPointDetail,
							{
								detail: {
									...nearPoint,
									lon: this.$tool.toFixedFn(center[0], 8, false),
									lat: this.$tool.toFixedFn(center[1], 8, false),
									speed: this.$tool.toFixedFn(Number(nearPoint.speed), 2) + "",
									angle: this.$tool.toFixedFn(Number(nearPoint.angle), 1) + "",
								},
								type: "intersectPoints",
								lang: this.$t("popup.positionMap"),
							},
							center,
							{ bottom: [0, 0] }
						);
					}
					if (this.sectionPopup) {
						this.sectionPopup.remove();
						this.sectionPopup = null;
					}
					if (features[0].layer.id == "sectionLine" && !this.sectionPopup) {
						let item = features[0].properties;
						item.points_str = item.points ? JSON.parse(item.points) : {};
						item.center_str = item.center ? JSON.parse(item.center) : {};
						this.sectionPopup = this.createPopup(
							sectionLineDetail,
							{
								detail: {
									...item,
									start_lng: item.points_str[0].geometry.coordinates[0],
									start_lat: item.points_str[0].geometry.coordinates[1],
									end_lng: item.points_str[1].geometry.coordinates[0],
									end_lat: item.points_str[1].geometry.coordinates[1],
								},
								type: "sectionLine",
								lang: this.$t("popup.positionMap"),
							},
							item.center_str.geometry.coordinates,
							{ bottom: [0, 0] }
						);
					}
				} else {
					this.clearPopup();
				}
			},
			clearPopup() {
				if (this.TrackPopup) {
					this.TrackPopup.remove();
					this.TrackPopup = null;
				}
				if (this.ballPopup) {
					this.ballPopup.remove();
					this.ballPopup = null;
				}
				if (this.sectionPopup) {
					this.sectionPopup.remove();
					this.sectionPopup = null;
				}
				if (this.intersectPopup) {
					this.intersectPopup.remove();
					this.intersectPopup = null;
				}
			},
			// 处理地图鼠标悬浮事件
			handlerMapHover(e) {
				const features = this.map.queryRenderedFeatures(e.point, {
					layers: [
						"ballClustersCount",
						"ballClusters",
						"trackLine",
						"trackPoints",
						"sectionLine",
						"sectionPoints",
						"ballPoint",
						"realTrackLine",
					],
				});
				if (features.length) {
					let hasLine = features.find(
						(f) => f.layer.id == "trackLine" || f.layer.id == "realTrackLine"
					);
					if (hasLine) {
						this.highLigth(true);
						this.fHover = hasLine;
						this.highLigth(false);
					}

					this.map.getCanvas().style.cursor = "pointer";
				} else {
					this.highLigth(true);
					this.map.getCanvas().style.cursor = "";
				}
			},
			// 创建弹框
			createPopup(vm, data, center, offset = {}) {
				let option = {
					project: vm,
					data,
				};
				if (this._custom_create_popup) {
					let _temp = this._custom_create_popup({
						project: vm,
						data,
					});
					if (_temp) {
						option = _temp;
					}
				}
				if(this.$store.getters.deviceInfo.type !== 'PC'){
					this._openDetail(option);
					return ;
				}
				this.popup = this.map
					._vmToPopup(option, {
						closeButton: false,
					})
					.setLngLat(center)
					.setOffset({ bottom: [0, -60], ...offset })
					.setMaxWidth("200")
					.addTo(this.map);
			},
			highLigth(clear = false) {
				if (!this.fHover) {
					return;
				}
				this.map.setFeatureState(
					{
						source: this.fHover.source,
						id: this.fHover.id,
					},
					{
						hover: !clear,
					}
				);
				if (clear) {
					this.fHover = null;
				}
			},
			async setDefaultLayer() {
				// console.log(require("../../assets/images/public/ball.png"));
				if (!this.map.hasImage("ballIcon")) {
					const img = await this.map._loadImage(ball);
					this.map.addImage("ballIcon", img.data);
				}
				if (!this.map.hasImage("point2")) {
					const point2 = await this.map._loadImage(point_2);
					this.map.addImage("point2", point2.data);
				}
				if (!this.map.hasImage("point1")) {
					const point1 = await this.map._loadImage(point_1);
					this.map.addImage("point1", point1.data);
				}
				if (!this.map.hasImage("offimg")) {
					const offimg = await this.map._loadImage(offline);
					this.map.addImage("offimg", offimg.data);
				}
				if (!this.map.hasImage("arrowIcon")) {
					const arrow = await this.map._loadImage(arrowIcon);
					this.map.addImage("arrowIcon", arrow.data);
				}
				if (!this.map.getSource("section")) {
					this.map.addSource("section", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});
				}
				if (!this.map.getSource("intersect")) {
					this.map.addSource("intersect", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});
				}
				if (!this.map.getSource("track")) {
					this.map.addSource("track", {
						type: "geojson",
						promoteId: true,
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});
				}
				if (!this.map.getSource("realTrack")) {
					this.map.addSource("realTrack", {
						type: "geojson",
						promoteId: true,
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});
				}

				if (!this.map.getSource("trackPoint")) {
					this.map.addSource("trackPoint", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});
				}
				if (!this.map.getSource("ball")) {
					this.map.addSource("ball", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
						cluster: true,
						clusterMaxZoom: 12, // Max zoom to cluster points on
						clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
					});
				}
				if (!this.map.getLayer("realTrackLine")) {
					this.map.addLayer(defaultLine("realTrackLine", "realTrack"));
				}
				if (!this.map.getLayer("arrowRealTrackLayer")) {
					this.map.addLayer({
						id: "arrowRealTrackLayer",
						type: "symbol",
						source: "realTrack",
						layout: {
							"symbol-placement": "line",
							"symbol-spacing": 50, // 图标间隔，默认为250
							"icon-image": "arrowIcon", //箭头图标
							"icon-size": 0.5,
						},
					});
				}
				if (!this.map.getLayer("trackLine")) {
					// 轨迹点 线
					this.map.addLayer(defaultLine("trackLine"));
				}
				if (!this.map.getLayer("arrowTrackLayer")) {
					this.map.addLayer({
						id: "arrowTrackLayer",
						type: "symbol",
						source: "track",
						layout: {
							"symbol-placement": "line",
							"symbol-spacing": 50, // 图标间隔，默认为250
							"icon-image": "arrowIcon", //箭头图标
							"icon-size": 0.5,
						},
					});
				}
				if (!this.map.getLayer("trackPoints")) {
					// 轨迹点
					this.map.addLayer({
						id: "trackPoints",
						metadata: {
							type: "track",
						},
						type: "circle",
						source: "trackPoint",
						paint: {
							"circle-color": "rgba(236, 236, 236, 1)",
							"circle-radius": 6,
							"circle-stroke-width": 2,
							"circle-stroke-color": "rgba(0, 144, 255, 1)",
						},
					});
				}
				if (!this.map.getLayer("sectionLine")) {
					// 截面点对 线
					this.map.addLayer({
						type: "line",
						source: "section",
						metadata: {
							type: "section",
						},
						id: "sectionLine",
						paint: {
							"line-color": "#000000",
							"line-width": 6,
							"line-opacity": 1,
						},
					});
				}
				if (!this.map.getLayer("sectionPoints")) {
					//  截面点对 点
					this.map.addLayer({
						id: "sectionPoints",
						type: "symbol",
						metadata: {
							type: "section",
						},
						source: "section", // reference the data source
						layout: {
							"icon-allow-overlap": true,
							"icon-image": [
								"match",
								["get", "index"],
								0,
								"point1",
								1,
								"point2",
								"point1",
							], // reference the image
							"icon-size": 0.4,
							visibility: "visible",
							"icon-anchor": "bottom-left",
							"icon-offset": [-68, 0],
						},
					});
				}
				if (!this.map.getLayer("intersectPoints")) {
					// 轨迹线与截面点对交点
					this.map.addLayer({
						id: "intersectPoints",
						metadata: {
							type: "intersect",
						},
						type: "circle",
						source: "intersect",
						paint: {
							"circle-color": "rgba(255, 0, 0, 1)",
							"circle-radius": 6,
							"circle-stroke-width": 2,
							"circle-stroke-color": "rgba(255, 175, 0, 1)",
						},
					});
				}
				if (!this.map.getLayer("ballPoint")) {
					// 流速球图标
					this.map.addLayer({
						id: "ballPoint",
						type: "symbol",
						metadata: {
							type: "ball",
						},
						source: "ball", // reference the data source
						paint: {
							"icon-color": [
								"case",
								["all", ["==", ["get", "lookAt"], "1"]],
								"rgba(255, 0, 0, 0.6)",
								"rgba(0, 0, 0, 0)",
							],
						},
						layout: {
							"icon-allow-overlap": true,
							// "icon-image": "ballIcon", // reference the image
							"icon-image": [
								"case",
								["all", ["==", ["get", "online_status"], 1]],
								"ballIcon",
								"offimg",
							], // reference the image
							"icon-size": [
								"case",
								["all", ["==", ["get", "lookAt"], "1"]],
								0.6,
								0.4,
							],

							visibility: "visible",
							"icon-anchor": "center",
						},
					});
				}
				if (!this.map.getLayer("ballClusters")) {
					// 流速球图标 聚合
					this.map.addLayer({
						id: "ballClusters",
						type: "circle",
						source: "ball",
						filter: ["all", ["has", "point_count"]],
						paint: {
							"circle-color": [
								"step",
								["get", "point_count"],
								"#51bbd6",
								100,
								"#f1f075",
								750,
								"#f28cb1",
							],
							"circle-radius": [
								"step",
								["get", "point_count"],
								20,
								100,
								30,
								750,
								40,
							],
						},
					});
				}
				if (!this.map.getLayer("ballClustersCount")) {
					this.map.addLayer({
						id: "ballClustersCount",
						type: "symbol",
						source: "ball",
						filter: ["has", "point_count"],
						layout: {
							"text-field": ["get", "point_count_abbreviated"],
							"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
							"text-size": 12,
						},
					});
				}
				if (!this.mapLoad) {
					this.map.on("mousemove", this.handlerMapHover);
					this.map.on("click", this.handlerMapClick);
					let local = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
					if (local.length) {
						local[0].addEventListener("click", this.location);
					}
					this.computedIntersect();
					this.mapLoad = true;
				}
			},
			getMap(cb) {
				cb(this.map);
			},
			// 计算交点流速
			computedIntersect(clear = false) {
				// if (!this.isShowSection || !this.isShowTrack) {
				//   return;
				// }
				if (clear) {
					this.map.getSource("intersect").setData({
						type: "FeatureCollection",
						features: [],
					});
					return;
				}
				let track_line = this.map.getSource("track")._data.features;
				let section_line = this.map
					.getSource("section")
					._data.features.filter((f) => f.geometry.type == "LineString");
				let points = [];
				if (track_line.length && section_line.length) {
					track_line.forEach((track) => {
						section_line.forEach((section) => {
							let _point = lineIntersect(track, section).features;
							if (_point.length) {
								_point.forEach((temp) => {
									let nearPoint = nearestPoint(
										temp,
										featureCollection(
											this.originTrackData[track.properties.sn].map((f) => {
												return point([Number(f.lng), Number(f.lat)], f);
											})
										)
									);
									temp.properties = {
										...track.properties,
										...section.properties,
										intersect: temp.geometry.coordinates,
										nearPoint: nearPoint.properties,
									};
									points.push(temp);
								});
							}
						});
					});
				}
				this.map.getSource("intersect").setData({
					type: "FeatureCollection",
					features: points,
				});
			},
			handlerMapFilter() {
				const filter = ["all"];
				const { sns, status } = this.queryData;
				if (status === 1 || status === 0) {
					filter.push(["==", "status", this.queryData.status]);
				}
				if (sns.length) {
					filter.push(["in", "sn", ...sns]);
				} else {
					filter.push(["in", "sn", ...this.options.map((f) => f.value)]);
				}
				return filter;
			},
			handlerMeasure(e) {
				console.log(e);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.positionMap {
		height: 100%;
		position: relative;
		width: 100%;

		.btn {
			background-color: var(--baseColor);
			border-color: var(--baseColor);
			color: #fff;
		}

		& > div {
			height: 100%;
		}

		.mapContent {
			flex: 1;
			overflow: hidden;
			position: relative;
			width: 100%;

			.selects {
				position: absolute;
				z-index: 1;
				background-color: rgba($color: #ffffff, $alpha: 0.8);
				top: 0;
				left: 0;
				width: 100%;
				padding: 12px;

				&-icon {
					position: absolute;
					right: 0;
					top: 0;
					width: 32px;
					height: 32px;
					background: var(--baseColor);
					transform: translateX(100%);
					justify-content: center;
					transition-duration: 0.3s;

					& i {
						font-size: 22px;
						color: #fff;
					}
				}

				.el-form-item--mini.el-form-item,
				.el-form-item--small.el-form-item {
					margin-bottom: 6px;
				}

				.cell {
				}
			}

			.selects.MOBIE {
				width: calc(100% - 32px);
				padding: 8px;
				transform: translateX(-100%);
				transition-duration: 0.3s;

				.el-form-item {
					margin-right: 0;

					& + .el-form-item {
						margin-top: 6px;
					}
				}

				.el-form-item.MOBIE {
					width: 100%;

					::v-deep .el-form-item__content {
						width: 100%;
					}
				}
			}

			.selects.MOBIE.open {
				transform: translateX(0%);

				& i {
					transform: rotate(180deg);
				}
			}

			.btn.MOBIE {
				width: calc(100%);
				// transform: translate(-8px, 8px);
				border-radius: 0;
			}
		}

		.list {
			width: 280px;
			background-color: #fff;

			.title {
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #d2e9ce;
				color: #333333;
			}

			.points {
				padding: 20px;
				flex: 1;
				overflow-y: auto;
				overflow-x: hidden;
			}
		}

		.list.MOBIE {
			position: absolute;
			right: 0;
			z-index: 10;
			top: 0;
			bottom: 0;
			transform: translateX(100%);
			transition-duration: 0.3s;

			.title {
				position: absolute;
				width: 32px;
				height: 120px;
				background: #539d56;
				border-radius: 2px 0px 0px 2px;
				text-align: center;
				line-height: 34px;
				top: 50%;
				left: 0;
				writing-mode: vertical-rl;
				letter-spacing: 6px;
				color: #ffffff;
				transform: translate(-100%, -50%);
			}
		}

		.list.MOBIE.open {
			transform: translateX(0%);
		}
	}
</style>

<style lang="scss">
	.el-checkbox .el-checkbox__label {
		display: none;
	}

	.selectsLabel {
		margin-left: 10px;
	}

	.checkAll {
		padding: 0 20px;
		line-height: 34px;
		font-size: 14px;
		color: #606266;

		.el-checkbox .el-checkbox__label {
			display: inline-block;
		}

		.is-checked {
			.el-checkbox__label {
				font-weight: 700;
			}
		}
	}
</style>

<template>
  <div class="receiverList" :class="$store.getters.deviceInfo.type">
    <div class="fixed">
      <div class="query">
      <div class="input-width">
        <div class="search_group">
          <div class="flex-hc" :style="{width: $store.getters.deviceInfo.type == 'PC' ? '': '100%'}">
            <span style="min-width: 100px"
              >{{ $t("label.system.systemN") }}：</span
            >
            <el-input
              v-model="systemN"
              :placeholder="$t('label.system.systemN')"
              size="small"
              style="flex: 1;"
            ></el-input>
          </div>
          <div
            class="btn_group"
            :style="{
              width: $store.getters.deviceInfo.type == 'PC' ? '' : '100%',
              marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
              marginLeft: $store.getters.deviceInfo.type == 'PC' ? '10px' : '0',
            }"
          >
            <el-button
              size="small"
              style="background-color: #539d56;width: 50%;"
              @click="searchList"
              >{{ $t("button.search") }}</el-button
            >
            <el-button
              size="small"
              style="background-color: #ef9a41;width: 50%;"
              @click="reset"
              >{{ $t("button.reset") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="pageTitle opt">
      <span style="font-weight: 600; color: #333333"
        >{{ $t("label.system.systemL") }}：</span
      >
      <div class="btn_group">
        <el-button @click="addTask" size="small" type="primary">{{
          $t("button.create")
        }}</el-button>
        <el-button @click="delTask" size="small" type="danger">{{
          $t("button.delete")
        }}</el-button>
      </div>
    </div>
    <div class="table" v-if="deviceInfo.type == 'PC'">
      <el-table
        :data="tableData"
        ref="tableData"
        stripe
        :max-height="tableHeight"
        :size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.system.${column}`)"
          align="center"
          :min-width="column == 'index' ? '50px' : '100px'"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="{ row }">
            <template v-if="column == 'operate'">
              <div class="btn_group">
                <el-button
                  size="small"
                  style="background-color: #539d56"
                  @click="toSysInfo(row)"
                  >{{ $t("label.system.view") }}</el-button
                >
                <el-button
                  size="small"
                  style="background-color: #539d56"
                  @click="toEdit(row)"
                  >{{ $t("label.system.edit") }}</el-button
                >
              </div>
            </template>
            <template v-else>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <van-list
        v-model="isLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="MBCard" v-for="item in MBData" :key="item.id">
          <el-checkbox @change="tbChange($event, item)"></el-checkbox>
          <div class="item">
            <span>坐标系：</span>
            <span>{{ item.systemN }}</span>
          </div>
          <div class="item">
            <span>创建时间：</span>
            <span>{{ item.cTime }}</span>
          </div>
          <div class="item">
            <span>椭球：</span>
            <span>{{ item.ballN }}</span>
          </div>
          <div class="item">
            <span>中央经线：</span>
            <span>{{ item.centerLine }}</span>
          </div>
          <div class="btn">
            <el-button
              size="small"
              type="primary"
              @click="toSysInfo(item)"
              >{{ $t("label.system.view") }}</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="toEdit(item)"
              >{{ $t("label.system.edit") }}</el-button
            >
          </div>
        </div>
      </van-list>
    </div>
    <div class="pagination" v-if="deviceInfo.type == 'PC'">
      <el-pagination
        background
        :layout="
          $store.getters.deviceInfo.type == 'PC'
            ? 'total, prev, pager, next, jumper'
            : 'prev, pager, next'
        "
        :total="total"
        popper-class="mySelectType"
        :current-page="page"
        @current-change="currentChange"
        :small="$store.getters.deviceInfo.type != 'PC'"
      >
      </el-pagination>
    </div>
    <CreateSys
      :show.sync="showC"
      :rowInfo.sync="rowInfo"
      :disabled.sync="disabled"
      @refush="refush"
    ></CreateSys>
    <ConfirmDialog
      :title="'提示'"
      :show="showD"
      :message="'确定删除坐标系统数据吗？'"
      @toggleShow="closeDia"
      @submit="closeDia"
      @delete="toDelete"
    ></ConfirmDialog>
  </div>
</template>
    
    <script>
import { List } from "vant";
import ConfirmDialog from "../../components/public/confirmDialog";
import { systemL, delSystem } from "@/api/index";
import tool from "@/utils/tool.js";
import CreateSys from "./components/createSys.vue";
//   import CreateTask from './components/createTask.vue';
export default {
  data() {
    return {
      disabled: false,
      rowInfo: {},
      columns: ["systemN", "cTime", "ballN", "centerLine", "operate"],
      tableData: [
        // {
        //   index: 1,
        //   systemN: 1,
        //   cTime: "2023-04-27 11:00:01",
        //   ballN: "2023-04-27 11:00:01",
        //   centerLine: "2023-04-27 11:00:01",
        //   operate: 111,
        // },
      ],
      total: 20,
      page: 1,
      pageSize: 10,
      tableHeight: 450,
      infoShow: false,
      showC: false, // 新建弹框
      showD: false, // 删除弹框
      systemN: "",
      select: [], // 选中的数据
      isLoading: true,
      finished: true,
      MBData: [],
    };
  },
  components: {
    CreateSys,
    ConfirmDialog,
    VanList: List,
  },
  computed: {
    isIndeterminate() {
      // console.log(this.queryData.sns);
      if (this.snValue.length && this.options.length != this.snValue.length) {
        return true;
      } else {
        return false;
      }
    },
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.tableData?.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.getSystemList();
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      this.tableHeight =
        window.innerHeight - this.$refs.tableData?.$el.offsetHeight - 145;
    });
  },
  methods: {
    tbChange(e, info) {
      if (e) {
        this.select.push(info);
      } else {
        this.select = this.select.filter((item) => item.id !== info.id);
      }
      console.log(this.select, "seleeeee");
    },
    refush(){
      this.MBData = []
      this.page = 1
      this.isLoading = true;
      this.getSystemList();
    },
    onLoad() {
      this.page++;
      this.isLoading = true;
      this.getSystemList();
    },
    closeDia() {
      this.showD = false;
    },
    refreshList() {
      console.log("shuaxin");
    },
    //
    toDelete() {
      let ids = this.select.map((item) => {
        return item.id;
      });
      let param = {
        id: ids,
      };
      delSystem(param).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.page = 1
          this.MBData = []
          this.getSystemList();
        } else {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
      // console.log("123");
    },
    delTask() {
      if (!this.select.length) {
        this.$message({
          type: "warning",
          message: "请选择坐标系统进行删除",
        });
        return;
      }
      this.showD = true;
      // console.log("删除");
    },
    // 查看
    toSysInfo(e) {
      this.rowInfo = e;
      this.showC = true;
      this.disabled = true;

      // console.log("查看");
    },
    // 编辑
    toEdit(e) {
      this.rowInfo = e;
      this.showC = true;
      // console.log("编辑");
    },
    // 搜索
    searchList() {
      this.MBData = []
      this.page = 1;
      // console.log("搜索");
      this.getSystemList();
    },
    reset() {
      // console.log("重置");
      this.systemN = "";
      this.getSystemList();
      //   this.snValue = [];
      //   this.getSystemList();
    },
    selectionChange(e) {
      this.select = e;
      // console.log(e, "eeee")
    },
    getSystemList() {
      let param = {
        coordinate_name: this.systemN,
        page_num: this.page,
        page_size: this.pageSize,
      };
      systemL(param).then((res) => {
        // console.log(res, "rrrrr")
        if (res.code == 200) {
          this.total = res.data.total;
          this.tableData = res.data.rows.map((item, index) => {
            return {
              index: (this.page - 1) * this.pageSize + index + 1,
              id: item.id,
              systemN: item.coordinate_name,
              cTime: item.created_at,
              ballN: item.ellipsoid_info.ellipsoid_name,
              centerLine: item.central_meridian,
            };
          });
          this.finished = res.data.rows.length ? false : true;
          this.MBData = this.MBData.concat(this.tableData);
          this.isLoading = false;
        }
        // this.tableData = res.data.
      });
    },
    // 新建
    addTask() {
      this.showC = true;
      console.log("新建");
    },
    toggleShowAddSn() {},
    // 页数
    currentChange(e) {
      this.page = e;
      this.getSystemList();
    },
    // 提交清除数据的选中状态
    searchTableData() {
      this.$refs.tableData.clearSelection(); //清除选中的数据
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.receiverList {
  padding: 0 20px 20px;
  .MBCard {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #979797;
    border-radius: 8px;
    margin: 10px 0;
    .item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 32px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
    }
  }
  .fixed {
    background-color: #fff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 9;
    // top: 20px;
    // padding: 20px 0;
  }
  .opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .query {
    display: flex;
    justify-content: space-between;
  }

  .input-width {
    max-width: 1000px;
    .search_group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
    }
  }
  .operate-btn {
    display: flex;
  }
  .btn_group {
    margin-left: 20px;
    display: flex;

    & > .el-button {
      min-width: 60px;
      color: #fff;
      border: none;
    }
  }
  .btn {
    min-width: 80px;
    height: 32px;
  }
  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;
    height: calc(100% - 32px - 50px - 40px);

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }
  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: 0px;
    right: 20px;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding: 5px 0;
    width: calc(100% - 230px);
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    .el-select .el-input__inner:focus {
      border-color: #3b9167;
    }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }

    .el-button--success.is-disabled {
      background-color: #c0c4cc;
      border-color: #c0c4cc;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }
}

.MOBIE {
  .pagination {
    position: unset;
    width: 100%;
    padding: 0;
    margin-top: 16px;
    // margin-bottom: 16px;
  }
}
.selectsLabel {
  margin-left: 10px;
}
</style>
    <style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>
    <style lang="scss">
.el-checkbox .el-checkbox__label {
  display: none;
}

.selectsLabel {
  margin-left: 10px;
}

.checkAll {
  padding: 0 20px;
  line-height: 34px;
  font-size: 14px;
  color: #606266;

  .el-checkbox .el-checkbox__label {
    display: inline-block;
  }

  .is-checked {
    .el-checkbox__label {
      font-weight: 700;
    }
  }
}
</style>
    
<template>
  <manage-dialog
    :show="show"
    :append-to-body="appendToBody"
    :notPaddingTop="notPaddingTop"
    :width="width"
  >
    <div class="customDialog-title" slot="title">
      <div class="customDialog-title-content">
        <span class="customDialog-title-content-label">{{ title }} </span>
        <div class="closeDia">
          <i class="el-icon-close" @click="handleClose"></i>
        </div>
      </div>
    </div>
    <template slot="main">
      <slot name="main">
        <div class="dialogMain"></div>
      </slot>
    </template>
    <template slot="footer" class="dialog-footer">
      <slot name="footer">
        <div class="dialogFooter"></div>
      </slot>
    </template>
  </manage-dialog>
</template>

<script>
import ManageDialog from "../manageDialog";
export default {
  name: "CustomDialog",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "标题",
    },
    width: {
      type: String,
      default: "600",
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    notPaddingTop: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ManageDialog,
  },
  methods: {
    handleClose(done) {
      this.$emit("toggleShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.customDialog-title {
  text-align: center;
  background-color: #fff;
  // padding: 12px;
  &-content {
    background-color: $main_color;
    line-height: 40px;
    height: 40px;
    color: #fff;
    position: relative;
    // & > span {

    // }
    &-label {
      position: absolute;
      left: 50%;
      line-height: 40px;
      transform: translateX(-50%);
      top: 0;
      width: 50%;
      display: inline-block;
    }
    .closeDia {
      position: absolute;
      right: 0;
      width: 40px;
      height: 40px;
      top: 0;
      cursor: pointer;
    }
  }
}
</style>

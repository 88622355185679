let res = null;
export default function () {
  if (!res) {
    res = [
      {
        values: new Array(24).fill(0).map((f, i) => {
          return i < 10 ? '0' + i : i
        }),
        defaultIndex: 0,
      },
      {
        values: new Array(60).fill(0).map((f, i) => {
          return i < 10 ? '0' + i : i
        }),
        defaultIndex: 0,
      },
      {
        values: new Array(60).fill(0).map((f, i) => {
          return i < 10 ? '0' + i : i
        }),
        defaultIndex: 0,
      },
    ]
  }
  return res;
}
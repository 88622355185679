<template>
  <custom-dialog
    :show="show"
    :title="title"
    @toggleShow="handleClose"
    width="398"
  >
    <div class="content" slot="main">
      <slot name="main">
        <div class="dialogMain" :style="{ 'text-align': messageAlign }">
          {{ message }}
        </div>
      </slot>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose()"
      >
        {{ $t("public.cancel") }}
      </el-button>
      <el-button
        type="primary"
        class="btn save"
        size="small"
        @click="handleSubmit"
      >
        {{ $t("public.submit") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../customDialog";
export default {
  name: "ConfirmDialog",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageAlign: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose(done) {
      this.$emit("toggleShow", false);
    },
    handleSubmit(done) {
      this.$emit("option"); // 流速球远程诊断
      this.$emit("delete"); // 删除用户列表
      this.$emit("submit", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: $main_color;
    margin-left: 30px;
    border-color: $main_color;
  }

  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
  }
}

.form {
  justify-content: center;
}
</style>

<template>
  <custom-dialog
    :show="show"
    :title="$t('dialog.userList.equipment')"
    @toggleShow="handleClose"
    :appendToBody="true"
    width="400"
  >
    <div class="flex form" slot="main">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="95px"
        label-position="left"
        hide-required-asterisk
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <template v-if="item.inputType == 'select'">
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t('dialog.userList.dis_placeholder')"
            >
              <el-option
                v-for="option in options"
                :key="option.id"
                :label="option.account"
                :value="option.id"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button type="primary" class="btn save" size="small">
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-input
                size="small"
                style="width: 268px"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="primary"
        class="btn save"
        size="small"
        @click="disSave()"
      >
        {{ $t("dialog.userList.save") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose()"
      >
        {{ $t("dialog.userList.close") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import { checkAgencyList, receiverAssign, userInfoList } from "@/api/index";
export default {
  name: "EditSn",
  components: {
    CustomDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      formItem: [
        {
          label: "dialog.userList.distribute",
          key: "user",
          inputType: "select",
        },
      ],
      form: {
        user: "",
      },
      rules: {
        user: [
          { required: true, message: "请选择归属用户", trigger: "change" },
        ],
      },
      options: [],
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.formItem.filter(
          (f) => f.key == "accountType" || f.key == "contact"
        );
      } else {
        return this.formItem;
      }
    },
  },
  watch: {
    show: {
      handler(val) {
        this.form = {
          user: "",
        };
      },
      deep: true,
    },
  },
  mounted() {
    // console.log(this.selectValue,"sssss")
    this.getUserInfoList();
  },
  methods: {
    getUserInfoList() {
      // let param = {
      // 	page: 1,
      // 	page_size: 9999,
      // }
      // userInfoList(param).then(res => {
      // 	// let table = []
      // 	if(res.code === 200){
      // 		this.options = res.data.list.map((item,index) => {
      // 			return {
      // 				id:item.id,
      // 				name: item.name,
      // 				contact: item.phone,
      // 				account: item.account,
      // 				accountType: item.type===2?'经销商':'个体用户',
      // 				dealer: item.pid_account,
      // 				pid: item.pid
      // 			}
      // 		})
      // 		// this.total = res.data.count
      // 	}
      // 	// console.log(res,"rrr")
      // })
    },
    handleClose(done) {
      this.$emit("toggleShow", false);

      this.$refs.form.clearValidate();
    },
    disSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
        //   let param = {
        //     device_ids: this.selectValue,
        //     uid: this.form.user,
        //   };
        //   receiverAssign(param).then((res) => {
        //     console.log(res);
        //     if (res.code === 200) {
        //       this.$message({
        //         type: "success",
        //         // message:"分配设备成功！",
        //         message: this.$t("dialog.userList.discover_success_info"),
        //         duration: 2000,
        //       });
        //       this.$emit("toggleShow", false);
        //       this.$emit("searchTableData");
        //       this.$emit("refush");
        //     } else {
        //       this.$message({
        //         type: "warning",
        //         message: res.msg,
        //         duration: 2000,
        //       });
        //     }
        //   });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
.form {
  justify-content: center;
}
</style>
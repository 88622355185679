<template>
	<base-popup id="BallDetail">
		<div class="flex-dc ballDetail">
			<div class="title">
				{{ lang.title }}
			</div>
			<div class="cells">
				<div
					class="flex cell"
					v-for="(cell, index) in items"
					:key="`${cell.key}_${index}`"
				>
					<span class="label"> {{ lang[cell.label] }}： </span>
					<span class="value omit-1">
						{{ detail[cell.key] || "-" }}
					</span>
				</div>
				<div class="flex btns">
					<el-button size="small" class="btn" @click="openTrack">
						{{ lang.track }}
					</el-button>
					<el-button
						size="small"
						:style="{
							'--baseColor': '#EF9A41',
						}"
						:disabled="detail.online_status == '2'"
						class="btn"
						@click="openConfig"
						>{{ lang.config }}</el-button
					>
				</div>
			</div>
		</div>
	</base-popup>
</template>

<script>
	import BasePopup from "../../basePopup";
	import mapPopup from "./mapPopup"
	export default {
		name: "BallDetail",
		props: {
			detail: {
				type: Object,
				default: () => {
					return {};
				},
			},
			lang: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				items:mapPopup.ballDetail,
			};
		},
		components: {
			BasePopup,
		},
		methods:{
			openTrack(){
				if(window.$emitter){
					window.$emitter.emit("openTrack",this.detail)
				}
			},
			openConfig(){
				if(window.$emitter){
					window.$emitter.emit("openConfig",this.detail)
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	#BallDetail {
		width: 220px;
		min-height: 220px;
		color: rgba(51, 51, 51, 1);

		.ballDetail {
			height: 100%;
			padding: 20px;
			padding-top: 0;

			.title {
				padding: 12px 0;
				text-align: center;
				font-weight: 600;
				font-size: 14px;
				color: #333333;
			}

			.cells {
				flex: 1;
				overflow-y: auto;
				overflow-x: hidden;
				position: relative;
				.cell {
					justify-content: space-between;
					font-size: 12px;
					line-height: 24px;

					.label {
						color: #666666;
					}

					.value {
						color: #333333;
					}
				}
				.btns {
					// position: absolute;
					bottom: 0;
					padding-top: 6px;
					background-color: #fff;
					left: 0;
					width: 100%;
					height: auto;
					z-index: 5;
					justify-content: space-between;
					.el-button {
						background-color: #539d56;
						color: #fff;
					}
					.el-button.is-disabled {
						color: #fff;
						background-color: #999;
					}
				}
			}
		}
	}
</style>

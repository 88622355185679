<template>
  <div class="flex-dc basePopup" :id="id">
    <div class="basePopup-title" v-if="hasTitle">
      <slot name="title">{{ title }} </slot>
    </div>
    <div class="basePopup-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopup',
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    hasTitle: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.basePopup {
  position: relative;
  background: #fff;

  &-main {
    flex: 1;
    overflow: hidden;
  }
}
</style>

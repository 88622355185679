const validateXYZ = (rule, value, callback) => {
    // const sz = /^-?(?:[1-9]\d{0,5}|0)(?:\.\d{1,3})?$/;
    const sz = /^-?(?:[1-9]\d{0,5}|0)(?:\.\d{1,6})?$/;
    if (!value) {
        callback(new Error("请输入参数"));
    } else if (!sz.test(value) || value > 999999.999 || value < -999999.999) {
        callback(new Error("范围在-999999.999~999999.999"));
    } else {
        callback();
    }
};
const validateRXYZ = (rule, value, callback) => {
    // const sz = /^-?(?:[1-9]\d{0,4}|0)(?:\.\d{1,5})?$/;
    const sz = /^-?(?:[1-9]\d{0,4}|0)(?:\.\d{1,6})?$/;
    if (!value) {
        callback(new Error("请输入参数"));
    } else if (!sz.test(value) || value > 9999.99999 || value < -9999.99999) {
        callback(new Error("范围在-9999.99999~9999.99999"));
    } else {
        callback();
    }
};
const validateS = (rule, value, callback) => {
    // const sz = /^-?(?:[1-9]\d{0,9}|0)(?:\.\d{1,3})?$/;
    const sz = /^-?(?:[1-9]\d{0,9}|0)(?:\.\d{1,6})?$/;
    if (!value) {
        callback(new Error("请输入参数"));
    } else if (
        !sz.test(value) ||
        value > 999999999.999 ||
        value < -999999999.999
    ) {
        callback(new Error("范围在-999999999.999~999999999.999"));
    } else {
        callback();
    }
};
// 东偏移 西偏移
const validateOffset = (rule, value, callback) => {
    // const sz = /^-?(?:[1-9]\d{0,8}|0)(?:\.\d{1,3})?$/;
    const sz = /^-?(?:[1-9]\d{0,8}|0)(?:\.\d{1,6})?$/;
    console.log(!sz.test(value), value, "value")
    if (!value) {
        callback(new Error("请输入参数"));
    } else if (
        !sz.test(value) ||
        value > 99999999.999 ||
        value < -99999999.999
    ) {
        callback(new Error("范围在-99999999.999~99999999.999"));
    } else {
        callback();
    }
};
const validateCen = (rule, value, callback) => {
    const sz = /^-?(?:[1-9]\d{0,3}|0)(?:\.\d{1,6})?$/;
    if (!value) {
        callback(new Error("请输入中央子午线"));
    } else if (!sz.test(value) || value > 180.0 || value < -180.0) {
        callback(new Error("范围在-180.000000~180.000000"));
    } else {
        callback();
    }
};
const validateName = (rule, value, callback) => {
    const sz = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,20}$/;
    if (!value) {
        callback(new Error("请输入椭球名称"));
    } else if (!sz.test(value)) {
        callback(new Error("请输入20位以内的中英文或数字字母"));
    } else {
        callback();
    }
};
export {
    validateXYZ,
    validateRXYZ,
    validateS,
    validateCen,
    validateName,
    validateOffset
}
import {
  point,
} from "@turf/turf";
export default {
  data() {
    return {
      isLoaded: false,
    }
  },
  watch: {
    mapLoad(val) {
      if (val && this.modulesInit) {
        this.modulesInit.push(this._ball_init)
      }
    }
  },
  activated() {
    if (this.isLoaded) {
      this.getMapData();
    }
  },

  deactivated() {
    this.setTimer(true);
  },
  beforeDestroy() {
    this.setTimer(true);
  },
  methods: {
    async _ball_init() {
      if (this.isShowBall) {
        console.log('模块ball初始化步骤');
        await this.getBallData();
      }
    },

    // 获取流速球位置
    getPosBySn() {
      if (!this.io) {
        // this.io = new WebSocket("ws://nxa34kegc963.allynav.cn:9000/receiver/v1/gnss/device/all");
        // this.io = new WebSocket("ws://47.104.212.222:13016/v1/gnss/device/all");
        this.io = new WebSocket("wss://bdrmp.allynav.cn/api/v1/gnss/device/all");
        this.io.onopen = () => {
          this.io.send(
            JSON.stringify({
              // sn: this.queryData.sns.length ? this.queryData.sns : this.allSn,
              sn: this.queryData.sns,
              // status: this.queryData.status ? this.queryData.status : null,
              online_status: this.queryData.status ? this.queryData.status : 0,
              model: 1,
              token: JSON.parse(localStorage.getItem("token")),
            })
          );
        };
      } else {
        this.io.send(
          JSON.stringify({
            // sn: this.queryData.sns.length ? this.queryData.sns : this.allSn,
            sn: this.queryData.sns,
            // status: this.queryData.status ? this.queryData.status : null,
            online_status: this.queryData.status ? this.queryData.status : 0,
            model: 1,
            token: JSON.parse(localStorage.getItem("token")),
          })
        );
      }
      return new Promise((resolve) => {
        this.io.onmessage = (e) => {
          const res = JSON.parse(e.data);
          this.$emitter.emit("ballUpdate",res)
          if (res.code !== 200) {
            // this.$message.warning(this.$t("message.positionMap.data_error"))
            this.$message.error(res.message)
            // this.$router.push('/login')
            this.setTimer(true);
            resolve(false);
          } else {
            this.map.setFilter("ballPoint", this.handlerMapFilter());
            if (res.data.device_info && res.data.device_info.length) {
              this.map.getSource("ball").setData({
                type: "FeatureCollection",
                features: res.data.device_info.map((f) => {
                  let lookAt = f.sn === this._current_look_at ? '1' : '0';
                  let pos = [Number(f.lon || 0), Number(f.lat || 0)];
                  if (lookAt === '1' && this.queryData.dateRange.length === 0) {
                    this.map.easeTo({
                      center: pos,
                      zoom: 15,
                    });
                  }
                  return point(pos, {
                    ...f,
                    lookAt: lookAt
                  });
                }),
              });
              if (this.isShowTrack) {
                this.handlerRealTrackLine(res.data.device_info)
              }

            } else {
              this.map.getSource("ball").setData({
                type: "FeatureCollection",
                features: [],
              });
              this.map.getSource("realTrack").setData({
                type: "FeatureCollection",
                features: [],
              })
            }
            resolve(res);
          }

        };
      });
    },
    // 整合调取接口
    getBallData() {
      return this.getAllSN()
        .then((res) => {
          return this.getPosBySn();
        })
        .then((res) => {
          if (!res) {
            console.warn("数据链接错误");
          }
          this.setTimer(false)
          return Promise.resolve()
        })
      // .then((res) => {
      //   if()
      //   this.isLoaded = true;
      //   this.setTimer();
      // }).catch(err => {
      //   console.log(err.response, "数据链接错误");
      // });
    },
    // 流速球状态筛选
    handlerBallStatus() {
      this.getPosBySn();
    },
    // sn筛选
    handlerSnFilter(e) {
      this.isRecord = false;
      if(e.length === 0){
        this._current_look_at = null;
      }
      this._sn_select(this.queryData.sns)
      this.getPosBySn();
    },
    // 实时更新流速球位置
    setTimer(clear = false) {
      if (clear) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        if (this.io) {
          this.io.close();
          this.io = null;
        }
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.getPosBySn();
      }, 5000);
    },
  }
}
export default {
  ballDetail: [
    {
      label: "type",
      key: "type",
    },
    {
      label: "sn",
      key: "sn",
    },
    {
      label: "guisu",
      key: "username",
    },
    {
      label: "status",
      key: "status",
    },
    {
      label: "date",
      key: "last_online",
    },
  ],
  computIntersectPoint: [
    {
      label: 'lon',
      key: 'lon'
    },
    {
      label: 'lat',
      key: 'lat'
    },
    {
      label: 'date',
      key: 'time',
      unit: ""
    },
    {
      label: 'coefficient',
      key: 'coefficient',
      type: "input",
      min: 0,
      max: 1,
      maxLength: 1,
      unit: ""
    },
    {
      label: 'area',
      key: 'area',
      type: "input",
      min: 0,
      max: 100000,
      maxLength: 6,
      unit: "㎡"
    },
    {
      label: 'speed',
      key: 'speed',
      unit: "m/s"
    },
    {
      label: 'flow',
      key: 'flow',
      type: "input",
      unit: "m³/s",
      disable: true,
    },
  ],
  intersectPointDetail: [
    {
      label: 'lon',
      key: 'lon'
    },
    {
      label: 'lat',
      key: 'lat'
    },
    {
      label: 'speed',
      key: 'speed',
      unit: "m/s"
    },
    {
      label: 'azimuth',
      key: 'angle',
      unit: "°"
    }
  ],
  sectionLineDetail: [
    {
      label: 'sectionLinetitle',
      key: 'title'
    },
    {
      label: 'start_lng',
      key: 'start_lng'
    },
    {
      label: 'start_lat',
      key: 'start_lat'
    },
    {
      label: 'end_lng',
      key: 'end_lng'
    },
    {
      label: 'end_lat',
      key: 'end_lat'
    },
  ],
  trackPointDetail: [
    {
      label: 'lon',
      key: 'lon'
    },
    {
      label: 'lat',
      key: 'lat'
    },
    {
      label: 'speed',
      key: 'speed',
      unit: "m/s"
    },
    {
      label: 'azimuth',
      key: 'angle',
      unit: "°"
    }
  ]
}
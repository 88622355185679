<template>
  <el-drawer :title="$t('dialog.addSectionPoint.title')" custom-class="addSectionPoint" :visible="show" direction="rtl"
    :size="`calc(100% - ${$store.getters.deviceInfo.type == 'PC'?($store.getters.sideNavOpen ? '210px' : '64px'):'0px'})`" @close="close">
    <div class="addSectionPoint" :class="$store.getters.deviceInfo.type">
      <main-map v-if="show" :modules="['ball', 'track', 'section']" :ability="['section_add','section_del']" :autoSectionLoad="true"></main-map>
    </div>
  </el-drawer>
</template>
    
<script>
import MainMap from "../../../components/public/mainMap"
export default {
  name: "addSectionPoint",
  components: {
    MainMap
  },
  data() {
    return {
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  mounted() {

  },
  methods: {
    close() {
      this.$emit('update:show', false)
      this.$emit('getData')
    }
  },
};
</script>
    
    
<style lang="scss" scoped>
.addSectionPoint {
  height: 100%;
  width: 100%;
  padding: 28px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 28px - 28px);
    height: calc(100% - 28px - 28px);
    border: 1px solid #999;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}
.MOBIE{
  padding: 0;
  &::before{
    display: none;
  }
}
</style>
    
<template>
	<div class="datePicker">
		<div class="flex cell">
			<span v-if="showLable">时间范围：</span>
			<div style="flex: 1" @click="openPopup">
				<el-date-picker
					style="width: 100%"
					v-model="rangeStr"
					type="datetimerange"
					size="mini"
					readonly
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</div>
		</div>

		<Popup v-model="isShowPopup" position="bottom" round>
			<div class="title">请选择时间</div>
			<div class="flex timeRange">
				<div @click="openTimePopup(0)">
					<el-input
						v-model="timeRange[0]"
						readonly
						placeholder="请输入内容"
					></el-input>
				</div>
				<span>~</span>
				<div @click="openTimePopup(1)">
					<el-input
						v-model="timeRange[1]"
						readonly
						placeholder="请输入内容"
					></el-input>
				</div>
			</div>
			<div class="calendar">
				<van-calendar
					:show-title="false"
					:poppable="false"
					:min-date="min_date"
					:formatter="formatterDateClass"
					:max-date="max_date"
					color="#539d56"
					:default-date="range"
					:max-range="2"
					:allow-same-day="true"
					type="range"
					@confirm="confirmDate"
				/>
			</div>
		</Popup>
		<DatePicker
			:show.sync="isShowTime"
			@confirm="timeConfirm"
			:default-value="timeRange[currentIndex]"
		></DatePicker>
	</div>
</template>

<script>
	import { Calendar, Popup } from "vant";
	import DatePicker from "./datePicker.vue";
	import XEUtils from "xe-utils";
	export default {
		props: {
			value: {
				type: [String, Array],
				default: () => [],
			},
			formatter: {
				type: Function,
				default: null,
			},
			showLable: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				range: [new Date(), new Date()],
				max_date: new Date(),
				min_date: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
				rangeStr: [],
				timeRange: ["00:00:00", "23:59:59"],
				currentIndex: 0,
				isShowPopup: false,
				isShowTime: false,
			};
		},
		components: {
			Popup,
			DatePicker,
			VanCalendar: Calendar,
		},
		watch: {
			value: {
				handler(val) {
					if (val && val.length) {
						this.range = XEUtils.clone(val).map((f) => new Date(f));
						this.rangeStr = XEUtils.clone(val).map((f) =>
							XEUtils.toStringDate(f)
						);
						this.timeRange = this.rangeStr.map((f) =>
							XEUtils.toDateString(f, "HH:mm:ss")
						);
					}
				},
				deep: true,
				immediate: true,
			},
			timeRange: {
				handler(val) {
					let _arr = val.map((f) => new Date(`2024-06-06 ${f}`).getTime());
					if (_arr[1] < _arr[0]) {
						this.timeRange.reverse();
					}
				},
				deep: true,
			},
		},
		computed: {
			pickTitle({ currentIndex }) {
				return (
					"选择" +
					(currentIndex === 0
						? this.$t("public.start_date")
						: this.$t("public.end_date"))
				);
			},
		},
		methods: {
			formatterDateClass(e){
				if(this.$tool.getType(this.formatter) === "Function"){
					e.className =  this.formatter(e.date) || '';
				}
				return e;
			},
			confirmDate(e) {
				this.rangeStr = e.map((f, i) => {
					let str = XEUtils.toDateString(f, "yyyy-MM-dd");
					str += ` ${this.timeRange[i]}`;
					return str;
				});
				this.closePopup();
				this.checkChange();
			},
			timeConfirm(e) {
				this.$set(this.timeRange, this.currentIndex, e);
				// console.log(e);
			},
			checkChange() {
				if (this.rangeStr.filter((f) => f).length === 2) {
					this.$emit("input", this.rangeStr);
					this.$emit("confirm", this.rangeStr);
				}
			},
			openPopup(e) {
				this.isShowPopup = true;
			},
			openTimePopup(e) {
				this.currentIndex = e;
				this.isShowTime = true;
			},
			closePopup() {
				this.isShowPopup = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.datePicker {
		.cell {
			margin-top: 6px;
			::v-deep {
				.el-range__close-icon {
					display: none !important;
				}
				.el-date-editor .el-range-input {
					flex: 1;
				}
			}
		}
		.title {
			text-align: center;
			line-height: 2.5;
			font-size: 18px;
			// font-weight: bold;
		}
		.calendar {
			height: 502px;
		}
		.timeRange {
			padding: 0 16px;
			gap: 16px;
		}
	}
</style>
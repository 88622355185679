import { render, staticRenderFns } from "./resetPwd.vue?vue&type=template&id=a32cd0de&scoped=true&"
import script from "./resetPwd.vue?vue&type=script&lang=js&"
export * from "./resetPwd.vue?vue&type=script&lang=js&"
import style0 from "./resetPwd.vue?vue&type=style&index=0&id=a32cd0de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a32cd0de",
  null
  
)

export default component.exports
<template>
	<div class="receiverList" :class="$store.getters.deviceInfo.type">
		<div class="query">
			<div class="input-width">
				<div class="search_group">
					<div class="item">
						<span>截面名称：</span>
						<el-input size="small" placeholder="截面名称" style="width: 180px" v-model="queryData.line_name">
						</el-input>
					</div>
					<div class="btn_group" :style="{
		marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
		marginLeft: '10px',
	}">
						<el-button size="small" style="background-color: #539d56" @click="search">{{ $t("button.search")
							}}</el-button>
						<el-button size="small" style="background-color: #ef9a41" @click="reset">{{ $t("button.reset")
							}}</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="pageTitle btn_point">
			<span style="font-weight: 600; color: #333333">{{ $t("label.dataManage.page_title") }}：</span>
			<div class="btn_group">
				<el-button @click="delSection" size="small" type="danger">{{
		$t("button.del_section")
	}}</el-button>
				<el-button @click="openMap" size="small" type="primary">{{
		$t("button.add_section")
	}}</el-button>
			</div>
		</div>
		<template v-if="$store.getters.deviceInfo.type === 'PC'">
			<div class="table">
				<el-table :data="tableData" @selection-change="handleSelectionChange" ref="tableData" stripe
					:max-height="tableHeight" :size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'">
					<el-table-column type="selection" width="55"> </el-table-column>
					<el-table-column v-for="column in columns" :key="column" :prop="column"
						:label="$t(`label.sectionPoint.${column}`)" align="center" :min-width="column == 'operate'
		? '300px'
		: column == 'index'
			? '50px'
			: '100px'
		" :show-overflow-tooltip="true">
						<template slot-scope="{ row }">
							{{ row[column] }}
						</template>
					</el-table-column>
					<el-table-column :label="$t(`label.sectionPoint.map`)" align="center" min-width="60">
						<template slot-scope="{ row }">
							<el-button size="small" style="border-color: #409eff; background-color: #409eff" type="primary"
								@click="openDetail(row)">
								{{ $t(`label.sectionPoint.check`) }}
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination">
				<el-pagination background :layout="$store.getters.deviceInfo.type == 'PC'
		? 'total, prev, pager, next, jumper'
		: 'prev, pager, next'
		" :total="total" popper-class="mySelectType" :page-size="queryData.page_size" :current-page="queryData.page_num"
					@current-change="currentChange" :small="$store.getters.deviceInfo.type != 'PC'">
				</el-pagination>
			</div>
		</template>
		<template v-else>
			<div class="query" style="padding-bottom: 10px">
				<el-checkbox :indeterminate="choose.length ? choose.length < total : false
		" v-model="checkAll" label="全选"></el-checkbox>
			</div>
			<List ref="mList" :all="checkAll" @selection-change="handleSelectionChange"></List>
		</template>
		<add-section-point :show.sync="infoShow" @getData="getData"></add-section-point>
		<section-data :show.sync="isShowData" :data="currentDetail"></section-data>
	</div>
</template>

<script>
import AddSectionPoint from "./components/addSectionPoint.vue";
import SectionData from "./components/sectionData.vue";
import { delSection } from "../../api";
import { distance, point } from "@turf/turf";
import List from "./components/mList.vue";
export default {
	data() {
		return {
			columns: ["index", "name", "begin", "end"],
			tableData: [],
			queryData: {
				line_name: "",
				page_num: 1,
				page_size: 10,
			},
			choose: [],
			total: 0,
			tableHeight: 450,
			infoShow: false,
			isShowData: false,
			currentDetail: null,
			checkAll: false,
		};
	},
	components: {
		AddSectionPoint,
		SectionData,
		List,
	},
	watch: {
		"$store.getters.deviceInfo": {
			handler(val) {
				if (val.type === "PC") {
					this.$nextTick(() => {
						this.$refs.tableData.doLayout();
					});
				}
			},
			deep: true,
			immediate: true,
		},
	},
	activated() {
		this.getData();
	},
	provide() {
		return {
			_getTableData: this.getData,
			_openDetail: this.openDetail,
		};
	},
	mounted() {
		if (this.$store.getters.deviceInfo.type === "PC") {
			this.$nextTick(() => {
				// window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
				this.tableHeight =
					window.innerHeight - this.$refs.tableData.$el.offsetHeight - 155;
			});
		}
	},
	methods: {
		// 获取截面点对数据
		getData(e = null) {
			let query = {
				...this.queryData,
				page_num: e ? e.page_num : this.queryData.page_num,
			}
			return this.$api.sectionList(query).then((res) => {
				this.total = res.data.total;
				this.tableData = res.data.rows.map((f, i) => {
					return {
						...f,
						title: f.name,
						begin: `${f.begin_lon},${f.begin_lat}`,
						end: `${f.end_lon},${f.end_lat}`,
						index:
							(this.queryData.page_num - 1) * this.queryData.page_size + i + 1,
						dis:
							distance(
								point([f.begin_lon, f.begin_lat]),
								point([f.end_lon, f.end_lat])
							) * 1000,
					};
				});
				return Promise.resolve({
					data: this.tableData,
					params: {
						...query,
						total: this.total,
					},
				});
			});
		},
		openDetail(val = null) {
			this.currentDetail = val;
			this.isShowData = true;
		},
		search() {
			this.checkAll = false;
			if (this.$store.getters.deviceInfo.type === "PC") {
				this.getData();
			} else {
				this.$refs.mList.reSet();
			}
		},
		reset() {
			this.queryData.line_name = "";
			this.checkAll = false;
			if (this.$store.getters.deviceInfo.type === "PC") {
				this.getData();
			} else {
				this.$refs.mList.reSet();
			}
		},
		// 页数
		currentChange(e) {
			this.queryData.page_num = e;
			this.getData();
		},
		openMap() {
			this.infoShow = true;
		},
		// 选择设备
		handleSelectionChange(e) {
			this.choose = e;
			if (this.$store.getters.deviceInfo.type !== "PC") {
				let ref = this.$refs.mList;
				this.checkAll = ref.list.length === e.length;
			}
		},
		delSection() {
			if (!this.choose.length) {
				this.$message.warning(this.$t("label.sectionPoint.null_choose"));
				return;
			}
			this.$confirm(this.$t("label.sectionPoint.tip"), this.$t("public.tip"), {
				confirmButtonText: this.$t("public.submit"),
				cancelButtonText: this.$t("public.cancel"),
				type: "warning",
			})
				.then(() => {
					this.$api
						.delSection({
							id: this.choose.map((f) => {
								return f.id;
							}),
						})
						.then((res) => {
							if (this.choose.length >= this.queryData.page_size) {
								this.queryData.page_num -= 1;
							}
							if (this.queryData.page_num === 0) {
								this.queryData.page_num = 1;
							}
							this.getData();
						});
				})
				.catch(() => { });
		},
	},
};
</script>

<style lang="scss" scoped>
.receiverList {
	padding: 20px;

	.btn_point {
		display: flex;
		height: 40px;
		line-height: 40px;
		justify-content: space-between;
		align-items: center;

		.el-button {
			height: 32px;
		}
	}

	.query {
		display: flex;
		justify-content: space-between;
	}

	.input-width {
		max-width: 1000px;

		.search_group {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			font-size: 14px;
		}
	}

	.operate-btn {
		display: flex;
	}

	.btn_group {
		margin-left: 20px;
		display: flex;

		&>.el-button {
			min-width: 60px;
			color: #fff;
			border: 1px solid #fff;
			border-radius: 4px;
		}
	}

	.btn {
		min-width: 80px;
		height: 32px;
	}

	.table {
		margin-top: 10px;
		border-radius: 2px 2px 0px 0px;
		overflow: hidden;

		&::v-deep th.el-table__cell {
			background-color: $table_header;
			color: #fff;
		}

		&::v-deep td.el-table__cell {
			background: transparent !important;
		}

		&::v-deep .el-table__row--striped {
			background-color: #d2e9ce;
		}
	}

	.pagination {
		// display: flex;
		// align-items: center;
		// justify-content: flex-end;
		// margin-top: 12px;
		position: fixed;
		bottom: 0px;
		right: 20px;
		// width: 100%;
		display: flex;
		justify-content: flex-end;
		background-color: #ffffff;
		z-index: 999;
		padding-bottom: 15px;
		width: calc(100% - 230px);
	}

	::v-deep {
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #3b9167;
			color: #fff;
		}

		.el-pagination.is-background .btn-next,
		.el-pagination.is-background .btn-prev,
		.el-pagination.is-background .el-pager li {
			background-color: #ffffff;
			border: 1px solid #e8e8e8;
		}

		.el-pagination.is-background .el-pager li:hover {
			color: #3b9167;
		}

		.el-pagination__sizes .el-input .el-input__inner:hover {
			border-color: #3b9167;
		}

		.el-select .el-input__inner:focus {
			border-color: #3b9167;
		}

		.el-select-dropdown__item.selected {
			color: #3b9167 !important;
		}

		.el-button--success.is-disabled {
			background-color: #c0c4cc;
			border-color: #c0c4cc;
		}

		// .el-pagination.is-background .el-pager li:not(.disabled):hover {
		// 	color: #3b9167;
		// }
	}
}

.MOBIE {
	height: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.item {
		display: flex;
		width: 100%;
		align-items: center;

		&>span {
			width: 100px;
		}

		.el-input,
		.el-select {
			flex: 1;
			width: auto !important;
			margin-right: 0 !important;
		}
	}



	.search_group {
		padding-top: 10px;

		.btn_group {
			width: 100%;
			margin-left: 0 !important;

			.el-button {
				flex: 1;
			}
		}
	}


	::v-deep {
		.el-checkbox__inner {
			width: 16px;
			height: 16px;
		}

		.el-checkbox .el-checkbox__label {
			display: inline-block !important;
		}
	}

	.pagination {
		position: unset;
		width: 100%;
		padding: 0;
		margin-top: 16px;
		// margin-bottom: 16px;
	}

	.query {
		padding: 0 20px;
	}

	.pageTitle {
		padding: 0 20px;
	}
}

.selectsLabel {
	margin-left: 10px;
}
</style>
<style lang="scss">
.mySelectType {
	.el-select-dropdown__item.selected {
		color: #3b9167 !important;
	}
}
</style>
<style lang="scss">
.el-checkbox .el-checkbox__label {
	display: none;
}

.selectsLabel {
	margin-left: 10px;
}

.checkAll {
	padding: 0 20px;
	line-height: 34px;
	font-size: 14px;
	color: #606266;

	.el-checkbox .el-checkbox__label {
		display: inline-block;
	}

	.is-checked {
		.el-checkbox__label {
			font-weight: 700;
		}
	}
}
</style>
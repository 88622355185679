<template>
	<custom-dialog :show="show" :title="title" @toggleShow="handleClose" width="620">
		<div class="form" :class="$store.getters.deviceInfo.type" slot="main">
			<el-form ref="form" :model="form" :rules="rules" inline size="small"
				:label-width="$store.getters.deviceInfo.type != 'PC' ? 'auto' : '100px'" :label-position="lang == 'ru-RU' ||
		lang == 'tr-TR' ||
		lang == 'es' ||
		$store.getters.deviceInfo.type != 'PC'
		? 'top'
		: 'right'
		">
				<el-form-item :label="$t('label.taskManage.taskName') + '：'" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('label.taskManage.dateRange') + '：'" prop="date">
					<template v-if="$store.getters.deviceInfo.type == 'PC'">
						<el-date-picker value-format="timestamp" v-model="form.date" type="datetimerange"
							:picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange">
						</el-date-picker>
					</template>
					<template v-else>
						<DatePicker v-model="form.date" :showLable="false"></DatePicker>
					</template>
				</el-form-item>
				<el-form-item :label="$t('label.taskManage.connect') + '：'" prop="connect">
					<el-select v-model="form.connect" multiple filterable :multiple-limit="3" placeholder="请选择关联设备">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('label.taskManage.point') + '：'" prop="region">
					<el-select v-model="form.point" :multiple-limit="8" multiple placeholder="请选择截面点对">
						<el-option v-for="item in pointOp" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('label.taskManage.system') + '：'" prop="region">
					<el-select v-model="form.system" placeholder="请选择坐标系统">
						<el-option v-for="item in systemOp" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<div class="flex btns" slot="footer">
			<el-button type="success" class="btn cancel" size="small" @click="handleClose">
				{{ $t("dialog.userList.cancel") }}
			</el-button>
			<el-button type="primary" class="btn save" size="small" @click="submitForm">
				{{ $t("dialog.userList.submit") }}
			</el-button>
		</div>
	</custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import {
	addTask,
	pointList,
	systemL,
	getTaskInfo,
	updSystem,
} from "@/api/index";
import DatePicker from "@/components/public/baseDatePicker";
export default {
	name: "EditUser",
	components: {
		CustomDialog,
		DatePicker,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		rowInfo: {
			type: Object,
			default: () => {
				return null;
			},
		},
		options: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		let _minTime = "";
		let _maxTime = "";
		return {
			form: {
				name: "",
				date: "",
				connect: [],
				point: [],
				system: null,
			},
			rules: {
				name: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
				date: [{ required: true, message: "请选择时间范围", trigger: "blur" }],
				connect: [
					{
						required: true,
						message: "请选择关联设备",
						trigger: ["blur", "change"],
					},
				],
			},
			connectOp: [],
			pointOp: [],
			systemOp: [],
			pickerOptions: {
				onPick(time) {
					// 如果选择了只选择了一个时间
					if (!time.maxDate) {
						let timeRange = 2 * 24 * 60 * 60 * 1000; // 6天
						_minTime = time.minDate.getTime() - timeRange; // 最小时间
						_maxTime = time.minDate.getTime() + timeRange; // 最大时间
						// 如果选了两个时间，那就清空本次范围判断数据，以备重选
					} else {
						_minTime = _maxTime = null;
					}
				},
				disabledDate(time) {
					// onPick后触发
					// 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
					if (_minTime && _maxTime) {
						return time.getTime() < _minTime || time.getTime() > _maxTime;
					}
				},
			},
			value1: [],
		};
	},
	computed: {
		title() {
			if (this.rowInfo && this.rowInfo.id) {
				return this.$t("label.taskManage.edit_title");
			} else {
				return this.$t("label.taskManage.add_title");
			}
		},
		lang() {
			return this.$store.getters.lang;
		},
	},
	watch: {
		rowInfo: {
			handler(val) {
				// console.log(val, "vvvvvvvvvvvvvv")
				if (val.id && this.show) {
					this.getTaskInfo(val.id);
				}
			},
			deep: true,
			// immediate: true,
		}
	},
	activated() {
		// this.getAgencyList();
		this.getPointList();
		this.getSystemList();
	},
	mounted() { },
	methods: {
		getTaskInfo(id) {
			let param = {
				task_id: id,
			};
			getTaskInfo(param).then((res) => {
				if (res.code == 200) {
					this.form.name = res.data.name;
					this.form.date = [
						this.getTimeFormat(res.data.begin_at),
						this.getTimeFormat(res.data.end_at),
					];
					this.form.connect = res.data.sn;
					this.form.point = res.data.line_info.map((item) => {
						return item.line_id;
					});
					this.form.system = res.data.coordinate_info.coordinate_id;
				}
			});
		},
		getSystemList() {
			let param = {
				page_num: 1,
				page_size: 200,
			};
			systemL(param).then((res) => {
				// console.log(res, "55555");
				this.systemOp = res.data.rows.map((item) => {
					return {
						label: item.coordinate_name,
						value: item.id,
					};
				});
			});
		},
		// 年月日时分秒转时间戳
		getTimeFormat(timeS) {
			let time = new Date(timeS).getTime(); //除1000 是变成秒级的时间戳 不除就是毫秒级
			return time;
		},
		// 获取截面点对下拉
		getPointList() {
			let param = {
				page_num: 1,
				page_size: 200,
			};
			pointList(param).then((res) => {
				// console.log(res, "55555")
				this.pointOp = res.data.rows.map((item) => {
					return {
						value: item.id,
						label: item.name,
					};
				});
			});
		},
		dateChange() {
			console.log(this.form, "ffff");
		},
		handleClose(done) {
			// return
			this.$emit("toggleShow", null, {});
			// this.$emit("rowInfo:update", {});
			(this.form = {
				name: "",
				date: "",
				connect: [],
				point: [],
				system: null,
			}),
				// this.$refs["form"].resetFields();
				setTimeout(() => {
					// 关闭弹框清除校验
					this.$refs["form"].clearValidate();
				}, 0);
		},
		submitForm() {
			// addTask
			if (this.rowInfo.id && this.show) {
				// 编辑
				let param = {
					task_id: this.rowInfo.id,
					task_name: this.form.name,
					begin_at: new Date(this.form.date[0]).getTime() / 1000,
					end_at: new Date(this.form.date[1]).getTime() / 1000,
					sn: this.form.connect,
					line_id: this.form.point,
					coordinate_id: this.form.system ? Number(this.form.system) : null,
				};
				this.$refs["form"].validate((valid) => {
					if (valid) {
						updSystem(param).then((res) => {
							if (res.code == 200) {
								this.$message({
									type: "success",
									message: "修改成功",
								});
								this.handleClose();
								this.$emit("refresh");
							} else {
								this.$message({
									type: "success",
									message: res.msg,
								});
							}
						});
					} else {
						return false;
					}
				});
			} else {
				// 新增
				let param = {
					task_name: this.form.name,
					begin_at: new Date(this.form.date[0]).getTime() / 1000,
					end_at: new Date(this.form.date[1]).getTime() / 1000,
					sn: this.form.connect,
					line_id: this.form.point,
					coordinate_id: this.form.system ? Number(this.form.system) : null,
				};
				this.$refs["form"].validate((valid) => {
					if (valid) {
						addTask(param).then((res) => {
							if (res.code == 200) {
								this.$message({
									type: "success",
									message: "新建成功",
								});
								this.handleClose();
								this.$emit("refresh");
							}
						});
					} else {
						return false;
					}
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.btns {
	justify-content: center;

	.btn {
		width: 180px;
		height: 32px;
	}

	.save {
		background-color: $main_color;
		border-color: $main_color;
	}

	.cancel {
		border-color: $main_color;
		background-color: #fff;
		color: $main_color;
		margin-left: 30px;
	}
}



.form {
	max-height: 400px;
	justify-content: center;
}
::v-deep {
	// .el-form-item__content {
	// 	min-width: 400px;
	// }

	.el-dialog__body {
		text-align: center;
	}

	.el-select {
		width: 100%;
	}

	.el-date-editor {
		width: 100%;
	}
}
.PC {
	::v-deep {
		.el-form-item__content {
			min-width: 400px;
		}
	}
}

.MOBIE {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	.el-form-item--small.el-form-item {
		text-align: left;
	}

	::v-deep {
		.el-form-item {
			width: 100%;
		}
	}


}
</style>
<style lang="scss">
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
	background-color: rgba(210, 233, 206, 1);
	color: rgba(51, 51, 51, 1);
	font-weight: 400;
}

.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
	background-color: rgba(83, 157, 86, 1);
	font-weight: 400;
}

.el-icon-check:before {
	display: none;
}
</style>
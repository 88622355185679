<template>
	<div class="satellite">
		<div class="pageTitle">{{ $t("label.satellite.page_title") }}</div>
		<el-button type="success" class="btn" size="small" @click="refresh">{{
			$t("public.refresh")
		}}</el-button>
		<div class="satellite-table">
			<el-table :data="tableData" ref="tableData"  stripe>
				<el-table-column
					v-for="column in columns"
					:key="column"
					:prop="column"
					:label="$t(`label.satellite.${column}`)"
					align="center"
					min-width="100px"
				>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {starsInfo} from "@/api/index"
	export default {
		data() {
			return {
				columns: [
					"satellite_type",
					"satellite_code",
					"elevating_angle",
					"azimuth",
					"B1_L1",
					"B2_L2",
					"B3_L3",
				],

				tableData: [],
			};
		},
		mounted(){
		},
		activated(){
			this.getStarsInfo()
		},
		watch:{
			'$store.getters.deviceInfo': {
				handler(val) {
					// console.log(val);
					this.$nextTick( ()=> {
						this.$refs.tableData.doLayout();
					});
				},
				deep: true,
				immediate: true,
			},
		},
		methods:{
			// 刷新信息
			refresh(){
				this.getStarsInfo()
			},
			getStarsInfo(){
				// let param = {
				// 	sn:JSON.parse(localStorage.getItem('receiverInfo')).sn
				// }
				// starsInfo(param).then(res=>{
				// 	this.tableData = []
				// 	if(res.code === 200){
				// 		res.data.list.map((item,index)=>{
				// 			// 对返回字段的key处理
				// 			let newObj = {}
				// 			newObj.satellite_type = item.type
				// 			newObj.satellite_code = item.name
				// 			newObj.elevating_angle = item.high
				// 			newObj.azimuth = item.atz
				// 			newObj.B1_L1 = item.bl1
				// 			newObj.B2_L2 = item.bl2
				// 			newObj.B3_L3 = item.bl3
				// 			this.tableData.push(newObj)
				// 		})
				// 	}
					
				// })
			},
		}
	};
</script>

<style lang="scss" scoped>
	.satellite {
		padding: 0 10px;
		.btn {
			width: 70px;
			height: 30px;
			line-height: 30px;
			background-color: $main_color;
			border-color: $main_color;
			padding: 0;
		}
		&-table {
			margin-top: 10px;
			// width: 1000px;
			border-radius: 2px 2px 0px 0px;
			overflow: hidden;
			&::v-deep th.el-table__cell {
				background-color: $table_header;
				color: #fff;
			}
			&::v-deep td.el-table__cell {
				background: transparent !important;
			}
			&::v-deep .el-table__row--striped {
				background-color: #d2e9ce;
			}
		}
	}
</style>
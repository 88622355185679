<template>
  <div class="postSet">
    <div class="pageTitle">{{ $t("label.postSet.page_title") }}</div>
    <div class="postSet-table">
      <el-table :data="tableData" ref="tableData" style="width: 100%" stripe>
        <template v-for="column in columns">
          <template v-if="column == 'index'">
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              min-width="60"
              align="center"
            >
            </el-table-column>
          </template>
          <template v-else-if="column == 'operate'">
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              align="center"
              min-width="150"
            >
              <template slot-scope="{ row }">
                <!-- <el-button size="mini">{{
									$t("label.postSet.start")
								}}</el-button> -->
                <el-button size="mini" @click="openEditPost(row)">{{
                  $t("label.postSet.setting")
                }}</el-button>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              align="center"
              min-width="100"
            >
              <template slot-scope="{ row }">
                <template v-if="column == 'input'">
                  <span v-if="row.input">
                    {{ row[column] }}
                  </span>
                  <span v-else> —— </span>
                </template>
                <template v-else-if="column == 'output'">
                  <span v-if="row.output">
                    {{ row[column] }}
                  </span>
                  <span v-else> —— </span>
                </template>
                <template v-else>
                  {{ row[column] ? row[column] : "——" }}
                </template>
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>
    <edit-post
      :show="showPost"
      :rowInfo="rowInfo"
      @toggleShow="closeDialog"
      @refush="getIOList"
    ></edit-post>
  </div>
</template>

<script>
import { IOList } from "@/api/index";
import EditPost from "./components/editPost.vue";
export default {
  components: {
    EditPost,
  },
  data() {
    return {
      columns: [
        "index",
        "type",
        "IPAddress",
        "post",
        "input",
        "output",
        "operate",
      ],
      tableData: [
        // {
        // 	index: 1,
        // 	IPAddress:"10.233.117.111",
        // 	type: "TCP_Client1",
        // 	post: "172.18.210.17:7000",
        // 	input:"RTCM3.0",
        // 	output: "GGA（1Hz）",
        // },
        // {
        // 	index: 2,
        // 	IPAddress:"10.233.117.111",
        // 	type: "Ntrip_Client1",
        // 	post: "172.18.210.17:7001",
        // 	input:"",
        // 	output: "",
        // },
        // {
        // 	index: 3,
        // 	IPAddress:"10.233.117.111",
        // 	type: "TCP_Client2",
        // 	post: "172.18.210.17:7002",
        // 	input:"RTCM3.0",
        // 	output: "RTCM3.2",
        // },
        // {
        // 	index: 4,
        // 	IPAddress:"10.233.117.111",
        // 	type: "Ntrip_Client2",
        // 	post: "172.18.210.17:7003",
        // 	input:"",
        // 	output: "RTCM3.2",
        // },
        // {
        // 	index: 5,
        // 	IPAddress:"10.233.117.111",
        // 	type: "TCP Server1",
        // 	post: "7001",
        // 	input:"RTCM3.0",
        // 	output: "RTCM3.2",
        // },
        // {
        // 	index: 6,
        // 	IPAddress:"10.233.117.111",
        // 	type: "TCP Server2",
        // 	post: "7002",
        // 	input:"RTCM3.0",
        // 	output: "RTCM3",
        // },
        // {
        // 	index: 7,
        // 	IPAddress:"10.233.117.111",
        // 	type: "Ntrip Server1",
        // 	post: "7003",
        // 	input:"RTCM3.0",
        // 	output: "RTCM3",
        // },
        // {
        // 	index: 8,
        // 	IPAddress:"10.233.117.111",
        // 	type: "Ntrip Server2",
        // 	post: "7004",
        // 	input:"RTCM3.0",
        // 	output: "RTCM3",
        // },
      ],
      showPost: false,
      rowInfo: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },

  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        // console.log(val);
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.getIOList();
  },
  mounted() {
  },
  methods: {
    getIOList() {
      let param = {
        // sn: JSON.parse(localStorage.getItem("receiverInfo")).sn,
      };
      // IOList(param).then((res) => {
      //   // console.log(res,"-=-=-=-=-")
      //   this.tableData = res.data.config_list.map((item, index) => {
      //     if (item.protoc == 1) {
      //       item.protoc = "TCP Client";
      //     } else if (item.protoc == 2) {
      //       item.protoc = "Ntrip Client";
      //     } else if (item.protoc == 3) {
      //       item.protoc = "Ntrip Server";
      //     } else {
      //       item.protoc = "";
      //     }
      //     return {
      //       id: item.server_id,
      //       index: index + 1,
      //       IPAddress: this.formatIP(item.ip),
      //       type: item.protoc,
      //       post: item.port,
      //       // input: item.in_put,
      //       // output: item.out_put
      //     };
      //   });
      // });
    },
	// 格式化ip
    formatIP(ip) {
        ip = ip.split(".").map((item) => {
          if (item.startsWith("00")) {
            return item.slice(2);
          }
          if (item.startsWith("0")) {
            return item.slice(1);
          } else {
            return item;
          }
        }).join(".");
      	return ip;
    },
    openEditPost(val) {
      this.showPost = true;
      this.rowInfo = val;
    },
    closeDialog() {
      this.showPost = false;
      this.rowInfo = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.postSet {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &-table {
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;
  }
  &::v-deep th.el-table__cell {
    background-color: $table_header;
    color: #fff;
  }
  &::v-deep td.el-table__cell {
    background: transparent !important;
  }
  &::v-deep .el-table__row--striped {
    background-color: #d2e9ce;
  }
}
// .status{
// 	width: 20px;
// 	height: 20px;
// 	display: flex;
// 	justify-content: center;
// 	background-color: red;
// }
</style>
<template>
  <base-popup id="IntersectPointDetail">
    <div class="flex-dc intersectPointDetail">
      <div class="title">
        {{ lang.title }}
      </div>
      <div class="cells">
        <div class="flex cell" v-for="(cell, index) in items" :key="`${cell.key}_${index}`">
          <span class="label">
            {{ lang[cell.label] }}：
          </span>
          <span class="value flex" v-if="cell.type === 'input'">
            <el-input v-model="form[cell.key]" :min="cell.min || -999999" :maxlength="cell.maxLength || 20" :max="cell.max || 999999" type="number"
              :disabled="cell.disable" size="small" :placeholder="lang[cell.label]"></el-input>
            <em v-if="cell.unit" style="margin-left: 4px;">{{ cell.unit }}</em>
          </span>
          <span class="value omit-1" v-else>
            {{ detail[cell.key] || '-' }}{{ cell.unit }}
          </span>
        </div>
      </div>
    </div>
  </base-popup>
</template>

<script>
import BasePopup from "../../basePopup";
import mapPopup from "./mapPopup"
export default {
  name: 'ComputIntersectPoint',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    },
    lang: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        coefficient: 0.85,
        area: null,
        flow: null
      },
      items: mapPopup.computIntersectPoint
    };
  },
  computed: {
    flow({ form, detail }) {
      const { area = 0, coefficient = 0 } = form;
      return detail.speed * area * coefficient
    }
  },
  watch:{
    flow:{
      handler(val){
        this.form.flow = val;
      },
      deep:true,
      immediate:true
    }
  },
  components: {
    BasePopup
  }
};
</script>

<style lang="scss" scoped>
#IntersectPointDetail {
  width: 250px;
  min-height: 160px;
  color: rgba(51, 51, 51, 1);

  .intersectPointDetail {
    height: 100%;
    padding: 20px;
    padding-top: 0;

    .title {
      padding: 12px 0;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
    }

    .cells {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;

      .cell {
        justify-content: space-between;
        font-size: 12px;
        line-height: 24px;
        padding: 4px 0;

        .label {
          color: #666666;
        }

        .value {
          color: #333333;
          flex: 1;
          text-align: right;
          max-width: 60%;
        }
      }
    }
  }
}
</style>

<template>
	<div class="model">
		<div class="pageTitle">{{ $t("label.model.page_title") }}</div>
		<div class="model-form">
			<el-form
				ref="form"
				:model="form"
				label-width="120px"
				:label-position="lang == 'ru-RU' || lang == 'tr-TR' || lang == 'es'?'top':'left'"
				:rules="rules"
			>
				<el-form-item :label="$t('label.model.start_model') + '：'">
					<el-select
						v-model="form.start_model"
						style="width: 268px"
						:placeholder="$t('label.model.start_model')"
						@change="formReset"
					>
						<el-option
							v-for="option in $t('option.model.start_model_options')"
							:key="option.value"
							:label="option.label"
							:value="option.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<template v-if="form.start_model == '2'">
					<el-form-item
						:label="$t(item.label) + '：'"
						v-for="item in formItem"
						:prop="item.key"
						:key="item.key"
					>
						<template v-if="item.inputType == 'select'">
							<el-select
								v-model="form[item.key]"
								style="width: 268px"
								:placeholder="$t(item.label)"
							>
								<el-option
									v-for="option in $t(item.selectKey)"
									:key="option.value"
									:label="option.label"
									:value="option.value"
								>
								</el-option>
							</el-select>
						</template>
						<template v-else-if="item.inputType == 'input'">
							<template v-if="item.key == 'elevation'">
								<div class="flex">
									<el-input
										v-model="form[item.key]"
										:placeholder="$t(item.label)"
									></el-input>
									<span style="margin: 0 12px">m</span>
									<el-button type="primary" class="btn save" size="small" @click="getHigh">
										{{ $t("label.model.getLonlat") }}
									</el-button>
								</div>
							</template>
							<template v-else>
								<el-input
									maxlength="15"
									style="width: 268px"
									v-model="form[item.key]"
									:placeholder="$t(item.label)"
								></el-input>
							</template>
						</template>
					</el-form-item>
				</template>
			</el-form>
		</div>
		<div class="model-btns">
			<el-button type="primary" class="btn save" size="small" @click="save">
				{{ $t("label.model.save") }}
			</el-button>
			<el-button type="success" class="btn cancel" size="small">
				{{ $t("label.model.cancel") }}
			</el-button>
		</div>
	</div>
</template>

<script>
	import { receiverProConfig, receiverInfo, IOList } from "@/api/index"
	export default {
		data() {
			// 经度
			var longitude = (rule, value, callback) => {
				// console.log(value)
				if (Number(value) >= 0 && Number(value) <= 180){
					callback();
				} else {
					return callback(new Error('请输入0-180之间的数值！'));
				}
			}
			// 纬度
			var latitude = (rule, value, callback) => {
				// console.log(value)
				if (Number(value) >= 0 && Number(value) <= 90){
					callback();
				} else {
					return callback(new Error('请输入0-90之间的数值！'));
				}
			}
			var elevation = (rule, value, callback) => {
				// console.log(value)
				if (Number(value) >= -9999.999 && Number(value) <= 9999.999){
					callback();
				} else {
					return callback(new Error('请输入-9999.999~9999.999之间的数值！'));
				}
			}
			return {
				formItem: [
					{
						label: "label.model.lonlat_format",
						key: "lonlat_format",
						inputType: "select",
						selectKey: "option.model.lonlat_format_options",
					},
					{
						label: "label.model.longitude",
						key: "longitude",
						inputType: "input",
					},
					{
						label: "label.model.latitude",
						key: "latitude",
						inputType: "input",
					},
					{
						label: "label.model.elevation",
						key: "elevation",
						inputType: "input",
					},
				],
				form: {
					start_model: "",
					elevation: "", // 大地高
					lonlat_format: "1", // 经纬度格式
					latitude: "",
					longitude: ""
				},
				rules: {
					longitude: [ // 经度
						{ validator: longitude, trigger: 'change' }
					],
					latitude: [ // 纬度
						{ validator: latitude, trigger: 'change' }
					],
					elevation: [ // 大地高
						{ validator: elevation, trigger: 'change' }
					],
				}
			};
		},
		computed:{
			lang(){
				return this.$store.getters.lang;
			}
		},
		activated(){
			this.getStartModel()
			this.form = {
				start_model: "",
				elevation: "", // 大地高
				lonlat_format: "1", // 经纬度格式
				latitude: "",
				longitude: ""
			}
		},
		methods:{
			getStartModel(){
				// let param = {
				// 	sn: JSON.parse(localStorage.getItem('receiverInfo')).sn
				// }
				// IOList(param).then(res=>{
				// 	let data = []
				// 	if(res.code === 200){
				// 		data = res.data.config_list.filter(item=>{
				// 			return item.server_id == 0
				// 		})
				// 		this.form.start_model = data[0].zjz_mod + 1 + ""
				// 	}
				// })
			},
			// 获取大地高度及经纬度数据
			getHigh(){
				// let param = {
				// 	sn: JSON.parse(localStorage.getItem('receiverInfo')).sn
				// }
				// receiverInfo(param).then(res=>{
				// 	// console.log(res)
				// 	if(res.code === 200){
				// 		this.form.elevation = res.data.high + ''
				// 		this.form.latitude = res.data.lat + ''
				// 		this.form.longitude = res.data.lng + ''
				// 		if(!this.form.elevation){
				// 			this.$message({
				// 				type: 'error',
				// 				// message: '获取失败，请重新获取！'
				// 				message: this.$t("label.model.get_high_err"),
				// 				duration: 2000
				// 			})
				// 		} else {
				// 			this.$message({
				// 				type: 'success',
				// 				// message: '获取成功！'
				// 				message: this.$t("label.model.get_high_success"),
				// 				duration: 2000
				// 			})
				// 		}
				// 	}
				// 	console.log(this.form)
				// })
			},
			// 保存
			save(){
				// console.log(this.form)
				this.$refs['form'].validate((valid) => {
					if (valid) {
						// let param = {
						// 	deviceid:[JSON.parse(localStorage.getItem('receiverInfo')).sn], 
						// 	pos_config:{
						// 		zjz_mod:Number(this.form.start_model) - 1, // 启动模式
						// 		pos_mod:this.form.lonlat_format ? this.form.lonlat_format : '', // 经纬度格式
						// 		get_pos:this.form.elevation ? this.form.elevation : '' // 大地高
						// 	},
						// }
						// receiverProConfig(param).then(res=>{
						// 	if(res.code === 200){
						// 		this.$message({
						// 			// message: '保存修改成功！',
						// 			message: this.$t("label.model.save_success"),
						// 			type: 'success',
						// 			duration: 2000
						// 		});
						// 	}else {
						// 		// this.$message.error('网络错误，请重新保存！');
						// 		this.$message({
						// 			message: res.msg,
						// 			type: 'error',
						// 			duration: 2000
						// 		});
						// 	}
						// })
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
				
			},
			formReset(e){
				console.log(2222,this.form)
				this.form.latitude = ""
				this.form.longitude = ""
				this.form.elevation = ""
				this.form.lonlat_format = "1"
				// this.form = {
				// 	// latitude: "",
				// 	// longitude: "",
				// 	// start_model: "",
				// 	elevation: "", // 大地高
				// 	lonlat_format: "1" // 经纬度格式
				// }
			},
		}
	};
</script>

<style lang="scss" scoped>
	.model {
		padding: 10px;
		&-form {
			max-width: 500px;
		}

		.btn {
			width: 180px;
			height: 32px;
		}
		.save {
			background-color: $main_color;
			border-color: $main_color;
		}
		.cancel {
			border-color: $main_color;
			background-color: #fff;
			color: $main_color;
			margin-left: 30px;
		}
	}
</style>
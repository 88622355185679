<template>
	<el-drawer
		:title="$t('dialog.sectionPoint.title')"
		custom-class="addSectionPoint"
		:visible="show"
		:destroy-on-close="true"
		direction="rtl"
		:size="`calc(100% - ${
			$store.getters.deviceInfo.type == 'PC'
				? $store.getters.sideNavOpen
					? '210px'
					: '64px'
				: '0px'
		})`"
		@close="close"
	>
		<div
			class="addSectionPoint"
			:class="$store.getters.deviceInfo.type"
			v-if="show"
		>
			<div class="params">
				<div class="import">
					<el-input
						style="width: 200px; height: 60px"
						placeholder="请选择文件"
						readonly
						v-model="fileName"
					>
						<div style="height: 60px; gap: 6px 0" class="flex-dc" slot="append">
							<el-button>导入</el-button>
							<el-button style="z-index: 20" @click="checkData">选择</el-button>
						</div>
					</el-input>
					<el-upload
						class="upload"
						drag
						:show-file-list="false"
						accept=".xlsx, .xls"
						:http-request="handlerFile"
						action="https://jsonplaceholder.typicode.com/posts/"
					>
					</el-upload>
				</div>
				<div style="margin-top: 10px">
					<el-input
						style="width: 200px"
						placeholder="请输入水位值"
						type="number"
						v-model="line"
					>
						<div slot="append" class="flex-dc" style="height: 38px">
							<el-button @click="addWarterLine(true)">计算</el-button>
						</div>
					</el-input>
				</div>
				<div style="font-size: 14px; line-height: 3em">
					断面面积：{{ area }}㎡
				</div>
				<el-button
					style="width: 60%; margin: 0px auto; display: block"
					v-if="area"
					@click="saveData"
					:loading="loading"
					>保存</el-button
				>
			</div>
			<section-data-detail
				ref="detail"
				:data="currentData"
				:show="isShowData"
				@close="isShowData = false"
			></section-data-detail>
			<main-map
				v-if="show"
				:autoLocate="false"
				@inited="inited"
				:modules="['section']"
				:ability="[]"
				:autoSectionLoad="false"
			></main-map>
		</div>
	</el-drawer>
</template>
    
<script>
	import MainMap from "../../../components/public/mainMap";
	import sectionDataDetail from "./sectionDataDetail.vue";
	import { readFile, utils, writeFile, write } from "xlsx";
	export default {
		name: "sectionPoint",
		components: {
			MainMap,
			sectionDataDetail,
		},
		data() {
			return {
				fileName: "",
				line: "",
				currentData: [],
				loading: false,
				isShowData: false,
				currentUrl: "",
				area: 0,
			};
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			data: {
				type: [Object, null],
				default: () => {
					return null;
				},
			},
		},
		provide() {
			return {
				_custom_section_data: this.handlerCustomSectionData,
			};
		},
		watch: {
			show(val) {
				if (val) {
					this.getData();
				} else {
					this.isShowData = false;
					this.fileName = "";
					this.line = null;
					this.area = "";
					this.currentData = {};
				}
			},
		},
		mounted() {},
		methods: {
			getData() {
				this.$api.getSectionOne(this.data.id).then((res) => {
					if (res.data.sectionUrl) {
						this.fileName = res.data.sectionUrl.split("/").slice(-1)[0];
						this.currentUrl = res.data.sectionUrl;
					}
					if (res.data.waterLever) {
						this.line = res.data.waterLever;
					}
					if (res.data.area) {
						this.area = res.data.area;
					}
					this.$api.getFile(this.currentUrl).then(blob => {
						this.handlerFile({
							file:new File([blob], this.fileName, { type: blob.type })
						})
					});
				});
			},
			close() {
				this.$emit("update:show", false);
				this.$emit("getData");
			},
			checkData() {
				this.isShowData = true;
			},
			addWarterLine(flag=true) {
				if (!this.currentData.length) {
					this.$message({
						type: "warning",
						message: "文件数据异常",
						duration: 2000,
					});
					return;
				}
				if (!this.line && flag) {
					this.$message({
						type: "warning",
						message: "请输入水位值",
						duration: 2000,
					});
					return;
				}
				this.isShowData = true;
				this.$refs.detail.showWarterLine(this.line);
				this.$nextTick(() => {
					this.computedData();
				})
				
			},
			async saveData() {
				if (!this.area) {
					return;
				}
				this.loading = true;
				const exportArr = this.$refs.detail.tableData.map((f, i) => {
					return {
						name: f.start,
						value: f.height,
					};
				});
				const Header = [["起点距", "高程"]];
				const headerWs = utils.aoa_to_sheet(Header);
				const ws = utils.sheet_add_json(headerWs, exportArr, {
					skipHeader: true,
					origin: "A2",
				});
				const wb = utils.book_new();
				utils.book_append_sheet(wb, ws);
				let base64 = write(wb, { bookType: "xlsx", type: "base64" });
				const form = new FormData();
				let query = {
					id: this.data.id,
					sectionUrl: "",
					waterLever: this.line,
					area: this.area,
				};
				form.append("file", this.$tool.dataURLToBlob(base64, this.fileName));
				let msg = "";
				try {
					const urlData = await this.$api.uploadFile(form);
					if (urlData.code !== 200) {
						msg = "上传文件失败";
					}
					query.sectionUrl = urlData.data.url;
					const res = await this.$api.updateSection(query);
					if (res.code !== 200) {
						msg = "数据保存失败";
					}
				} catch (err) {
					console.log(err);
					this.loading = false;
					this.$message({
						type: "warning",
						message: msg,
						duration: 2000,
					});
				}
				this.$message({
					type: "success",
					message: "数据保存成功！",
					duration: 2000,
				});
				setTimeout(() => {
					this.loading = false;
					this.close();
				}, 1500);
			},
			computedData() {
				
				let filteredDistances = [];
				let filteredElevations = [];
				this.$refs.detail.tableData.forEach((e) => {
					if (e.start <= this.data.dis) {
						filteredDistances.push(e.start);
						filteredElevations.push(e.height);
					}
				});
				this.area = this.$tool.toFixedFn(
					this.calculateSectionArea(
						filteredDistances,
						filteredElevations,
						Number(this.line)
					)
				);
			},
			handlerCustomSectionData() {
				return [this.data];
			},
			async handlerFile(file) {
				let data = [];
				try {
					this.fileName = file.file.name;
					let workbook = readFile(await file.file.arrayBuffer());
					// console.log(workbook);
					if (!Object.keys(workbook.Sheets.Sheet1).length) {
						this.$message({
							type: "warning",
							message: "文件数据异常",
							duration: 2000,
						});
						return;
					}
					for (const key in workbook.Sheets.Sheet1) {
						if (key.indexOf("!") !== -1 || key.indexOf("B") !== -1) {
							continue;
						}
						let index = Number(key.replace("A", ""));
						let A = workbook.Sheets.Sheet1[key];
						let B = workbook.Sheets.Sheet1[`B${index}`];
						data.push({
							key: index,
							start: A.v,
							height: B.v,
						});
					}
					this.handlerShow(true);
				} catch (err) {
					console.error(err);
					this.fileName = "";
				}
				this.currentData = data.slice(1);
				this.addWarterLine(false);
			},
			handlerShow(val = false) {
				if (val && this.fileName) {
					this.isShowData = val;
				} else {
					this.isShowData = val;
				}
			},
			calculateSectionArea(filteredDistances, filteredElevations, waterLevel) {
				let sectionArea = 0;
				let lowerPoints = [];
				let higherPoints = [];
				// 分别将高程点位于水位线上方和下方的点分组
				for (let i = 0; i < filteredElevations.length; i++) {
					if (filteredElevations[i] <= waterLevel) {
						lowerPoints.push({
							distance: filteredDistances[i],
							elevation: filteredElevations[i],
						});
					} else {
						higherPoints.push({
							distance: filteredDistances[i],
							elevation: filteredElevations[i],
						});
					}
				}
				for (let i = 0; i < lowerPoints.length - 1; i++) {
					const base1 = Math.abs(lowerPoints[i].elevation - waterLevel);
					const base2 = Math.abs(lowerPoints[i + 1].elevation - waterLevel);
					const height = Math.abs(
						lowerPoints[i + 1].distance - lowerPoints[i].distance
					);
					sectionArea += this.calculateTrapezoidArea(base1, base2, height);
				}
				return sectionArea;
			},
			calculateTrapezoidArea(base1, base2, height) {
				return ((base1 + base2) * height) / 2;
			},
			inited(map) {
				if (!map) {
					return;
				}
				this._map = map;
				this._map.jumpTo({
					center: [this.data.begin_lon, this.data.begin_lat],
					zoom: 16,
				});
			},
		},
	};
</script>
    
    
<style lang="scss" scoped>
	.addSectionPoint {
		height: 100%;
		width: 100%;
		overflow: hidden;
		position: relative;
		.params {
			position: absolute;
			left: 0;
			top: 0;
			background-color: #fff;
			z-index: 10;
			padding: 10px;
			border-radius: 0 0 6px 0;
			&::v-deep {
				.el-input-group__append {
					padding: 0;
				}
				.el-button {
					flex: 1;
					padding: 0;
					margin: 0;
					width: 58px;
					height: 38px;
					font-size: 12px;
					background-color: #539d56;
					color: #fff;
				}
				.import {
					position: relative;
					.upload {
						position: absolute;
						z-index: 1;
						left: 0;
						top: 0;
						width: 200px;
						height: 60px;
						opacity: 0;
						.el-upload {
							width: 100%;
							height: 100%;
						}
						.el-upload-dragger {
							width: 100%;
							height: 100%;
						}
					}
					.el-input__inner {
						height: 62px;
					}
				}
			}
		}
	}
	.MOBIE {
		padding: 0;
		&::before {
			display: none;
		}
	}
</style>
    
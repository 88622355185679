<template>
	<el-dialog class="custom-dialog updataProgress" :visible.sync="dialogVisible" :show-close="true" width="400px"
		title="固件升级中" :before-close="handleClose">
		<div class="flex updataProgress-main">
			<el-progress :text-inside="true" color="#3EAD42" :stroke-width="22" :percentage="progress"
				:format="handlerLabel"></el-progress>
		</div>
	</el-dialog>
</template>

<script>

export default {
	name: "UpdataProgress",
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			dialogVisible: false,
		};
	},
	watch: {
		show: {
			handler(val) {
				this.dialogVisible = val;
			},
			immediate: true,
		},
		progress: {
			handler(val) {
				if (val == 100) {
					setTimeout(() => {
						this.handleClose()
					}, 1000)
				}
			}
		}
	},
	mounted() {
		// this.upload()
	},
	methods: {
		handleClose(done) {
			this.$emit("toggleShow", false);
		},
		handlerLabel(e) {
			return this.$t('public.progress', { progress: `${e}%` })
		}
	},
};
</script>
<style lang="scss" scoped>
.updataProgress {
	&-main {
		// height: 126px;
		height: 216px;
		background-color: #fff;
		border-radius: 0px 0px 8px 8px;
		padding: 30px;

		.el-progress {
			width: 100%;
		}

		&::v-deep .el-progress__text {
			color: $progress_color;
			font-size: 18px !important;
		}
	}
}
</style>
<template>
  <div>
    <div class="container" :style="{ width: deviceInfo.type == 'PC' ? '30%' : ''}">
      <div class="card">
        <div class="title">{{ $t("label.status.status") }}</div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.power") }}：</div>
          <div class="info_value">
            {{ detail.battery_info.percentage + "%" }}
          </div>
        </div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.voltage") }}：</div>
          <div class="info_value">
            {{ $tool.toFixedFn(detail.battery_info.voltage / 1000, 1) + "V" }}
          </div>
        </div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.charging") }}：</div>
          <div class="info_value">
            {{
              detail.battery_info.status == 0
                ? $t("label.activity.BT1")
                : detail.battery_info.status == 1
                ? $t("label.activity.BT2")
                : $t("label.activity.BT3")
            }}
          </div>
        </div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.type") }}：</div>
          <div class="info_value">
            {{ checkPowerType(detail.battery_info.type) }}
          </div>
        </div>
      </div>
      <div class="card">
        <div class="title">{{ $t("label.status.storage") }}</div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.used") }}：</div>
          <div class="info_value">
            {{
              `[ ${detail.storage_info.used_capacity}M / ${detail.storage_info.total_capacity}M ]`
            }}
          </div>
        </div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.proportion") }}：</div>
          <div class="info_value">{{ detail.storage_info.percent }}%</div>
        </div>
      </div>
      <!-- <div class="card">
        <div class="title">{{ $t("label.status.diff") }}</div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.work") }}：</div>
          <div class="info_value">
            {{ detail.diff_info.work_mode == 1 ? $t("label.activity.DT1") : $t("label.activity.DT2") }}
          </div>
        </div>
        <div class="info">
          <div class="info_title">{{ $t("label.status.data_line") }}：</div>
          <div class="info_value">
            {{ detail.diff_info.data_status == 1 ? $t("label.activity.DT3") : $t("label.activity.DT4") }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getActivity } from "@/api/index";
import $tool from "@/utils/tool";
export default {
  name: "ReceiverAdminR51Activity",
  data() {
    return {
      key: [],
      detail: {
        battery_info: {},
        storage_info: {},
        diff_info: {},
      },
    };
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  mounted() {
    this.getActivityInfo();
  },

  methods: {
    // 获取活动信息
    getActivityInfo() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 1
      };
      getActivity(param).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
        }
      });
    },
    checkPowerType(type) {
      let res = "";
      switch (type) {
        case 0:
          res = this.$t("label.activity.BT1");
          break;
        case 1:
          res = this.$t("label.activity.SD");
          break;
        case 2:
          res = this.$t("label.activity.QSDC");
          break;
        case 3:
          res = this.$t("label.activity.LDC");
          break;
        case 4:
          res = this.$t("label.activity.TYN");
          break;
        case 5:
          res = this.$t("label.activity.TQ");
          break;
        case 6:
          res = this.$t("label.activity.SL");
          break;
        default:
          break;
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  font-size: 14px;
  .card {
    padding: 0 10px 10px;
    margin-bottom: 10px;
    border: 1px solid #659b5d;
    border-radius: 5px;
    // width: 25%;
    .title {
      padding: 10px 0;
      border-bottom: 1px solid #659b5d;
    }
    .info {
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // &_title {
      //     margin-right: 100px;
      // }
    }
  }
}
</style>
<template>
  <div class="userList">
    <div class="fixed">
      <div class="query">
        <el-input
          :placeholder="$t('public.please_input')"
          v-model="queryData.keyWords"
          :style="{
            width: deviceInfo.type == 'PC' ? '300px' : '100%',
            marginBottom: '10px',
          }"
          @keyup.enter.native="toSearch"
          size="small"
        >
          <template slot="prepend">
            <el-select
              v-model="queryData.type"
              style="width: 110px"
              @change="selectChange"
            >
              <el-option
                v-for="item in $t('option.userList.query_options')"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- <template slot="append"> -->
          <!-- <el-button class="el-icon-search" @click="toSearch"></el-button> -->
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="toSearch"
          ></i>
          <!-- </template> -->
        </el-input>
        <el-button
          v-if="deviceInfo.type == 'PC'"
          size="small"
          style="
            background-color: #539d56;
            margin-left: 10px;
            color: #fff;
            border: none;
          "
          @click="toSearch"
          >查询</el-button
        >
      </div>
      <div v-if="deviceInfo.type !== 'PC'">
        <el-button
          size="small"
          style="
            background-color: #539d56;
            color: #fff;
            border: none;
            width: 100%;
          "
          @click="toSearch"
          >查询</el-button
        >
      </div>
      <div
        class="flex content_between"
        :style="{
          marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
        }"
      >
        <div
          class="pageTitle"
          style="line-height: 25px; font-weight: 600; color: #333333"
        >
          {{ $t("label.userList.page_title") }}：
        </div>
        <el-button
          type="success"
          style="margin-left: 20px; background: #539d56"
          size="small"
          @click="openDetail()"
          >{{ $t("label.userList.add") }}</el-button
        >
      </div>
    </div>
    <div class="table" v-if="deviceInfo.type == 'PC'">
      <el-table
        :data="tableData"
        stripe
        :max-height="tableHeight"
        ref="form"
        :size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'"
      >
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.userList.${column}`)"
          align="center"
          :min-width="column == 'operate' ? 280 : '140'"
        >
          <template slot-scope="{ row }">
            <template v-if="column == 'dealer'">
              <!-- <span v-if="row.dealer"> -->
              <span v-if="row.dealer.username">
                {{ row[column].username }}
              </span>
              <span v-else> / </span>
            </template>

            <template v-else-if="column == 'operate'">
              <el-button type="success" size="small" @click="openDetail(row)">{{
                $t("label.userList.edit")
              }}</el-button>
              <el-button type="danger" size="small" @click="openDelete(row)">{{
                $t("label.userList.delete")
              }}</el-button>
              <el-button
                type="success"
                size="small"
                style="background-color: #3e7791"
                @click="openResetPwd(row)"
                >{{ $t("label.userList.resetPwd") }}</el-button
              >
              <!-- <el-button type="danger" size="small" @click="openEquip(row)">{{
								$t("label.userList.equip")
							}}</el-button> -->
            </template>
            <template v-else>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="deviceInfo.type !== 'PC'">
      <van-list
        v-model="isLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="MBCard" v-for="item in MBData" :key="item.id">
          <!-- <el-checkbox @change="tbChange($event, item)"></el-checkbox> -->
          <div class="item">
            <span>名称：</span>
            <span>{{ item.name }}</span>
          </div>
          <div class="item">
            <span>联系电话：</span>
            <span>{{ item.contact }}</span>
          </div>
          <div class="item">
            <span>账号：</span>
            <span>{{ item.account }}</span>
          </div>
          <div class="item">
            <span>归属地：</span>
            <span>{{ item.belong }}</span>
          </div>
          <div class="item">
            <span>账户类型：</span>
            <span>{{ item.accountType }}</span>
          </div>
          <div class="item">
            <span>归属经销商：</span>
            <span>{{ item.dealer.username || "-" }}</span>
          </div>
          <div class="btn">
            <el-button type="success" size="small" @click="openDetail(item)">{{
              $t("label.userList.edit")
            }}</el-button>
            <el-button type="danger" size="small" @click="openDelete(item)">{{
              $t("label.userList.delete")
            }}</el-button>
            <el-button
              type="success"
              size="small"
              style="background-color: #3e7791"
              @click="openResetPwd(item)"
              >{{ $t("label.userList.resetPwd") }}</el-button
            >
          </div>
        </div>
      </van-list>
    </div>
    <div class="pagination" v-if="deviceInfo.type == 'PC'">
      <el-pagination
        background
        :layout="
          $store.getters.deviceInfo.type == 'PC'
            ? 'total, prev, pager, next, jumper'
            : 'prev, pager, next'
        "
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        :total="total"
        popper-class="mySelectType"
        :small="$store.getters.deviceInfo.type != 'PC'"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <edit-user
      :show="showDetail"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
      @refresh="refreshList"
    ></edit-user>
    <equip-list
      :show="showEquips"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
    ></equip-list>
    <reset-pwd
      :userInfo="userInfo"
      :show="showResetPwd"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
    >
    </reset-pwd>
    <ConfirmDialog
      :title="message.title"
      :message="message.content"
      :show="showDelete"
      @toggleShow="closeDia"
      @submit="closeDia"
      @delete="toDelete"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { List } from "vant";
import { userList, delUser } from "@/api/index";
import editUser from "./component/editUser.vue";
import EquipList from "./component/equipList.vue";
import ResetPwd from "./component/resetPwd.vue";
import ConfirmDialog from "../../components/public/confirmDialog";
export default {
  components: { editUser, EquipList, ResetPwd, ConfirmDialog, VanList: List },
  data() {
    return {
      queryData: {
        type: "名称",
        value: "name",
        keyWords: "",
      },
      columns: [
        "name",
        "contact",
        "account",
        "belong",
        "accountType",
        "dealer",
        "operate",
      ],
      tableData: [],
      showDetail: false,
      showEquips: false,
      showResetPwd: false,
      showDelete: false,
      rowInfo: null,
      total: 10,
      page: 1,
      pageSize: 10,
      username: "",
      account: "",
      searchType: "name", // 搜索类型
      message: {
        title: "",
        content: "",
      },
      userInfo: {},
      lineData: {},
      tableHeight: 500,
      timer: null,
      isLoading: true,
      finished: false,
      MBData: [],
    };
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  activated() {
    // this.getUserList()
    this.getUserList();

  },
  deactivated() {
    this.page = 1
    this.MBData = []
    this.finished = false
    this.isLoading = true
    this.setTimer();

  },
  mounted() {
    this.$nextTick(() => {
      // 计算table高度
      let height = window.innerHeight - this.$refs.form?.$el.offsetHeight - 135;
      this.tableHeight = height;
    });
  },
  methods: {
    tbChange(e, info) {
      if (e) {
        this.selectValue.push(info.sn);
      } else {
        this.selectValue = this.selectValue.filter((item) => item !== info.sn);
      }
      console.log(this.selectValue, "seleeeee");
    },
    onLoad() {
      this.page++
      this.isLoading = true
      this.getUserList()
    },
    // 获取用户列表
    getUserList() {
      let param = {
        page_num: this.page,
        page_size: this.pageSize,
        account: this.account,
        username: this.username,
      };
      userList(param).then((res) => {
        // console.log(res, "ressssssss");
        if (res.code == 200) {
          this.total = res.data.total;
          this.tableData = res.data.rows.map((item) => {
            return {
              id: item.id,
              name: item.username,
              contact: item.phone,
              account: item.account,
              belong: item.province + item.city + item.district,
              accountType: item.type == 1 ? "普通用户" : "经销商",
              dealer: item.agent_info,
              province: item.province, // 省
              city: item.city, // 市
              district: item.district, // 区
              pid: item.agent_info.id,
              class: item.class,
            };
          });
          this.finished = res.data.rows.length ? false : true;
          this.MBData = this.MBData.concat(this.tableData);
          this.isLoading = false
        }
      });
    },
    // 打开弹窗
    openDetail(val) {
      this.showDetail = true;
      this.rowInfo = val;
    },
    openEquip(val) {
      this.showEquips = true;
      this.rowInfo = val;
    },
    // 重置密码
    openResetPwd(val) {
      this.userInfo = val;
      this.showResetPwd = true;
      this.rowInfo = val;
    },
    // 删除
    openDelete(val) {
      this.message.title = this.$t("public.warning");
      this.message.content = this.$t("message.userList.delete");
      this.showDelete = true;
      this.lineData = val;
    },
    closeDia(val) {
      // console.log(val)
      if (val == false) val = null; // 删除弹框警告特殊处理
      this.showDetail = false;
      this.showEquips = false;
      this.showResetPwd = false;
      this.showDelete = false;
      this.timer = setTimeout(() => {
        this.rowInfo = val;
      }, 250);
    },
    // 清除定时器
    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      } else {
        return;
      }
    },
    selectChange(e) {
      this.searchType = e;
    },
    // 搜索
    toSearch() {
      this.MBData = []
      this.page = 1;
      // this.pageSize = 10
      if (this.searchType === "name") {
        this.username = this.queryData.keyWords;
        this.account = "";
      } else {
        this.username = "";
        this.account = this.queryData.keyWords;
      }
      this.getUserList();
    },
    // 分页
    sizeChange(e) {
      this.pageSize = e;
      // this.getUserList();
    },
    // 页数
    currentChange(e) {
      this.page = e;
      // console.log("页数", e);
      this.getUserList();
    },
    toDelete() {
      // console.log(this.lineData)
      let param = {
        id: this.lineData.id,
      };
      // console.log(param);
      // return
      delUser(param).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            // message: "已成功删除此用户！",
            message: this.$t("message.userList.delete_success_info"),
            duration: 2000,
          });
          this.getUserList();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 2000,
          });
        }
      });
    },
    refreshList() {
      this.getUserList();
    },
  },
};
</script>

<style lang="scss" scoped>
.userList {
  .MBCard {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #979797;
    border-radius: 8px;
    margin: 10px 0;
    .item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 32px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
    }
  }
  // height: calc(100vh);
  padding: 0 20px 20px;
  .fixed {
    background-color: #fff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 9;
    // top: 20px;
    // padding: 20px 0;
  }
  .query {
    // max-width: 400px;
    justify-content: space-between;
  }

  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    // height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    // .el-select .el-input__inner:focus {
    //   border-color: #3b9167;
    // }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }

  .el-button {
    border: 1px solid rgba(255, 255, 255, 0);
  }

  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: -6px;
    right: 20px;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding-bottom: 15px;
  }
}
</style>
<style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>

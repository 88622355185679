<template>
  <!-- 卫星信息 -->
  <div class="satellite">
    <el-button
      type="success"
      :class="{ normal: item.type != currentModel }"
      v-for="item in model"
      class="btn"
      :key="item.type"
      size="small"
      @click="handleModel(item.type)"
      >{{ item.label }}</el-button
    >

    <div class="satellite-table" v-show="currentModel == 'list'">
      <list
        :tableData="tableData"
        :active="currentModel"
        :satellites="satellites"
      ></list>
    </div>
    <div class="flex satellite-position" v-show="currentModel == 'position'">
      <position
        :tableData="tableData"
        :active="currentModel"
        :satellites="satellites"
      ></position>
    </div>
    <div class="satellite-chart" v-show="currentModel == 'chart'">
      <Chart :tableData="tableData" :active="currentModel"></Chart>
    </div>
  </div>
</template>

<script>
import { starsInfo } from "@/api/index"
import list from "./components/list.vue";
import Position from "./components/position.vue";
import Chart from "./components/chart.vue";
export default {
  components: {
    list,
    Position,
    Chart,
  },
  data() {
    return {
      tableData: [],
      satellites: [
        {
          label: "label.satellite.BDS",
          value: "BEIDOU",
        },
        {
          label: "label.satellite.GPS",
          value: "GPS",
        },
        {
          label: "label.satellite.GLONASS",
          value: "GLN",
        },
        {
          label: "label.satellite.GALILEO",
          value: "GAL",
        },
        {
          label: "label.satellite.QZSS",
          value: "QZSS",
        },
        {
          label: "label.satellite.SBAS",
          value: "SBAS",
        },
      ],
      model: [
        {
          type: "list",
          label: this.$t("label.satellite.page_title"),
        },
        {
          type: "position",
          label: this.$t("label.satellite.stellar_map"),
        },
        {
          type: "chart",
          label: this.$t("label.satellite.SNR"),
        },
      ],
      currentModel: "list",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
		let param = {
			sn: sessionStorage.getItem("currentSn"),
		}
		starsInfo(param).then((res) => {
			console.log(res, "ressss")
        	this.tableData = res.data.satellite_info;
      });
    },
    handleModel(val) {
      if (this.currentModel != val) {
        this.currentModel = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.satellite {
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .btn {
    width: 70px;
    height: 30px;
    line-height: 30px;
    background-color: $main_color;
    border-color: $main_color;
    padding: 0;
    margin-top: 30px;
  }

  .normal {
    background-color: transparent;
    border-color: transparent;
    color: #333333;
  }

  &-radio {
    margin-top: 30px;
  }

  &-table {
    margin-top: 10px;
    // width: 1000px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  &-position {
    justify-content: center;
  }
}
</style>
<template>
	<div class="FlowBallR12PositionMap">
		<main-map :modules="['ball', 'track']" @inited="initMap"></main-map>
		<history-track
			:info.sync="rowInfo"
			:show.sync="isShowHistoryTrack"
		></history-track>
	</div>
</template>

<script>
	import MainMap from "../../components/public/mainMap";
	import HistoryTrack from "@/components/public/historyTrack";
	import { point, lineString } from "@turf/turf";
	export default {
		name: "FlowBallR12PositionMap",
		components: {
			MainMap,
			HistoryTrack,
		},
		data() {
			return {
				isShowHistoryTrack: false,
				rowInfo: {},
				isRecord: false,
			};
		},
		provide() {
			return {
				_handler_date_class: this.handlerDateClass,
				_sn_select: this.getDateMark,
				_customDetail: this.getCustomDetail,
			};
		},
		watch: {
			isRecord(val) {
				if (!val) {
					this.clearRealTrack();
				}
			},
		},
		mounted() {
			this.$emitter.on("openTrack", this.handlerTrackPopup);
			this.$emitter.on("openConfig", this.handlerConfigPopup);
		},
		methods: {
			handlerDateClass(date) {
				let { yy, mm, dd } = this.$tool.formartDate(date);
				return this._currentDates && this._currentDates[`${yy}-${mm}-${dd}`]
					? "mark"
					: "";
			},
			initMap(map) {
				if (!this._map) {
					this._map = map;
					this.$emitter.on("ballUpdate", this.handlerBallRealTrack);
					this.$emitter.on("search", this.clearRealTrack);
				}
			},
			clearRealTrack() {
				this.isRecord = false;
				this._map.getSource("realTrack").setData({
					type: "FeatureCollection",
					features: [],
				});
			},
			handlerBallRealTrack(val) {
				if (
					!val ||
					!val.data ||
					!val.data.device_info ||
					val.data.device_info.length !== 1
				) {
					return;
				}
				let item = val.data.device_info[0];
				if (this.isRecord && item.online_status == 1) {
					const features = this._map.getSource("realTrack")._data.features;
					if (!features.length) {
						let line = lineString(
							[
								[item.lon, item.lat],
								[item.lon, item.lat],
							],
							{ sn: item.sn }
						);
						line.id = item.sn;
						features.push(line);
					} else {
						if (features[0].geometry.coordinates.length > 8640) {
							features[0].geometry.coordinates.shift();
						}
						features[0].geometry.coordinates.push([item.lon, item.lat]);
					}
					this._map.getSource("realTrack").setData({
						type: "FeatureCollection",
						features: features,
					});
				}
			},
			getDateMark(arr) {
				this.isRecord = arr.length === 1;
				if (!arr.length) {
					this._currentDates = null;
					let tds = document.querySelectorAll("td.mark");
					for (let index = 0; index < tds.length; index++) {
						const element = tds[index];
						element.className = element.className.replace("mark", "");
					}
					return;
				}
				this.$api.trackMark(arr[0]).then((res) => {
					this._currentDates = res.data.reduce((t, e) => {
						t[e] = e;
						return t;
					}, {});
				});
			},
			handlerTrackPopup(val) {
				// console.log(val);
				this.rowInfo = this.$tool.clone(val);
				this.isShowHistoryTrack = true;
			},
			handlerConfigPopup(val) {
				sessionStorage.setItem("currentSn", val.sn);
				sessionStorage.setItem("currentModel", "1");
				let page = this.$router.resolve({
					name: "receiverInfo",
				});
				window.open(page.href + "?type=config", "_blank");
			},
			getCustomDetail() {
				return new Promise((resolve, reject) => {
					resolve({
						line_info: [],
						sn: [this.rowInfo.sn],
					});
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.FlowBallR12PositionMap {
		width: 100%;
		height: 100%;
	}
</style>
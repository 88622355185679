<template>
	<div class="flex-dc receiverList" :class="$store.getters.deviceInfo.type">
		<div class="query">
			<div class="input-width">
				<div class="search_group">
					<div class="flex">
						<div class="item">
							<span>{{ $t("label.taskManage.taskName") }}：</span>
							<el-input
								style="width: 168px; margin-right: 20px"
								size="small"
								v-model="taskN"
								:placeholder="$t('label.taskManage.taskName')"
							></el-input>
						</div>
						<div class="item">
							<span>{{ $t("label.taskManage.connect") }}：</span>
							<el-select
								v-model="snValue"
								:multiple-limit="3"
								multiple
								collapse-tags
								style="width: 180px"
								size="small"
								filterable
								:placeholder="`${$t('label.taskManage.connect')}`"
								@change="handlerSnFilter"
								v-loadmore="loadMore"
							>
								<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value"
									:disabled="false"
								>
									<!-- <div class="cell">
											<el-checkbox
												:label="item.value"
												style="pointer-events: none"
											/>
											<span class="selectsLabel">{{ item.label }}</span>
										</div> -->
								</el-option>
								<!-- </el-checkbox-group> -->
							</el-select>
						</div>
					</div>
					<div
						class="btn_group"
						:style="{
							marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
							marginLeft: '10px',
						}"
					>
						<el-button
							size="small"
							style="background-color: #539d56"
							@click="searchList"
							>{{ $t("button.search") }}</el-button
						>
						<el-button
							size="small"
							style="background-color: #ef9a41"
							@click="reset"
							>{{ $t("button.reset") }}</el-button
						>
					</div>
				</div>
			</div>
		</div>
		<div class="pageTitle opt">
			<span style="font-weight: 600; color: #333333"
				>{{ $t("label.taskManage.taskL") }}：</span
			>
			<div class="btn_group">
				<el-button @click="addTask" size="small" type="primary">{{
					$t("button.create")
				}}</el-button>
				<el-button @click="delTask" size="small" type="danger">{{
					$t("button.delete")
				}}</el-button>
			</div>
		</div>
		<template v-if="$store.getters.deviceInfo.type === 'PC'">
			<div class="table">
				<el-table
					:data="tableData"
					ref="tableData"
					stripe
					:max-height="tableHeight"
					:size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'"
					@selection-change="selectionChange"
				>
					<el-table-column type="selection" width="55"> </el-table-column>
					<el-table-column
						v-for="column in columns"
						:key="column"
						:prop="column"
						:label="$t(`label.taskManage.${column}`)"
						align="center"
						:min-width="
							column == 'operate'
								? '300px'
								: column == 'index'
								? '50px'
								: '100px'
						"
						:show-overflow-tooltip="true"
					>
						<template slot-scope="{ row }">
							<template v-if="column == 'operate'">
								<div class="btn_group">
									<el-button
										size="small"
										style="background-color: #539d56"
										@click="toReview(row)"
										>{{ $t("label.taskManage.review") }}</el-button
									>
									<el-button
										size="small"
										style="background-color: #539d56"
										@click="toMapView(row)"
										>{{ $t("label.taskManage.mapView") }}</el-button
									>
									<el-button
										size="small"
										style="background-color: #539d56"
										@click="toTaskInfo(row)"
										>{{ $t("label.taskManage.taskInfo") }}</el-button
									>
									<el-button
										size="small"
										style="background-color: #539d56"
										@click="toEdit(row)"
										>{{ $t("label.taskManage.edit") }}</el-button
									>
								</div>
							</template>
							<template v-else>
								{{ row[column] }}
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination">
				<el-pagination
					background
					:layout="
						$store.getters.deviceInfo.type == 'PC'
							? 'total, prev, pager, next, jumper'
							: 'prev, pager, next'
					"
					:total="total"
					popper-class="mySelectType"
					:current-page="page"
					@current-change="currentChange"
					:small="$store.getters.deviceInfo.type != 'PC'"
				>
				</el-pagination>
			</div>
		</template>
		<template v-else>
			<div class="query" style="padding-bottom: 10px">
				<el-checkbox
					:indeterminate="
						selectValue.length ? selectValue.length < total : false
					"
					v-model="checkAll"
					label="全选"
				></el-checkbox>
			</div>
			<List
				ref="mList"
				:all="checkAll"
				@selection-change="selectionChange"
			></List>
		</template>
		<TaskInfo :show.sync="infoShow" :rowInfo.sync="rowInfo"></TaskInfo>
		<CreateTask
			:show="showC"
			:options="options"
			:rowInfo.sync="rowInfo"
			@toggleShow="closeDia"
			@refresh="reset"
		></CreateTask>
		<ConfirmDialog
			:title="$t('label.taskManage.delTask')"
			:show="showD"
			:message="$t('label.taskManage.delete')"
			@toggleShow="closeDia"
			@submit="closeDia"
			@delete="toDelete"
		></ConfirmDialog>
		<history-track
			:info.sync="rowInfo"
			:show.sync="isShowHistoryTrack"
		></history-track>
		<map-view :info.sync="rowInfo" :show.sync="isShowMapView"></map-view>
	</div>
</template>
  
<script>
	import ConfirmDialog from "../../components/public/confirmDialog";
	import TaskInfo from "./components/taskInfo.vue";
	import HistoryTrack from "../../components/public/historyTrack";
	import MapView from "./components/mapView.vue";
	import { getSN, taskList, delTask } from "@/api/index";
	import List from "./components/mList.vue";
	import CreateTask from "./components/createTask.vue";
	export default {
		data() {
			return {
				dateValue: [],
				taskN: "",
				infoId: "",
				columns: [
					"index",
					"taskName",
					"cTime",
					"sTime",
					"eTime",
					"connect",
					"system",
					"operate",
				],
				options: [
					// {
					//   value: "选项1",
					//   label: "111256478966",
					// }
				],
				snValue: [], // 搜索SN
				tableData: [
					// {
					// index: 1,
					// taskName: 1,
					// cTime: "2023-04-27 11:00:01",
					// sTime: "2023-04-27 11:00:01",
					// eTime: "2023-04-27 11:00:01",
					// connect: 111,
					// system: 111,
					// },
				],
				total: 20,
				page: 1,
				pageSize: 10,
				selectValue: [], // 选择的设备
				tableHeight: 450,
				infoShow: false,
				showC: false,
				isShowHistoryTrack: false,
				isShowMapView: false,
				showD: false,
				rowInfo: {},
				checkAll: false,
			};
		},
		provide() {
			return {
				_getTableData: this.getTableData,
				_toReview: this.toReview,
				_toMapView: this.toMapView,
				_toTaskInfo: this.toTaskInfo,
				_toEdit: this.toEdit,
			};
		},
		components: {
			TaskInfo,
			ConfirmDialog,
			CreateTask,
			HistoryTrack,
			MapView,
			List,
		},
		watch: {
			"$store.getters.deviceInfo": {
				handler(val) {
					if (val.type === "PC") {
						this.$nextTick(() => {
							this.$refs.tableData.doLayout();
						});
					}
				},
				deep: true,
				immediate: true,
			},
		},
		activated() {
			this.getSNList();
			if (this.$store.getters.deviceInfo.type === "PC") {
				this.getTaskList();
			}
		},
		mounted() {
			if (this.$store.getters.deviceInfo.type === "PC") {
				this.$nextTick(() => {
					// window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
					this.tableHeight =
						window.innerHeight - this.$refs.tableData.$el.offsetHeight - 155;
				});
			}
		},
		methods: {
			loadMore() {
				// console.log("到底了！");
			},
			getData(){
				if (this.$store.getters.deviceInfo.type === "PC") {
					this.getTaskList();
				} else {
					this.$refs.mList.reSet();
				}
			},
			closeDia(e, m) {
				this.showC = false;
				this.showD = false;
				this.rowInfo = m;
			},
			toDelete() {
				let param = {
					task_id: this.selectValue,
				};
				delTask(param).then((res) => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "删除成功",
						});
						this.getData();
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			handleCheckAllChange(e) {
				if (e) {
					this.snValue = this.options.map((f) => f.value);
				} else {
					this.snValue = [];
				}
				this.handlerSnFilter(this.snValue);
			},
			handlerSnFilter(e) {
				this.snValue = e;
				// console.log(this.snValue, "snnnn");
			},
			// 删除
			delTask() {
				if (!this.selectValue.length) {
					this.$message({
						type: "warning",
						message: "请选择任务进行删除",
					});
					return;
				}
				this.showD = true;
			},
			// 获取设备下拉列表
			getSNList() {
				let param = {
					// sn: "",
					model: 1,
				};
				getSN(param).then((res) => {
					if (res.code == 200) {
						this.options = res.data.sn.map((item) => {
							return {
								label: item,
								value: item,
							};
						});
					}
				});
			},
			// 轨迹回放
			toReview(e) {
				this.rowInfo = e;
				this.isShowHistoryTrack = true;
			},
			// 地图视图
			toMapView(e) {
				this.rowInfo = e;
				this.isShowMapView = true;
			},
			// 任务详情
			toTaskInfo(e) {
				this.infoShow = true;
				this.rowInfo = e;
				// this.infoId = e.id
			},
			// 编辑
			toEdit(e) {
				this.showC = true;
				this.rowInfo = e;
				// console.log(e,"编辑");
			},
			// 搜索
			searchList() {
				this.page = 1;
				this.checkAll = false;
				this.getData()
			},
			reset() {
				this.checkAll = false;
				this.snValue = [];
				this.taskN = "";
				this.getData()
			},
			selectionChange(e) {
				this.selectValue = e.map((item) => {
					return item.id;
				});
				if(this.$store.getters.deviceInfo.type !== "PC"){
					let ref = this.$refs.mList;
					this.checkAll = ref.list.length === e.length;
				}
			},
			getTaskList(e = null) {
				let param = {
					task_name: this.taskN ? this.taskN : "",
					page_num: e?e.page_num:this.page,
					page_size: this.pageSize,
					// sn: JSON.stringify(this.snValue),
					sn: this.snValue,
				};
				return taskList(param).then((res) => {
					this.total = res.data.total;
					this.tableData = res.data.rows.map((item, index) => {
						return {
							id: item.id,
							index: index + 1,
							taskName: item.name,
							cTime: item.created_at,
							sTime: item.begin_at,
							eTime: item.end_at,
							connect: item.sn.join(","),
							system: item.coordinate_info.coordinate_name
								? item.coordinate_info.coordinate_name
								: "-",
							system_id: item.coordinate_info.coordinate_id
								? item.coordinate_info.coordinate_id
								: "",
						};
					});
					return Promise.resolve({
						data: this.tableData,
						params: {
							...param,
							total: res.data.total,
						},
					});
				});
			},
			getTableData(e) {
				return this.getTaskList(e);
			},
			// 导出
			addTask() {
				this.showC = true;
			},
			toggleShowAddSn() {},
			// 页数
			currentChange(e) {
				this.page = e;
				this.getTaskList();
			},
			// 提交清除数据的选中状态
			searchTableData() {
				this.$refs.tableData.clearSelection(); //清除选中的数据
			},
		},
	};
</script>
  
  <style lang="scss" scoped>
	.receiverList {
		padding: 20px;

		.opt {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.query {
			display: flex;
			justify-content: space-between;
		}

		.input-width {
			max-width: 1000px;
			.search_group {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				font-size: 14px;
			}
		}
		.operate-btn {
			display: flex;
		}
		.btn_group {
			margin-left: 20px;
			display: flex;

			& > .el-button {
				min-width: 50px;
				text-align: center;
				color: #fff;
				border: none;
			}
		}
		.btn {
			min-width: 80px;
			height: 32px;
		}
		.table {
			margin-top: 10px;
			border-radius: 2px 2px 0px 0px;
			overflow: hidden;

			&::v-deep th.el-table__cell {
				background-color: $table_header;
				color: #fff;
			}

			&::v-deep td.el-table__cell {
				background: transparent !important;
			}

			&::v-deep .el-table__row--striped {
				background-color: #d2e9ce;
			}
		}
		.pagination {
			// display: flex;
			// align-items: center;
			// justify-content: flex-end;
			// margin-top: 12px;
			position: fixed;
			bottom: 0px;
			right: 20px;
			// width: 100%;
			display: flex;
			justify-content: flex-end;
			background-color: #ffffff;
			z-index: 999;
			padding-bottom: 10px;
			width: calc(100% - 230px);
		}

		::v-deep {
			.el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #3b9167;
				color: #fff;
			}

			.el-pagination.is-background .btn-next,
			.el-pagination.is-background .btn-prev,
			.el-pagination.is-background .el-pager li {
				background-color: #ffffff;
				border: 1px solid #e8e8e8;
			}

			.el-pagination.is-background .el-pager li:hover {
				color: #3b9167;
			}

			.el-pagination__sizes .el-input .el-input__inner:hover {
				border-color: #3b9167;
			}

			.el-select .el-input__inner:focus {
				border-color: #3b9167;
			}

			.el-select-dropdown__item.selected {
				color: #3b9167 !important;
			}

			.el-button--success.is-disabled {
				background-color: #c0c4cc;
				border-color: #c0c4cc;
			}

			// .el-pagination.is-background .el-pager li:not(.disabled):hover {
			// 	color: #3b9167;
			// }
		}
	}

	.MOBIE {
		height: 100%;
		padding: 0;
		::v-deep {
			.el-checkbox__inner {
				width: 16px;
				height: 16px;
			}
			.el-checkbox .el-checkbox__label {
				display: inline-block !important;
			}
		}
		.input-width{
			width: 100%;
		}
		.search_group{
			width: 100%;
			flex-direction: column;
			align-items: flex-start !important;
			&>div:first-child{
				flex-direction: column;
				align-items: flex-start !important;
			}
			.flex{
				width: 100%;
			}
			.btn_group{
				width: 100%;
				margin-left: 0 !important;
				.el-button{
					flex: 1;
				}
			}
			.item{
				display: flex;
				width: 100%;
				align-items: center;
				&>span{
					width: 100px;
				}
			}
			.el-input,.el-select{
				flex: 1;
				width: auto !important;
				margin-right: 0 !important;
			}
		}
		.item{
			margin-top:8px;
		}
		.pagination {
			position: unset;
			width: 100%;
			padding: 0;
			margin-top: 16px;
			// margin-bottom: 16px;
		}
		.query {
			padding: 0 20px;
		}
		.pageTitle {
			padding: 0 20px;
		}
	}
	.selectsLabel {
		margin-left: 10px;
	}
</style>
  <style lang="scss">
	.mySelectType {
		.el-select-dropdown__item.selected {
			color: #3b9167 !important;
		}
	}
</style>
  <style lang="scss">
	.el-checkbox .el-checkbox__label {
		display: none;
	}

	.selectsLabel {
		margin-left: 10px;
	}
</style>
  
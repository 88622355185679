<template>
	<el-drawer
		:title="$t('dialog.mapView.title')"
		custom-class="addSectionPoint"
		:visible="show"
		direction="rtl"
		:size="`calc(100% - ${
			$store.getters.deviceInfo.type == 'PC'
				? $store.getters.sideNavOpen
					? '210px'
					: '64px'
				: '0px'
		})`"
		@close="close"
	>
		<div
			class="mapView"
			:class="$store.getters.deviceInfo.type"
			v-loading="!init || loading"
		>
			<main-map
				v-if="init"
				ref="map"
				domId="mapView"
				:modules="['section']"
				@inited="loaded"
			></main-map>
			<div class="sns" v-if="detail.sn && detail.sn.length">
				<el-radio-group v-model="currentSn" @change="getTrackData">
					<el-radio v-for="item in detail.sn" :key="item" :label="item">{{
						item
					}}</el-radio>
				</el-radio-group>
			</div>
		</div>
	</el-drawer>
</template>
    
<script>
	import MainMap from "../../../components/public/mainMap";
	import ComputIntersectPoint from "../../../components/public/mainMap/components/computIntersectPoint.vue";
	export default {
		name: "mapView",
		components: {
			MainMap,
		},
		data() {
			return {
				init: false,
				loading: false,
				detail: {},
				currentSn: "",
			};
		},

		props: {
			show: {
				type: Boolean,
				default: false,
			},
			info: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		provide() {
			return {
				_custom_section_data: this.getSectionData,
				_custom_create_popup: this.handlerPop,
			};
		},
		watch: {
			show(val) {
				if (val) {
					this.getDetail();
				}
			},
		},
		mounted() {},
		methods: {
			close() {
				this.init = false;
				this.$emit("update:show", false);
			},
			getDetail() {
				this.$api
					.getTaskInfo({
						task_id: this.info.id,
					})
					.then((res) => {
						this.currentSn = res.data.sn[0];
						this.detail = res.data;
						this.init = true;
					});
			},
			async getSectionData() {
				return this.detail.line_info;
			},
			handlerPop(option) {
				option.data.detail.angle =
					this.$tool.toFixedFn(Number(option.data.detail.angel), 1) + "";
				if (
					option.data.type === "trackPoints" ||
					option.data.type === "intersectPoints"
				) {
					option.project = ComputIntersectPoint;
					option.data.lang = this.$t("popup.computIntersectPoint");
					option.data.type = 'computIntersectPoint';
				}
				return option;
			},
			loaded(map) {
				this.getTrackData();
			},
			async getTrackData(e) {
				if (e) {
					this.loading = true;
				}
				const {
					data: [res],
				} = await this.$api.chartsList({
					related_sns: [this.currentSn],
					begin_at: new Date(this.detail.begin_at).getTime() / 1000,
					end_at: new Date(this.detail.end_at).getTime() / 1000,
				});
				const _map = await this.$refs.map.getVm();
				await _map.setDefaultLayer();
				if (res.rows.length) {
					_map.map.setCenter([res.rows[0].lng, res.rows[0].lat]);
					_map.map.setZoom(20);
					_map.originTrackData = _map.handlerTrackData(
						this.$tool.clone(res.rows)
					);
					_map.handlerTrackLine();
				} else {
					this.$message.warning("暂无轨迹信息！");
					_map.map.getSource("track").setData({
						type: "FeatureCollection",
						features: [],
					});
					_map.map.getSource("trackPoint").setData({
						type: "FeatureCollection",
						features: [],
					});
					_map.computedIntersect();
				}
				let data = _map.map.getSource("intersect")._data.features;
				if (data.length) {
					let _res = {};
					data.forEach((e) => {
						if (!_res[e.properties.id]) {
							_res[e.properties.id] = {
								time: new Date(e.properties.nearPoint.time).getTime(),
								speed: e.properties.nearPoint.speed,
							};
						} else {
							if (
								new Date(_res[e.properties.id]).time <
								new Date(e.properties.nearPoint.time).getTime()
							) {
								_res[e.properties.id] = {
									time: new Date(e.properties.nearPoint.time).getTime(),
									speed: e.properties.nearPoint.speed,
								};
							}
						}
					});
					this.detail.line_info = this.detail.line_info.map((f) => {
						return {
							...f,
							showSpeed: true,
							speed: _res[f.line_id].speed,
						};
					});
					_map._section_init();
				}
				setTimeout(() => {
					this.loading = false;
				}, 1000);
				return [];
			},
		},
	};
</script>
    
<style lang="scss" scoped>
	.mapView {
		height: 100%;
		width: 100%;
		padding: 20px;
		overflow: hidden;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: calc(100% - 20px - 20px);
			height: calc(100% - 20px - 20px);
			border: 1px solid #999;
			transform: translate(-50%, -50%);
			pointer-events: none;
		}

		.sns {
			position: absolute;
			top: 34px;
			left: 34px;
			padding: 12px 16px;
			background-color: #fff;
			border-radius: 8px;

			&::v-deep .el-radio-group {
				display: flex;
				flex-direction: column;

				.el-radio {
					margin-right: 0;

					& + .el-radio {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.MOBIE {
		padding: 0;

		&::before {
			display: none;
		}

		.sns {
			top: 8px;
			left: 8px;
		}
	}
</style>
<style lang="scss">
	.addSectionPoint {
		.el-drawer__header {
			margin-bottom: 0px;
			padding: 10px 20px 0 10px;
		}
	}
</style>
    
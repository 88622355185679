<template>
  <div class="charts" v-loading="done">
    <div class="detail">
      <div class="item" v-for="item in column" :key="item">
        <div>{{ $t(`label.taskManage.${item}`) }}{{deviceInfo.type === 'PC'?'：':''}}</div>
        <div>{{ detail[item] + "m/s" }}</div>
      </div>
    </div>
    <div id="myCharts" ref="myCharts" class="myCharts"></div>
  </div>
</template>

<script>
export default {
  name: "FlowBallR12Charts",
  props: {
    current: {
      type: String,
      default: "1",
    },
    show: {
      type: Boolean,
      default: null,
    },
    chartData: {
      type: Array,
      default: () => {
        return null;
      },
    },
    sn: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      done: true,
      column: ["maxSpeed", "minSpeed", "averageSpeed"],
      detail: {
        maxSpeed: 0,
        minSpeed: 0,
        averageSpeed: 0,
      },
      charts: null,
      option: {
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) => value + "m/s",
        },
        legend: {
          data: [
            // "1222299999",
            // "Union Ads",
            // "Video Ads",
            // "Direct",
            // "Search Engine",
          ],
          left: 0,
          icon: "circle",
          selectedMode: "single",
        },
        // grid: {
        //   left: "5%",
        //   right: "5%",
        //   bottom: "15%",
        //   top: "20%"
        //   //   containLabel: true,
        // },
        dataZoom: [
          {
            type: "slider",
            backgroundColor: "#fff",
            borderColor: "rgba(0, 0, 0, 0.15)",
            moveHandleSize: 0,
            height: 20,
            bottom: "0%"
          },
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
          name: "m/s",
          nameTextStyle: {
            color: "#aaa",
            nameLocation: "start",
            // align: "left"
          },
        },
        series: [
          // {
          //   name: "1222299999",
          //   type: "line",
          //   stack: "Total",
          //   data: [],
          //   data: [120, 132, 101, 134, 90, 230, 210],
          // },
        ],
      },
    };
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      console.log(this.sn, "snnnn")
      //   setTimeout(() => {
      //   }, 2000);
    });
  },
  watch: {
    option: {
      handler(newValue, oldValue) {},
    },
    chartData: {
      handler(val) {
        if (val) {
          console.log(val, "charts");
          this.initChart();
          this.detail.maxSpeed = val[0].maxSpeed;
          this.detail.minSpeed = val[0].minSpeed;
          this.detail.averageSpeed = val[0].averageSpeed;
        }
      },
      deep: true,
    },
    current: {
      handler(newValue, oldValue) {
        if (newValue == "2") {
          // console.log("是2")
          this.charts.resize();
        }
      },
    },
    show: {
      handler(newValue, oldValue) {
        // if (newValue) {
          if(!newValue){
            this.charts.dispose()
          } else {
            this.done = true
          }
        // console.log("showwwwwwwwwwwww");
        // console.log(this.current);
        //   setTimeout(()=>{
        // if(newValue){
        //   this.charts.resize();
        // }
        //   },1000)
        // }
      },
      deep: true,
    },
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  methods: {
    initChart() {
      this.charts = this.$echarts.init(this.$refs.myCharts);
      this.option.xAxis.data = [];
      this.option.legend.data = this.sn;
      if (this.charts && this.chartData) {
        this.done = true
        window.onresize = () => {
          this.charts.resize();
        };
        this.option.series = [];
        // if(!this.chartData.length){
        //   this.option.series = []
        //   this.charts.setOption(this.option);
        //   return
        // }
        // console.log(Date.now())
        this.chartData.map((item, index) => {
          // console.log(item, "tttt");
          if (!item.rows.length) {
            this.option.series[index] = {
              name: item.sn,
              type: "line",
              stack: "Total",
              data: [],
            };
          } else {
            this.option.xAxis.data = [];
              this.option.series[index] = {
                name: item.sn,
                type: "line",
                stack: "Total",
                data: this.chartData[index].rows.map((it, index) => {
                  this.option.xAxis.data.push(it.time);
                  return it.speed;
                }),
              };
          }
        });
        // console.log(Date.now(), "eeee")
        // this.option.series = this.sn.map((item) => {
        //   this.option.xAxis.data = [];
        //   return {
        //     name: item,
        //     type: "line",
        //     stack: "Total",
        //     data: this.chartData.rows.map((it,index) => {
        //       this.option.xAxis.data.push(it.time);
        //       return it.speed;
        //     }),
        //   };
        // });
        // this.chartData.rows.map(item=>{
        //   this.option.xAxis.data.push(item.time)
        // })
        // console.log(this.option, "222");
        this.charts.on("legendselectchanged", (e) => {
          console.log(e, "点击了"); // 如果不加off事件，就会叠加触发
          this.chartData.map((item) => {
            if (item.sn == e.name) {
              this.detail.maxSpeed = item.maxSpeed;
              this.detail.minSpeed = item.minSpeed;
              this.detail.averageSpeed = item.averageSpeed;
            }
          });
        });
        this.charts.setOption(this.option);
        this.done = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charts {
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 100%;
  flex: 1;
  .detail {
    width: 35%;
    height: 35px;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      // justify-content: center;
      // align-items: center;
      line-height: 35px;
    }
  }
  // flex: 1;
}
.myCharts {
  width: 100%;
  //  height: calc(100% - 220px - 40px);
  flex: 1;
}
</style>
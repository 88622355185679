<template>
  <div>
    <div class="satellite-radio">
      <el-checkbox-group v-model="checkList" text-color="#333333">
        <el-checkbox v-for="item in satellites" :key="item.value" :label="item.value"></el-checkbox>
      </el-checkbox-group>
    </div>
    <el-table :data="data" ref="tableData" stripe height="400px">
      <el-table-column v-for="column in columns" :key="column" :prop="column"
        :label="$t(`label.satellite.${dict[column]}`)" align="center" min-width="100px">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    satellites: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {

      columns: [
        "type",
        "id",
        "hight_angle",
        "position_angle",
        "b1L1",
        "b2L2",
        "b3L3",
      ],
      dict: {
        "type": "satellite_type",
        "id": "satellite_code",
        "hight_angle": "elevating_angle",
        "position_angle": "azimuth",
        "b1L1": "B1_L1",
        "b2L2": "B2_L2",
        "b3L3": "B3_L3",
      },
      checkList: this.satellites.map(f => f.value),
    }
  },
  watch: {
    '$store.getters.deviceInfo': {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    data() {
      return this.tableData.filter(f => {
        return this.checkList.includes(f.type);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.satellite-radio {
  margin: 15px 0;
}
</style>
<template>
	<div
		class="flex-dc addPoints"
		:class="[deviceInfo.type, showAdd ? 'open' : '']"
	>
		<!-- <i :class="showAdd ? 'el-icon-circle-close' : 'el-icon-circle-plus-outline'" :style="{
      '--color': !showAdd ? 'rgba(83, 157, 86, 1)' : 'rgba(255, 0, 0, 1)'
    }" @click="toggleShowAdd"></i> -->
		<i
			class="add"
			v-if="!showAdd && deviceInfo.type == 'PC'"
			@click="toggleShowAdd"
		>
			<i class="el-icon-plus" style="margin-right: 6px"></i>添加
		</i>
		<div class="flex addPoints-content">
			<div class="label" @click="toggleShowAdd">
				{{ $t("label.positionMap.addPoint") }}
			</div>
			<div class="form">
				<i
					class="el-icon-circle-close"
					v-if="showAdd && deviceInfo.type == 'PC'"
					style="
						font-size: 24px;
						z-index: 10;
						font-weight: bold;
						cursor: pointer;
						color: rgba(255, 0, 0, 1);
						position: absolute;
						right: 8px;
						top: 8px;
					"
					@click="toggleShowAdd"
				></i>
				<el-form label-width="auto" :model="addForm" ref="form" size="small">
					<div class="flex form-title">
						<el-form-item
							prop="title"
							:label="`${$t('label.positionMap.section')}：`"
							:rules="{
								validator: this.handlerTitle,
								trigger: 'blur',
							}"
						>
							<el-input v-model="addForm.title"></el-input>
						</el-form-item>

						<template v-if="isSub">
							<el-button size="small" @click="savePoints" class="btn">{{
								$t("public.save")
							}}</el-button>
							<el-button
								size="small"
								:style="{
									'--baseColor': '#ff0000',
								}"
								@click="clearPoint"
								class="btn"
								>{{ $t("label.positionMap.cancel") }}</el-button
							>
						</template>
						<template v-else>
							<el-button size="small" @click="checkData" class="btn">{{
								$t("label.positionMap.confirm")
							}}</el-button>
						</template>
					</div>
					<div class="flex form-points">
						<div
							class="flex point"
							v-for="(point, index) in addForm.points"
							:key="index"
						>
							<span>{{ $t("label.positionMap.point") }}{{ index + 1 }}</span>
							<div>
								<el-form-item
									prop="lon"
									:rules="{
										validator: (...arg) => {
											handlerLng(arg, index, true);
										},
										trigger: 'blur',
									}"
									:label="`${$t('label.positionMap.lon')}：`"
								>
									<el-input
										v-model="point.lon"
										@change="handlerInput"
									></el-input>
								</el-form-item>
								<el-form-item
									prop="lat"
									:rules="{
										validator: (...arg) => {
											handlerLat(arg, index, true);
										},
										trigger: 'blur',
									}"
									:label="`${$t('label.positionMap.lat')}：`"
								>
									<el-input
										v-model="point.lat"
										@change="handlerInput"
									></el-input>
								</el-form-item>
							</div>
							<el-button
								size="small"
								v-if="!isSub"
								class="btn"
								@click="selectPoint(index)"
							>
								{{ $t("label.positionMap.collect") }}
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { validLng, validLat, validateName } from "../../../../utils/regExp";
	const defaultForm = {
		title: "",
		points: [
			{
				lon: "",
				lat: "",
			},
			{
				lon: "",
				lat: "",
			},
		],
	};
	export default {
		data() {
			return {
				showAdd: false,
				addForm: this.$tool.clone(defaultForm),
				currentId: "",
				isSub: false,
			};
		},
		inject: {
			_getMap: {
				name: "_getMap",
				default: () => {
					return () => {};
				},
			},
			_setLayerData: {
				name: "_setLayerData",
				default: () => {
					return () => {};
				},
			},
			_clearFeatures: {
				name: "_clearFeatures",
				default: () => {
					return () => {};
				},
			},
			_savePoints: {
				name: "_savePoints",
				default: () => {
					return () => {};
				},
			},
		},
		// ["_getMap", "_setLayerData", "_clearFeatures", "_savePoints"]
		computed: {
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
		},
		methods: {
			handlerLng(params, index) {
				const [rule, value, callback] = params;
				let _value = this.addForm.points[index].lon;
				if (!_value || !_value.replaceAll(" ", "")) {
					if (!this.flag) {
						this.$message.warning("请输入经度");
					}

					callback(new Error(" "));
				} else if (!validLng(_value)) {
					if (!this.flag) {
						this.$message.warning("经度不合法");
					}
					callback(new Error(" "));
				} else {
					callback();
				}
				this.flag = false;
			},
			handlerLat(params, index) {
				const [rule, value, callback] = params;
				let _value = this.addForm.points[index].lat;
				if (!_value || !_value.replaceAll(" ", "")) {
					if (this.flag) {
						this.$message.warning("请输入纬度");
						this.flag = true;
					}
					callback(new Error(" "));
				} else if (!validLat(_value)) {
					if (this.flag) {
						this.$message.warning("纬度不合法");
						this.flag = true;
					}
					callback(new Error(" "));
				} else {
					callback();
				}
				this.flag = false;
			},
			handlerTitle(rule, value, callback) {
				if (!value || !value.replaceAll(" ", "")) {
					this.$message.warning("请输入截面名称");
					callback(new Error(" "));
				} else if (validateName(value)) {
					this.$message.warning("截面点对名称仅能输入文字、数字或字母");
					callback(new Error(" "));
				} else {
					callback();
				}
			},
			toggleShowAdd() {
				this.showAdd = !this.showAdd;
			},
			savePoints() {
				this._savePoints({
					...this.$tool.clone(this.addForm),
					uuid: this.currentId,
				});
			},
			reSet() {
				this.currentId = "";
				this.isSub = false;
				this.addForm = this.$tool.clone(defaultForm);
			},
			clearPoint() {
				this.$confirm(
					this.$t("message.positionMap.confirm_message"),
					this.$t("message.positionMap.please_confirm"),
					{
						distinguishCancelAndClose: true,
						confirmButtonText: this.$t("public.submit"),
						cancelButtonText: this.$t("public.cancel"),
					}
				)
					.then(() => {
						this._clearFeatures(this.currentId);
						this.toggleShowAdd();
						this.reSet();
					})
					.catch((action) => {});
			},
			selectPoint(index) {
				if (!this.isSub) {
					this._getMap((map) => {
						this.map = map;
						this.map.getCanvas().style.cursor = "crosshair";
						map._measureTools.point((coordinates) => {
							// console.log(e);
							// const { coordinates } = e.event.features[0].geometry;
							this.addForm.points[index].lon = coordinates[0];
							this.addForm.points[index].lat = coordinates[1];
						});
					});
				}
			},
			checkData() {
				this.flag = true;
				this.$refs.form.validate((valid) => {
					if (!valid) {
						return;
					} else {
						if (!this.currentId) {
							this.currentId = this.map.getUUID();
						}
						if (!this.isSub) {
							this.isSub = true;
						}
						this._setLayerData({
							...this.$tool.clone(this.addForm),
							id: this.currentId,
						});
					}
				});
				// if (!this.addForm.title) {
				//   this.$message.warning(this.$t("message.positionMap.title_message"))
				//   return;
				// }
				// if (!this.addForm.points.map(f => {
				//   return Boolean(f.lon) && Boolean(f.lat)
				// }).reduce((total, item) => {
				//   return total && item
				// }, true)) {
				//   this.$message.warning(this.$t("message.positionMap.section_message"))
				//   return;
				// }
			},
			handlerInput() {
				if (this.isSub) {
					this.checkData();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.btn {
		background-color: var(--baseColor);
		border-color: var(--baseColor);
		color: #fff;
	}

	.addPoints {
		position: absolute;
		left: 50%;
		bottom: 0;
		pointer-events: none;
		gap: 20px;
		transform: translateX(-50%) translateY(calc(100% - 50px));
		z-index: 2;
		align-items: center;
		padding-bottom: 20px;
		transition-duration: 0.3s;

		& > * {
			pointer-events: all;
		}

		& > i {
			font-size: 14px;
			// font-weight: bold;
			color: #fff;
			cursor: pointer;
			line-height: 30px;
			height: 30px;
			font-style: normal;
			width: 80px;
			text-align: center;
			border-radius: 4px;
			background-color: rgba(83, 157, 86, 1);
		}

		&-content {
			min-width: 850px;
			height: 130px;
			background: rgba($color: #ffffff, $alpha: 0.8);

			& > div {
				height: 100%;
			}

			.label {
				width: 40px;
				background: #d2e9ce;
				text-align: end;
				line-height: 40px;
				writing-mode: vertical-rl;
				letter-spacing: 12px;
				color: #333333;
			}

			.form {
				flex: 1;
				overflow: hidden;
				position: relative;
				.el-form {
					height: 100%;

					.el-form-item {
						margin-bottom: 0;

						+ .el-form-item {
							margin-top: 6px;
						}
					}
				}

				&-title {
					height: 44px;
					gap: 10px;
					justify-content: center;
					position: relative;

					&::before {
						content: "";
						position: absolute;
						bottom: 0%;
						transform: translate(0%, 50%);
						left: 0;
						width: 100%;
						height: 1px;
						background: rgba(0, 0, 0, 0.15);
					}
				}

				&-points {
					height: calc(100% - 44px);

					.point {
						flex: 1;
						overflow: hidden;
						height: 100%;
						justify-content: center;
						position: relative;
						gap: 10px;

						& > span {
							position: absolute;
							left: 0;
							top: 50%;
							transform: translate(20%, -50%);
							font-size: 14px;
							color: #333;
							padding: 0 12px;

							&::before {
								content: "";
								position: absolute;
								top: 50%;
								transform: translate(0%, -50%);
								left: 0;
								width: 8px;
								height: 8px;
								background: #357437;
								border-radius: 50%;
							}
						}

						.el-button--small {
							padding-left: 10px;
							padding-right: 10px;
						}

						// padding: 0 20px;
					}
				}
			}
		}
	}

	.addPoints.MOBIE {
		width: 100%;
		padding-bottom: 0;
		transform: translateX(-50%) translateY(100%);

		> i {
			display: none;
		}

		.el-form-item {
			width: 220px;
		}

		.label {
			position: absolute;
			top: 0;
			writing-mode: lr;
			width: auto;
			letter-spacing: 0;
			height: auto;
			left: 50%;
			transform: translate(-50%, -100%);
			width: 120px;
			height: 32px;
			background: #539d56;
			border-radius: 2px 2px 0px 0px;
			text-align: center;
			line-height: 32px;
			color: #ffffff;
		}

		.addPoints-content {
			min-width: 100%;
			height: auto;
			min-height: 130px;
		}

		.point {
			width: 100%;
		}

		.form {
			height: auto;
		}

		.form-points {
			flex-direction: column;
		}
	}

	.open {
		transform: translateX(-50%);
	}

	.addPoints.MOBIE.open {
		transform: translateX(-50%);
	}
</style>
<template>
  <div class="receiverList">
    <div class="fixed">
      <div class="query">
        <div class="input-width">
          <el-input
            :placeholder="$t('public.please_input')"
            v-model="queryData.keyWords"
            @keyup.enter.native="searchList"
            size="small"
          >
            <template slot="prepend">
              <el-select
                v-model="queryData.type"
                style="width: 109px"
                @change="selectChange"
              >
                <el-option
                  v-for="item in $t('option.receiverList.query_options')"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
            <!-- <template slot="append">
            <el-button icon="el-icon-search" @click="searchList"></el-button>
          </template> -->
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              style="cursor: pointer"
              @click="searchList"
            ></i>
          </el-input>
        </div>
        <div
          style="display: flex; align-items: center"
          :style="{
            marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
          }"
        >
          <div
            :style="{
              fontSize: '14px',
              marginLeft: deviceInfo.type == 'PC' ? '40px' : '',
              minWidth: '110px',
            }"
          >
            连接状态：
          </div>
          <el-select
            size="small"
            v-model="status"
            placeholder="请选择"
            @change="statusChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            v-if="deviceInfo.type == 'PC'"
            size="small"
            style="
              background-color: #539d56;
              margin-left: 10px;
              color: #fff;
              border: none;
            "
            @click="searchList"
            >查询</el-button
          >
        </div>
        <el-button
          v-if="deviceInfo.type != 'PC'"
          size="small"
          style="
            background-color: #539d56;
            margin: 10px 0 0;
            color: #fff;
            border: none;
            width: 100%;
          "
          @click="searchList"
          >查询</el-button
        >
      </div>
      <div
        v-if="deviceInfo.type !== 'PC'"
        class="pageTitle"
        style="line-height: 30px; font-weight: 600"
      >
        {{ $t("label.receiverList.page_title") }}：
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div
          v-if="deviceInfo.type == 'PC'"
          class="pageTitle"
          style="line-height: 30px; font-weight: 600"
        >
          {{ $t("label.receiverList.page_title") }}：
        </div>
        <div
          class="operate-btn"
          :style="{
            marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
          }"
        >
          <el-button
            :class="{ btn: $store.getters.deviceInfo.type == 'PC' }"
            :size="$store.getters.deviceInfo.type == 'PC' ? 'small' : 'mini'"
            type="danger"
            @click="deleteData"
          >
            {{ $t("dialog.userList.delete") }}
          </el-button>
          <el-button
            type="success"
            :size="$store.getters.deviceInfo.type == 'PC' ? 'small' : 'mini'"
            style="background-color: #539d56; border-color: #539d56"
            :class="{ btn: $store.getters.deviceInfo.type == 'PC' }"
            @click="distribution"
            >{{ $t("label.receiverList.distribution") }}</el-button
          >
          <el-button
            :class="{ btn: $store.getters.deviceInfo.type == 'PC' }"
            :size="$store.getters.deviceInfo.type == 'PC' ? 'small' : 'mini'"
            type="success"
            style="
              background-color: #539d56;
              border-color: #539d56;
              margin-right: 10px;
            "
            @click="toggleShowAddSn"
          >
            {{ $t("dialog.userList.add_equip") }}
          </el-button>
          <el-upload
            ref="upload"
            class="upload-demo"
            action=""
            :limit="1"
            :file-list="fileList"
            :show-file-list="false"
            :http-request="uploadReceiverFile"
            :on-success="handleSuccess"
          >
            <el-button
              type="success"
              style="background-color: #539d56; border-color: #539d56"
              class="btn"
              size="small"
            >
              {{ $t("dialog.userList.batch_add_equip") }}
            </el-button>
          </el-upload>
        </div>
      </div>
    </div>

    <div class="table" v-if="deviceInfo.type == 'PC'">
      <el-table
        :data="tableData"
        ref="tableData"
        stripe
        :row-key="
          (row) => {
            return row.id;
          }
        "
        @selection-change="handleSelectionChange"
        :height="tableHeight"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.receiverList.${column}`)"
          align="center"
          :min-width="column == 'operate' ? '150px' : '100px'"
        >
          <template slot-scope="{ row }">
            <template v-if="column == 'status'">
              <span v-if="row.status === 1" style="color: #3e916a">
                {{ $t("label.receiverList.link") }}
              </span>
              <span v-else style="color: #ce0000">
                {{ $t("label.receiverList.break") }}
              </span>
            </template>
            <template v-else-if="column == 'operate'">
              <el-button
                type="success"
                size="small"
                @click="jumpDetail(row, 'config')"
                :style="
                  row.status == 1
                    ? 'background-color: #3E916A;border-color: #3E916A'
                    : ''
                "
                :disabled="row.status != 1"
                >{{ $t("label.receiverList.config") }}</el-button
              >
              <el-button
                type="success"
                size="small"
                @click="jumpDetail(row, 'dataSearch')"
                style="background-color: #3e916a; border-color: #3e916a"
                >{{ $t("label.receiverList.dataSearch") }}</el-button
              >
            </template>
            <template v-else>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="deviceInfo.type !== 'PC'">
      <van-list
        v-model="isLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div class="MBCard" v-for="(item, index) in MBData" :key="index" @click="handlerCheck(item)">
        <el-checkbox @change="tbChange($event, item)" v-model="item.checked"></el-checkbox>
        <div class="item">
          <span>序号：</span>
          <span>{{ item.index }}</span>
        </div>
        <div class="item">
          <span>设备SN：</span>
          <span>{{ item.sn }}</span>
        </div>
        <div class="item">
          <span>连接状态：</span>
          <span :style="{ color: item.status != 1 ? '#FF3B3B' : '#539D56' }">{{
            item.status != 1 ? "离线" : "在线"
          }}</span>
        </div>
        <div class="item">
          <span>电源类型：</span>
          <span>{{ item.ptype }}</span>
        </div>
        <div class="item">
          <span>电池电量：</span>
          <span>{{ item.power }}</span>
        </div>
        <div class="item">
          <span>设备归属：</span>
          <span>{{ item.equip_ownership }}</span>
        </div>
        <div class="btn">
          <el-button
            type="success"
            size="small"
            @click="jumpDetail(item, 'config')"
            :style="
              item.status == 1
                ? 'background-color: #3E916A;border-color: #3E916A'
                : ''
            "
            :disabled="item.status != 1"
            >{{ $t("label.receiverList.config") }}</el-button
          >
          <el-button
            type="success"
            size="small"
            @click="jumpDetail(item, 'dataSearch')"
            style="background-color: #3e916a; border-color: #3e916a"
            >{{ $t("label.receiverList.dataSearch") }}</el-button
          >
        </div>
      </div>
      </van-list>
      
    </div>
    <div class="pagination" v-if="deviceInfo.type == 'PC'">
      <el-pagination
        background
        :layout="
          $store.getters.deviceInfo.type == 'PC'
            ? 'sizes, prev, pager, next, jumper'
            : 'prev, pager, next'
        "
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        :total="total"
        popper-class="mySelectType"
        :current-page="page"
        @size-change="sizeChange"
        @current-change="currentChange"
        :small="$store.getters.deviceInfo.type != 'PC'"
      >
      </el-pagination>
    </div>
    <EditSn
      :show="showAddSn"
      @toggleShow="toggleShowAddSn"
      @refush="getReceiverList"
    ></EditSn>
    <DisReceiver
      :show="showDis"
      :selectValue="selectValue"
      @searchTableData="searchTableData"
      @toggleShow="closeDis"
      @refush="getReceiverList"
    ></DisReceiver>
    <ConfirmDialog
      :title="'删除设备'"
      :show="show"
      :message="'确定删除所选设备？'"
      @toggleShow="closeDia"
      @submit="closeDia"
      @delete="toDelete"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { List } from 'vant';
import ConfirmDialog from "../../components/public/confirmDialog";
import { deviceList, batchAdd, delList } from "@/api/index";
import EditSn from "../user/component/editSN.vue";
import DisReceiver from "./component/disReceiverList.vue";
import { color } from "echarts";
export default {
  data() {
    return {
      show: false,
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "在线",
        },
        {
          value: "2",
          label: "离线",
        },
      ],
      statusSelect: "0",
      queryData: {
        type: "sn",
        keyWords: "",
      },
      columns: [
        "index",
        "sn",
        "status",
        "ptype",
        "power",
        "equip_ownership",
        "operate",
      ],
      tableData: [
        // {
        //   index: 1,
        //   sn: 111111,
        //   status: 1,
        //   pType: 1,
        //   power: 1,
        //   equip_ownership: 1,
        //   id: 1,
        // },
      ],
      MBData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      searchType: "sn",
      sn: "",
      ownership: "",
      status: "0", // 在线状态 0全部 1在线 2离线
      fileList: [],
      showAddSn: false,
      showDis: false,
      choose: [],
      selectValue: [],
      tableHeight: 450,
      isLoading: true,
      finished: false,
    };
  },
  components: { EditSn, DisReceiver, ConfirmDialog, VanList: List },
  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        // console.log(val);
        this.$nextTick(() => {
          this.$refs.tableData?.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  activated() {
    // receiverList
    this.getReceiverList();
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      if (this.deviceInfo.type == "PC") {
        let height =
          window.innerHeight - this.$refs.tableData.$el.offsetHeight + 210;
        this.tableHeight = height;
      }
    });
  },
  deactivated() {
    this.sn = "";
    this.ownership = "";
    this.status = "0";
    this.queryData.keyWords = "";
    this.statusSelect = "0";
  },
  methods: {
    onLoad(){
      this.page++
      this.isLoading = true
      this.getReceiverList()
    },
    handlerCheck(info){
      // info.checked = !info.checked
    },
    tbChange(e, info) {
      if (e) {
        this.selectValue.push(info.sn);
        this.choose.push(info)
        // info.checked = true
      } else {
        this.selectValue = this.selectValue.filter((item) => item !== info.sn);
        this.choose = this.choose.filter(item => item.sn !== info.sn)
        // info.checked = false
      }
      console.log(this.selectValue,this.choose, "seleeeee");
    },
    closeDia() {
      this.show = false;
    },
    toDelete() {
      // delList
      let sns = this.choose.map((item) => {
        return item.sn;
      });
      let param = {
        sn: sns,
        model: 1,
      };
      delList(param).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getReceiverList();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
      // console.log(param, 'pppp')
    },
    deleteData() {
      if (!this.choose.length) {
        this.$message({
          type: "warning",
          message: "请选择设备进行删除",
        });
        return;
      }
      this.show = true;
      // console.log("删除")
    },
    // 获取时间
    getDate() {
      let time = new Date();
      let startYear = time.getFullYear();
      let startMonth =
        time.getMonth() + 1 < 10
          ? "0" + (time.getMonth() + 1)
          : time.getMonth() + 1;
      let startDay =
        time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
      let startHour =
        time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      let startMinutes =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      let startSecond =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
      let startTime =
        startYear +
        "-" +
        startMonth +
        "-" +
        startDay +
        " " +
        startHour +
        ":" +
        startMinutes +
        ":" +
        startSecond;
      time.setTime(time.getTime() - 24 * 60 * 60 * 1000 * 1);
      let endYear = time.getFullYear();
      let endMonth =
        time.getMonth() + 1 < 10
          ? "0" + (time.getMonth() + 1)
          : time.getMonth() + 1;
      let endDay = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
      let endHour =
        time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      let endMinutes =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      let endSecond =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
      let endTime =
        endYear +
        "-" +
        endMonth +
        "-" +
        endDay +
        " " +
        endHour +
        ":" +
        endMinutes +
        ":" +
        endSecond;
      return [endTime, startTime];
    },
    statusChange(e) {
      this.status = e;
      this.page = 1;
      // this.getReceiverList();
    },
    // 跳转流速球详情
    jumpDetail(e, param) {
      sessionStorage.setItem("currentSn", e.sn);
      sessionStorage.setItem("currentModel", e.model);
      // 在新标签页中打开
      if (param == "config") {
        let page = this.$router.resolve({
          name: "receiverInfo", // 路由名称
          // query: { id: id, e: 0 }, // 传递参数
        });
        // console.log(page.href);
        window.open(page.href + "?type=config", "_blank");
      } else {
        let page = this.$router.resolve({
          name: "dataManage", // 路由名称
          query: { sn: e.sn, date: this.getDate() }, // 传递参数
        });
        // console.log(page.href);
        window.open(page.href, "_self");
      }
    },
    // 改变搜索条件搜索
    selectChange(e) {
      this.searchType = e;
    },
    // 搜索
    searchList() {
      this.MBData = []
      this.page = 1;
      if (this.searchType === "sn") {
        this.sn = this.queryData.keyWords;
        this.ownership = "";
      } else {
        this.sn = "";
        this.ownership = this.queryData.keyWords;
      }
      this.getReceiverList();
    },
    getReceiverList() {
      let param = {
        page_num: this.page,
        page_size: this.pageSize,
        sn: this.sn,
        agent: this.ownership,
        online_status: this.status,
        model: 1,
      };
      deviceList(param).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item, index) => {
            return {
              index: (this.page - 1) * this.pageSize + index + 1,
              id: item.id,
              sn: item.sn,
              status: item.online_status,
              equip_ownership: item.user_info.username,
              model: item.model, // 设备类型
              ptype:
                item.battery_info.type == "65535"
                  ? "-"
                  : this.$t(`label.device.bt[${item.battery_info.type}]`),
              power:
                item.battery_info.percentage == "65535"
                  ? "-"
                  : item.battery_info.percentage + "%",
                checked: false,
            };
          });
          this.finished = res.data.rows.length ? false : true;
          this.MBData = this.MBData.concat(this.tableData);
          this.total = res.data.total;
          this.isLoading = false;
        }
      });
    },
    // 批量添加设备
    uploadReceiverFile(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      // console.log(formData.getAll("file")); // 无法直接打印formData
      this.$refs.upload.clearFiles(); // 清空上传的记录
      batchAdd(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: this.$t("label.receiverList.add_info_success"),
            duration: 2000,
          });
          this.getReceiverList();
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
            duration: 2000,
          });
        }
        this.fileList = [];
      });
      this.page = 1;
    },
    // 选择设备
    handleSelectionChange(e) {
      this.choose = e;
    },
    // 分配流速球
    distribution() {
      this.selectValue = [];
      if (this.choose && this.choose.length === 0) {
        this.$message({
          type: "warning",
          message: this.$t("label.receiverList.choose_receiver_warning"),
          duration: 2000,
        });
      } else {
        this.showDis = true;
        this.choose.forEach((item) => {
          this.selectValue.push(item.sn);
        });
      }
    },
    toggleShowAddSn() {
      this.showAddSn = !this.showAddSn;
    },
    closeDis() {
      this.showDis = false;
    },
    handleSuccess() {
      // console.log("79333");
    },
    // 分页
    sizeChange(e) {
      this.pageSize = e;
      this.getReceiverList();
    },
    // 页数
    currentChange(e) {
      this.page = e;
      // console.log("页数", e);
      this.getReceiverList();
    },
    // 提交清除数据的选中状态
    searchTableData() {
      this.$refs.tableData.clearSelection(); //清除选中的数据
    },
  },
};
</script>

<style lang="scss" scoped>
.MBCard {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #979797;
  border-radius: 8px;
  margin: 10px 0;
  .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    line-height: 32px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
  }
}
.receiverList {
  padding: 0 20px 20px;
  .fixed {
    background-color: #fff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 9;
    // top: 20px;
    // padding: 20px 0;
  }

  .query {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;
  }

  .input-width {
    max-width: 400px;
  }

  .operate-btn {
    display: flex;
  }

  .btn {
    min-width: 80px;
    height: 32px;
  }

  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: -6px;
    right: 20px;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding-bottom: 15px;
    width: calc(100% - 230px);
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    .el-select .el-input__inner:focus {
      border-color: #f5f7fa;
    }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }

    .el-button--success.is-disabled {
      background-color: #c0c4cc;
      border-color: #c0c4cc;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }
}
</style>
<style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>

import {
  lineString,
  featureCollection,
  point,
  center,
  lineIntersect,
  nearestPoint,
} from "@turf/turf";
export default {
  data() {
    return {
      sectionQueryData: {
        page_num: 1,
        page_size: 10,
      },
      _max_section_totel: 0
    }
  },
  watch: {
    mapLoad(val) {
      if (val && this.modulesInit) {
        this.modulesInit.push(this._section_init)
      }
    }
  },
  provide() {
    return {
      _setLayerData: this.handlerSetLayerData,
      _clearFeatures: this.clearFeatures,
      _savePoints: this.savePoints,
      _deletePoints: this.deletePoints,
    }
  },
  inject: {
    _custom_section_data: {
      name: "_custom_section_data",
      default: () => { }
    }
  },
  methods: {
    async _section_init() {

      if (this.isShowSection) {
        console.log('模块section初始化步骤');
        if (this._custom_section_data) {
          this.handlerCustomSectionData();
        } else {
          await this.getSectionList();
        }
      }
    },
    async handlerCustomSectionData() {
      let res = await this._custom_section_data();
      this.list = res.map((f, i) => {
        let temp = {
          ...f,
          title: f.line_name || f.title,
          id:f.line_id || f.id,
          points: [
            {
              lon: this.$tool.toFixedFn(f.begin_lon, 8),
              lat: this.$tool.toFixedFn(f.begin_lat, 8),
            },
            {
              lon: this.$tool.toFixedFn(f.end_lon, 8),
              lat: this.$tool.toFixedFn(f.end_lat, 8),
            },
          ],
        };
        this.handlerSetLayerData(temp);
        return temp;
      })
      this.computedIntersect();
      // console.log(res);
    },
    // 处理layers数据
    handlerSetLayerData(data, add = false) {
      let points = data.points.map((f, i) =>
        point([Number(f.lon), Number(f.lat)], {
          id: data.id,
          title: data.title,
          index: i % 2,
        })
      );
      let orinigData = this.$tool.clone(this.map.getSource("section")._data);
      if (add) {
        orinigData.features = orinigData.features.filter((f) => {
          if (f.properties) {
            return f.properties.id !== data.id;
          } else {
            return true;
          }
        });
      }

      orinigData.features.push(...points);
      orinigData.features.push(
        lineString(
          data.points.map((f) => [Number(f.lon), Number(f.lat)]),
          {
            id: data.id,
            title: data.title,
            center: center(featureCollection(points)),
            points,
          }
        )
      );
      this.map.getSource("section").setData(orinigData);
      this.map._measureTools.clear();
    },
    // 删除点
    deletePoints(item) {
      this.$confirm(this.$t("label.sectionPoint.tip"), this.$t("public.tip"), {
        confirmButtonText: this.$t("public.submit"),
        cancelButtonText: this.$t("public.cancel"),
        type: 'warning'
      }).then(() => {
        this.$api
          .delSection({
            id: [item.id],
          })
          .then((res) => {
            this.list = this.list.filter((f) => f.id !== item.id);
            this.clearFeatures(item.id);
          });
      }).catch(() => {
      });

    },
    // 保存截面点对
    savePoints(data) {
      this.$api
        .addSection({
          name: data.title,
          begin_lon: data.points[0].lon,
          begin_lat: data.points[0].lat,
          end_lon: data.points[1].lon,
          end_lat: data.points[1].lat,
        })
        .then((res) => {
          this.sectionQueryData.page_num = 1;
          this.getSectionList();
          this.$refs.addPoint.reSet();
        });
    },
    // 清除要素
    clearFeatures(id) {
      let orinigData = this.$tool.clone(this.map.getSource("section")._data);
      orinigData.features = orinigData.features.filter((f) => {
        if (f.properties) {
          return f.properties.id !== id;
        } else {
          return true;
        }
      });
      this.map.getSource("section").setData(orinigData);
      this.computedIntersect();
    },
    // 获取截面点对
    getSectionList($store) {
      if(this._custom_section_data){
        return;
      }
      if (this.sectionQueryData.page_num !== 1 && this.list.length >= this._max_section_totel) {
        return;
      }
      if (this.sectionQueryData.page_num === 1) {
        this.map.getSource("section").setData({
          type: "FeatureCollection",
          features: [],
        });
      }
      return this.$api.sectionList(this.sectionQueryData).then((res) => {
        this._max_section_totel = res.data.total;
        let list = res.data.rows.map((f, i) => {
          let temp = {
            ...f,
            title: f.name,
            points: [
              {
                lon: this.$tool.toFixedFn(f.begin_lon, 8),
                lat: this.$tool.toFixedFn(f.begin_lat, 8),
              },
              {
                lon: this.$tool.toFixedFn(f.end_lon, 8),
                lat: this.$tool.toFixedFn(f.end_lat, 8),
              },
            ],
          };
          this.handlerSetLayerData(temp);

          return temp;
        });
        if (this.sectionQueryData.page_num === 1) {
          this.list = list;
        } else {
          this.list.push(...list)
        }
        this.sectionQueryData.page_num += 1;
        this.computedIntersect();
      });
    },

  }
}
const turf = require('@turf/turf');
const mapboxgl = require('mapbox-gl');
const MapboxDraw = require('@mapbox/mapbox-gl-draw');
// import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
require('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css');
class Measure {
  constructor(map, cb = () => { }) {
    this.mdata = {};
    this.isMeasureType = '';
    this.measureComplete = this.measureComplete.bind(this);
    this.cb = cb;
    this.map = map;
    this.canvas = this.map.getCanvasContainer();

    const modes = MapboxDraw.modes;
    // modes.draw_rectangle = DrawRectangle;

    this.mapMeasureDrawTool = new MapboxDraw({
      displayControlsDefault: false,
      keybindings: false,
      touchEnabled: false,
      boxSelect: false,
      controls: {},
      modes: modes
    });
    this.map.addControl(this.mapMeasureDrawTool, 'top-right');
    this.map.on('draw.update', this.measureComplete);
  }

  boxZoom() {
    this.isMeasureType = 'boxZoom';
    this.map.on('draw.create', this.measureComplete);
    this.mapMeasureDrawTool.changeMode('draw_rectangle');

    this.map.operateState = 'draw_box_zoom';
    this.map.getCanvas().style.cursor = 'crosshair';
  }

  distance() {
    this.isMeasureType = 'line';
    this.map.on('draw.create', this.measureComplete);
    this.mapMeasureDrawTool.changeMode('draw_line_string');

    this.map.operateState = 'draw_line_string';
    this.map.getCanvas().style.cursor = 'crosshair';
  }

  area() {
    this.isMeasureType = 'polygon';
    this.map.on('draw.create', this.measureComplete);
    this.mapMeasureDrawTool.changeMode('draw_polygon');
    this.map.operateState = 'draw_polygon';
    this.map.getCanvas().style.cursor = 'crosshair';
  }

  point(cb) {
    this.isMeasureType = 'point';
    if (cb) {
      this.cb = cb
    }
    this.map.on('draw.create', this.measureComplete);
    this.mapMeasureDrawTool.changeMode('draw_point');

    this.map.operateState = 'draw_point';
    this.map.getCanvas().style.cursor = 'crosshair';

  }

  clear() {
    this.isMeasureType = '';
    this.cb = () => { }
    for (let key in this.mdata) {
      if (this.mdata[key].pop) {
        this.mdata[key].pop.remove();
      }
    }
    this.mapMeasureDrawTool.deleteAll();
  }

  // 测量结果
  measureComplete(e) {
    this.cb({
      event: e,
      type: this.isMeasureType
    })
    let currentFeature = null;

    let selected = this.mapMeasureDrawTool.getSelected();
    if (selected.features.length > 0) {
      currentFeature = selected.features[0];
    } else {
      let datas = this.mapMeasureDrawTool.getAll();

      if (datas.features.length > 0) {
        currentFeature = datas.features[datas.features.length - 1];
      }
    }

    if (currentFeature && this.isMeasureType == 'boxZoom') {
      let box = turf.bbox(currentFeature);

      this.map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]]
        ],
        { animate: false }
      ); // 以顶级单位的区域为边界确定地图的视野
      this.mapMeasureDrawTool.delete(currentFeature.id);
    } else if (currentFeature) {
      // console.log(currentFeature);
      if (this.mdata[currentFeature.id]) {
        let currentMdata = this.mdata[currentFeature.id];
        if (currentMdata.type === 'line') {
          const coordinates = currentFeature.geometry.coordinates;
          const position = coordinates[coordinates.length - 1];
          const result = Math.round(turf.lineDistance(currentFeature) * 1000) / 1000;
          currentMdata.pop
            .setLngLat(position)
            .setHTML('<div style="margin-top:15px">距离：' + result + ' (km)</div>');
        } else if (currentMdata.type === 'polygon') {
          const center = turf.centroid(currentFeature).geometry.coordinates;
          const result = Math.round((turf.area(currentFeature) / 1000000) * 1000) / 1000;
          currentMdata.pop
            .setLngLat(center)
            .setHTML('<div style="margin-top:15px">面积：' + result + ' (km²)</div>');
        } else if (currentMdata.type === 'point') {
          const position = currentFeature.geometry.coordinates;
          currentMdata.pop
            .setLngLat(position)
            .setHTML('<div style="margin-top:15px">经纬度：' + position.join(",") + ' </div>');

        }
      } else {
        if (this.isMeasureType === 'line') {
          const coordinates = currentFeature.geometry.coordinates;
          const position = coordinates[coordinates.length - 1];
          const result = turf.lineDistance(currentFeature);
          let resultTxt =
            result > 1 ? result.toFixed(2) + ' (km)' : (result * 1000).toFixed(2) + ' (m)';
          let pop = new mapboxgl.Popup({
            className: 'overview-pop-class',
            closeButton: true,
            closeOnClick: false
          });
          pop
            .setLngLat(position)
            .setHTML('<div style="margin-top:15px">距离：' + resultTxt + '</div>')
            .addTo(this.map);
          this.mdata[currentFeature.id] = { pop: pop, type: 'line' };
          pop.on('close', () => {
            delete this.mdata[currentFeature.id];
            this.mapMeasureDrawTool.delete(currentFeature.id);
          });
        } else if (this.isMeasureType === 'polygon') {
          let unit = {
            value: 1,
            name: "㎡"
          };

          const center = turf.centroid(currentFeature).geometry.coordinates;
          const result = Math.round((turf.area(currentFeature)) * 1000) / 1000;
          let pop = new mapboxgl.Popup({
            className: 'overview-pop-class',
            closeButton: true,
            closeOnClick: false
          });
          pop
            .setLngLat(center)
            .setHTML('<div style="margin-top:15px">面积：' + result + ' (' + unit.name + ')</div>')
            .addTo(this.map);
          pop.on('close', () => {
            delete this.mdata[currentFeature.id];
            this.mapMeasureDrawTool.delete(currentFeature.id);
          });
          this.mdata[currentFeature.id] = { pop: pop, type: 'polygon' };
        } else if (this.isMeasureType === 'point') {
          const position = currentFeature.geometry.coordinates;
          let pop = new mapboxgl.Popup({
            className: 'overview-pop-class',
            closeButton: true,
            closeOnClick: false
          });
          pop.setLngLat(position)
            .setHTML('<div style="margin-top:15px">经纬度：' + position.join(",") + ' </div>').addTo(this.map);
          pop.on('close', () => {
            delete this.mdata[currentFeature.id];
            this.mapMeasureDrawTool.delete(currentFeature.id);
          });
          this.mdata[currentFeature.id] = { pop: pop, type: 'point' };
        }
      }
    }
    this.map.off('draw.create', this.measureComplete);
    setTimeout(() => {

      this.map.operateState = '';
      this.map.getCanvas().style.cursor = '';
    });
  }
}

export default Measure;

<template>
	<van-popup v-model="show" round :position="$store.getters.deviceInfo.type === 'PC' ? 'center' : 'bottom'"
		:style="{ 'height': 'calc(100vh - 42px)' }">
		<div class="flex sectionDataDetail" :class="{[$store.getters.deviceInfo.type]: true }">
			<div class="flex-dc table" style="height: 100%">
				<div class="title">
					列表
				</div>
				<div class="table-btns" v-if="$store.getters.deviceInfo.type === 'PC'">
					<el-button size="small" style="border-color: #539d56; background-color: #539d56" type="primary"
						@click="addItem">
						{{ $t(`label.sectionPoint.add`) }}
					</el-button>
					<el-button size="small" style="border-color: #ef9a41; background-color: #ef9a41" type="primary"
						@click="delItems">
						{{ $t(`label.sectionPoint.del`) }}
					</el-button>
				</div>
				<div class="table-content">
					<el-table :data="tableData" height="100%" @selection-change="handleSelectionChange" style="width: 100%">
						<el-table-column type="selection" width="55"> </el-table-column>
						<el-table-column prop="start" label="起点距" min-width="50">
							<template slot-scope="{ row }">
								<template v-if="currentRow.key !== row.key">
									{{ row.start }}
								</template>
								<el-input v-else v-model.number="currentRow.start" placeholder="请输入内容"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="height" label="高程" min-width="50">
							<template slot-scope="{ row }">
								<template v-if="currentRow.key !== row.key">
									{{ row.height }}
								</template>
								<el-input v-else v-model.number="currentRow.height" placeholder="请输入内容"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="操作" min-width="30">
							<template slot-scope="{ row }">
								<template v-if="currentRow.key !== row.key">
									<i class="el-icon-edit icon" style="color: #539d56;" @click="handlerEdit(row)"></i>
									<i class="el-icon-delete icon" style="color: #ff0000;" @click="delItem(row)"></i>
								</template>
								<template v-else>
									<i class="el-icon-document-checked icon" style="color: #5470c6;" @click="saveData"></i>
									<i class="el-icon-document-delete icon" style="color: #ff0000;" @click="clearCurrentRow"></i>
								</template>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="table-btns" v-if="$store.getters.deviceInfo.type !== 'PC'">
					<el-button size="small" style="border-color: #539d56; background-color: #539d56" type="primary"
						@click="addItem">
						{{ $t(`label.sectionPoint.add`) }}
					</el-button>
					<el-button size="small" style="border-color: #ef9a41; background-color: #ef9a41" type="primary"
						@click="delItems">
						{{ $t(`label.sectionPoint.del`) }}
					</el-button>
				</div>
			</div>
			<div class="chart" v-if="isShowChart">
				<div class="title">
					折线图
				</div>
				<i class="el-icon-close close" @click="close()"></i>
				<base-chart id="sectionDataDetailChart" @load="chartInit"></base-chart>
			</div>
		</div>
	</van-popup>
</template>

<script>
import BaseChart from "@/components/public/baseChart";
import { Popup } from 'vant';
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		data: {
			type: [Array,Object],
			default: () => {
				return null;
			},
		},
	},
	components: {
		BaseChart,
		"van-popup": Popup
	},
	watch: {
		data() {
			if (this.show) {
				this.handlerData();
			}
		},
		show(val) {
			if (val) {
				this.handlerData();
			}
		},
	},
	data() {
		return {
			tableData: [],
			currentRow: {
				start: "",
				height: "",
			},
			isShowTable: true,
			isShowChart: true,
		};
	},
	computed: {
		dataObj({ data }) {
			let res = {};
			data.forEach((e) => {
				res[e.key] = e;
			});
			return res;
		},
	},
	methods: {
		chartInit(val) {
			this._chart = val;
			this.checkChartData();
		},
		getDefalutOption() {
			return {
				grid: {
					left: "3%",
					right: "4%",
					bottom: "3%",
					containLabel: true,
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
					axisLine: {
						onZero: false,
					},
					// data: [],
				},
				yAxis: {
					type: "value",
					axisLine: {
						onZero: false,
					},
				},
				series: [],
			};
		},
		handlerData() {
			this.tableData = this.$tool.clone(this.data);
			this.handlerChartData();
			this.checkChartData();
		},
		checkChartData() {
			if (this._chart && this._chartData) {
				let temp = this.getDefalutOption();
				// temp.xAxis.data = this._chartData.x_data;
				temp.yAxis.min = this._chartData.minY;
				temp.series.push({
					name: "高程线",
					type: "line",
					stack: "Total",
					areaStyle: {},
					symbol: "none",
					emphasis: {
						focus: "series",
					},
					data: this._chartData.series_0_data,
				});

				this.$nextTick(() => {
					this._currentOption = temp;
					this._chart.resize();
					this._chart.setOption(temp);
					this.handlerWarterData();
					// this.handlerWarterData();
				});
			}
		},
		handlerChartData() {
			let series_0_data = [];
			let series_1_data = [];
			let x_data = [];
			let minY = null;
			let maxY = null;
			this.tableData.forEach((f, i) => {
				f.index = i;
				series_0_data.push({
					value: f.height,
					...f,
					index: i,
				});
				if (minY === null) {
					minY = f.height;
				} else {
					if (minY > f.height) {
						minY = f.height;
					}
				}
				if (maxY === null) {
					maxY = f.height;
				} else {
					if (maxY < f.height) {
						maxY = f.height;
					}
				}
				series_1_data.push(null);
				x_data.push(f.start);
			});
			this._chartData = { series_0_data, minY, maxY, series_1_data, x_data };
			return this._chartData;
		},
		showWarterLine(val = null) {
			this.isShowChart = true;
			this.warterLine = val;

			this.handlerChartData();
			this.checkChartData();
		},
		handlerWarterData() {
			if (!this.warterLine) {
				return;
			}
			let arr = [];
			let _series_0_data = this.$tool.clone(this._chartData.series_0_data);
			for (let index = 0; index < this.tableData.length; index++) {
				const element = this.tableData[index];
				const next = this.tableData[index + 1];
				if (
					(element.height <= this.warterLine &&
						next &&
						next.height >= this.warterLine) ||
					(element.height >= this.warterLine &&
						next &&
						next.height <= this.warterLine)
				) {
					arr.push(element);
				}
			}
			arr.forEach((f) => {
				let c = this.dataObj[f.key];
				let n = this.dataObj[f.key + 1];
				if (c && n) {
					let point_x = this.computeLine(
						{ x: c.start, y: c.height },
						{ x: n.start, y: n.height },
						this.warterLine
					);
					let index = _series_0_data.findIndex((f) => f.key === c.key);
					_series_0_data.splice(index + 1, 0, {
						value: this.warterLine,
						key: "point",
						start: point_x,
						height: this.warterLine,
					});
				}
			});
			let flag = false;
			let series_1_data = [];
			_series_0_data.forEach((f) => {
				if (f.key === "point") {
					flag = !flag;
					if (!flag) {
						series_1_data.push(this.warterLine - f.height);
					}
				}
				let num = null;
				if (flag) {
					num = this.warterLine - f.height;
				}
				if (num < 0) {
					num = null;
				}
				series_1_data.push(num);
			});
			this._currentOption.series[0].data = _series_0_data;
			if (this._currentOption.series.length !== 1) {
				this._currentOption.series[1].data = series_1_data;
			} else {
				this._currentOption.series.push({
					name: "水位线",
					type: "line",
					stack: "Total",
					areaStyle: {},
					symbol: "none",
					emphasis: {
						focus: "series",
					},
					data: series_1_data,
				});
			}

			this.$nextTick(() => {
				this._chart.setOption(this._currentOption);
				// this.handlerWarterData();
			});
		},
		computeLine(p0, p1, t) {
			let x1 = p0.x;
			let y1 = p0.y;
			let x2 = p1.x;
			let y2 = p1.y;
			return ((t - y1) * (x2 - x1)) / (y2 - y1) + x1;
		},
		handlerEdit(val) {
			this.currentRow = this.$tool.clone(val);
		},
		toggleShowTable(val = false) {
			this.isShowTable = val;
			this.checkClose();
		},
		toggleShowChart(val = false) {
			this.isShowChart = val;
			this.checkClose();
		},
		saveData() {
			if (!this.currentRow.start || !this.currentRow.height) {
				this.$message({
					type: "warning",
					message: "起点距和高程不能为空",
				});
				return;
			}
			let index = this.tableData.findIndex(
				(f) => f.key === this.currentRow.key
			);
			if (index === -1) {
				return;
			}
			if (
				index !== this.tableData.length - 1 &&
				this.tableData[index + 1] &&
				this.currentRow.start > this.tableData[index + 1].start
			) {
				this.$message({
					type: "warning",
					message: "起点距必须为递增数列",
				});
				return;
			}

			let temp = this.tableData[index];
			temp.start = this.currentRow.start;
			temp.height = this.currentRow.height;
			this.clearCurrentRow();
			this.handlerChartData();
			this.checkChartData();
		},
		clearCurrentRow() {
			this.currentRow = {};
		},
		addItem() {
			let temp = this.$tool.clone(this.tableData.slice(-1)[0]);
			temp.key += 1;
			this.tableData.push(temp);
			this.handlerChartData();
			this.checkChartData();
		},
		delItem(row) {
			this.tableData.splice(row.index, 1);
			this.handlerChartData();
			this.checkChartData();
		},
		delItems() {
			if (!this._selection) {
				this.$message({
					type: "warning",
					message: "请先进行勾选",
				});
				return;
			}
			this.$confirm("确定删除数据吗", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.tableData = this.tableData.filter(
						(f) => !this._selection[f.key]
					);
					this.handlerChartData();
					this.checkChartData();
				})
				.catch(() => { });
		},
		handleSelectionChange(row) {
			this._selection = row.length
				? row.reduce((t, e) => {
					t[e.key] = e;
					return t;
				}, {})
				: null;
		},
		setOption() { },
		checkClose() {
			if (!this.isShowChart && !this.isShowTable) {
				this.close();
			}
		},
		close() {
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss" scoped>
.sectionDataDetail {
	height: 600px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	overflow: hidden;
	z-index: 50;

	&>div {
		width: 500px;
		height: 100%;
		background-color: #fff;
	}

	.table {
		padding: 10px;
		position: relative;

		&-content {
			flex: 1;
			overflow: hidden;

			.icon {
				cursor: pointer;

				&+.icon {
					margin-left: 6px;
				}
			}
		}
	}

	.chart {
		position: relative;
	}

	.close {
		position: absolute;
		right: 8px;
		top: 8px;
		color: #ff0000;
		font-size: 18px;
		font-weight: bold;
		cursor: pointer;
		z-index: 10;
	}
}

.MOBIE {
	width: 100%;
	height: calc(100vh - 42px);
	position: relative;
	transform: translate(0, 0);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	left: 0;
	top: 0;
	padding: 0 12px;
	gap: 12px;

	&>div {
		width: 100%;
		flex: 1;
		overflow: hidden;
		z-index: 5;
	}

	.title {
		line-height: 2.5;
		padding: 0 12px;
		color: #333;
		// border-left: 2px solid #3FB350;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 2px;
			height: 16px;
			background-color: #3FB350;
		}
	}

	.chart {
		transform: translateY(calc(-100% - 12px));
	}

	.table {
		transform: translateY(calc(100% + 12px));
		padding: 0;

		&-btns {
			display: flex;
			justify-content: center;
			padding: 12px 0;
		}
	}
}

.hide {
	display: none;
}
</style>
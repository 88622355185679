import {
  lineString,
  point,
} from "@turf/turf";

export default {
  data() {
    let _minTime = ""
    let _maxTime = ""
    let that = this;
    return {
      // pickerOptions: {
      //   onPick: this.datePick,
      //   disabledDate: this.handleDisable,
      // },
      // 暂时先这样处理
      pickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 1 * 24 * 60 * 60 * 1000; // 1天
            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
          // onPick后触发
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
        cellClassName(date){
          return that._handler_date_class(date);
        }
      },
    }
  },
  watch: {
    mapLoad(val) {
      if (val && this.modulesInit) {
        this.modulesInit.push(this._track_init)
      }
    }
  },
  inject: {
    _custom_track_data: {
      name: "_custom_track_data",
      default: () => { }
    },
    _handler_date_class: {
      name: "_handler_date_class",
      default: () => { }
    }
  },
  mounted() {
    this.tp = new window.TrackPlay().init(false, 1);
  },
  methods: {
    async _track_init() {
      if (this.isShowTrack) {
        console.log('模块track初始化步骤');

      }
      if (this._custom_track_data) {
        this.handlerCustomTrackData();
      }
       
    },
    async handlerCustomTrackData() {
      let res = await this._custom_track_data();
      
    },   // 创建颜色
    createColor() {
      return '#44FF00';
    },
    // 重置历史轨迹筛选条件
    resetQuery() {
      this.queryData = {
        sns: [],
        status: "",
        dateRange: [],
      };
      this._current_look_at = null;
      this.isRecord = false;
      this.map.getSource("track").setData({
        type: "FeatureCollection",
        features: [],
      });
      this.map.getSource("realTrack").setData({
        type: "FeatureCollection",
        features: [],
      });
      this._sn_select(this.queryData.sns)
      this.map.getSource("trackPoint").setData({
        type: "FeatureCollection",
        features: [],
      });

      this.computedIntersect();
    },
    // 获取流速球历史轨迹
    getBallTrackList() {
      if (!this.queryData.sns.length) {
        this.$message.warning("至少选择一个设备!");
        return;
      }
      if(this.queryData.sns.length === 1 && this.isShowBall){
        this._current_look_at = this.queryData.sns[0];
        this.getPosBySn();
        if(this.queryData.dateRange === 0){
          return
        }
      }

      // if (
      //   this.queryData.dateRange.length != 2 ||
      //   new Date(this.queryData.dateRange[1]).getTime() -
      //   new Date(this.queryData.dateRange[0]).getTime() >
      //   48 * 60 * 60 * 1000
      // ) {
      //   this.$message.warning("请选择正确的时间范围(48小时)!");
      //   return;
      // }
      // if (this.loading) {
      //   this.$message.warning("数据加载中，请稍后再试!");
      //   return;
      // }
      // this.loading = true;
      // this.isRecord = true;
      // this.$emitter.emit("search",this.queryData)
      // this.$api
      //   .ballTrackList({
      //     sn: this.queryData.sns,
      //     begin_time: this.queryData.dateRange[0],
      //     end_time: this.queryData.dateRange[1],
      //     model: 1
      //   })
      //   .then((res) => {
      //     if (res.data.track_info.length) {
      //       this.map.setCenter([
      //         res.data.track_info[0].lng,
      //         res.data.track_info[0].lat,
      //       ]);
      //       this.map.setZoom(20);
      //       this.originTrackData = this.handlerTrackData(
      //         this.$tool.clone(res.data.track_info)
      //       );
      //       this.handlerTrackLine();
      //     } else {
      //       this.$message.warning("暂无轨迹信息！");
      //       this.map.getSource("track").setData({
      //         type: "FeatureCollection",
      //         features: [],
      //       });
      //       this.map.getSource("trackPoint").setData({
      //         type: "FeatureCollection",
      //         features: [],
      //       });
      //       this.computedIntersect();
      //     }
      //     this.loading = false;
      //   });
    },
    // 处理轨迹点
    handlerTrackLine() {
      if (!this.originTrackData) {
        return;
      }
      let line = [];
      let points = [];
      Object.keys(this.originTrackData).forEach((k) => {
        const item = this.originTrackData[k];
        let temp_points = [];

        points.push(
          ...item.map((_point) => {
            let _temp = [_point.lng, _point.lat];
            temp_points.push(_temp);
            return point(_temp, _point);
          })
        );
        let temp_line = lineString(temp_points, { sn: k, color: this.createColor() });
        temp_line.id = Number(k);
        temp_line.name = k;
        line.push(temp_line);
      });
      this.map.getSource("track").setData({
        type: "FeatureCollection",
        features: line,
      });
      this.map.getSource("trackPoint").setData({
        type: "FeatureCollection",
        features: points,
      });
      this.computedIntersect();
    },
    // 设置最小值
    datePick(e) {
      this.minDate = new Date(e.minDate).getTime();
    },
    // 处理日期选择器禁用
    handleDisable(time) {
      if (!this.minDate) {
        return false;
      } 
      if (
        new Date(time).getTime() < this.minDate - 24 * 60 * 60 * 1000 ||
        new Date(time).getTime() > this.minDate + 24 * 60 * 60 * 1000 
      ) {
        return false;
      } else {
        return false;
      }
    },
    // 处理实时轨迹
    handlerRealTrackLine(points) {
      if (!this.isRecord) {
        return;
      }
      let [start_date, end_date] = this.queryData.dateRange;
      let now = new Date().getTime();
      if (!start_date || !end_date || now < new Date(start_date).getTime() || now > new Date(end_date).getTime()) {
        return;
      }
      let _data = points.filter(f => f.online_status === 1).reduce((total, item) => {
        total[item.sn] = item;
        return total;
      }, {});
      let _points = points.filter(f => f.online_status === 1).map(f => {
        return point([f.lon, f.lat], f)
      })
      this.map.getSource("track")._data.features.forEach(f => {
        if (_data[f.name]) {
          f.geometry.coordinates.push([_data[f.name].lon, _data[f.name].lat])
        }
      })
      this.map.getSource("trackPoint")._data.features.push(..._points);
      this.map.getSource("track").setData(this.map.getSource("track")._data);
      this.map.getSource("trackPoint").setData(this.map.getSource("trackPoint")._data);
      this.computedIntersect();
    },
    // 处理轨迹时间
    handlerTrackData(data = []) {
      let res = {};
      data.forEach((f) => {
        if (res[f.sn]) {
          res[f.sn].push(f);
        } else {
          res[f.sn] = [f];
        }
      });
      Object.keys(res).forEach((k) => {
        if(res[k].length>2){
          res[k] = this.tp.splicePath(res[k]).reduce((t,e) => {
            return [...t,...e];
          },[]);
        }
       
      });  
      return res;
    },
    //处理最小时间
    handlerMinDate() {
      if (!this.queryData.dateRange) {
        this.minDate = null;
        return;
      }
      if (this.queryData.dateRange.length != 2 && this.minDate) {
        this.minDate = null;
      }
    },
  }
}
<template>
  <van-popup v-model="show" position="bottom" round closeable :style="{ 'min-height': '30%' }">
    <div class="title">
      {{ lang.title }}
    </div>
    <van-cell-group>
      <van-cell v-for="cell in items" :key="cell.key" :title="lang[cell.label]">
        <div class="flex" v-if="cell.type == 'input'">
          <el-input v-model="form[cell.key]" :min="cell.min || -999999" :maxlength="cell.maxLength || 20"
            :max="cell.max || 999999" type="number" :disabled="cell.disable" size="small"
            :placeholder="lang[cell.label]"></el-input>
          <em v-if="cell.unit" style="margin-left: 4px;">{{ cell.unit }}</em>
        </div>
        <template v-else>
          {{ detail[cell.key] }}{{ cell.unit }}
        </template>
      </van-cell>
    </van-cell-group>
    <div class="flex btns">
      <el-button size="small" class="btn" @click="openTrack">
        {{ lang.track }}
      </el-button>
      <el-button size="small" :style="{
    '--baseColor': '#EF9A41',
  }" :disabled="detail.online_status == '2'" class="btn" @click="openConfig">{{ lang.config }}</el-button>
    </div>
  </van-popup>
</template>

<script>
import { Popup, Cell, CellGroup } from 'vant';
export default {
  components: {
    'van-popup': Popup,
    'van-cell': Cell,
    "van-cell-group": CellGroup,
  },
  data() {
    return {
      show: false,
      form: {
        coefficient: 0.85,
        area: null,
        flow: null
      },
      type: "",
      detail: {},
      lang: {},
      items: []
    }
  },
  watch: {
    'form.area': {
      handler(val) {
        this.computedFlow()
      },
      deep: true
    },
    'form.coefficient': {
      handler(val) {
        this.computedFlow()
      },
      deep: true
    }
  },
  methods: {
    open(e) {
      this.detail = e.data.detail;
      this.lang = e.data.lang;
      this.type = e.data.type;
      this.items = e.cells
      this.show = true;
    },
    computedFlow() {
      const { area = 0, coefficient = 0 } = this.form;
      this.form.flow = this.detail.speed * area * coefficient;
    },
    openTrack() {
      if (window.$emitter) {
        window.$emitter.emit("openTrack", this.detail)
      }
    },
    openConfig() {
      if (window.$emitter) {
        window.$emitter.emit("openConfig", this.detail)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  line-height: 38px;
  text-align: center;
  font-weight: bold;
}

.btns {
  // position: absolute;
  bottom: 0;
  padding: 6px 24px;
  background-color: #fff;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 5;
  justify-content: center;
  gap: 24px;

  .el-button {
    background-color: #539d56;
    color: #fff;
    border-radius: 6px;
    flex: 1;
  }

  .el-button.is-disabled {
    color: #fff;
    background-color: #999;
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 8px;
  right: 8px;
}
</style>
import Vue from 'vue';
const getHtmlFromVM = (Option) => {
  const { data, project } = Option;
  const _project = Vue.extend(project);
  let vm = new _project({
    propsData: data
  });
  vm.$mount(); //挂载
  return vm.$el;
};
export { getHtmlFromVM };

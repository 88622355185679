<template>
  <custom-dialog
    :show="show"
    :title="title"
    @toggleShow="handleClose"
    width="520"
  >
    <div class="form" :class="$store.getters.deviceInfo.type" slot="main">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        inline
        size="small"
        :label-width="$store.getters.deviceInfo.type != 'PC' ? 'auto' : '120px'"
        :label-position="
          lang == 'ru-RU' ||
          lang == 'tr-TR' ||
          lang == 'es' ||
          $store.getters.deviceInfo.type != 'PC'
            ? 'top'
            : 'right'
        "
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <!-- 用户类型 -->
          <template
            v-if="
              item.inputType == 'select' &&
              item.selectKey == 'option.userList.user_type_options'
            "
          >
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
            >
              <el-option
                v-for="option in $t(item.selectKey)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- 归属经销商 -->
          <template
            v-else-if="
              item.inputType == 'select' &&
              item.selectKey == 'option.userList.dealer_options'
            "
          >
            <el-select
              v-model="form.dealer"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
              @change="selectChange2"
            >
              <el-option
                v-for="option in options"
                :key="option.id"
                :label="option.username"
                :value="option.id"
              >
              </el-option>
            </el-select>
          </template>
          <!-- 归属地 -->
          <template
            v-else-if="
              item.inputType == 'select' && item.selectKey == 'belong_options'
            "
          >
            <el-cascader
              style="width: 268px"
              v-model="form.belong"
              :options="belong_options"
            ></el-cascader>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <!-- <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                  maxlength="10"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button type="primary" class="btn save" size="small">
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template> -->
            <template>
              <el-input
                size="small"
                :show-password="item.inputModel == 'pwd'"
                @input="change($event)"
                :maxlength="
                  item.inputModel == 'pwd' || item.inputModel == 'tel'
                    ? '15'
                    : '10'
                "
                style="width: 268px"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
      <el-button
        type="primary"
        class="btn save"
        size="small"
        @click="submitForm"
      >
        {{ $t("dialog.userList.submit") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import {
  agentList,
  regionList,
  addUser,
  checkAccount,
  updUser,
} from "@/api/index";
export default {
  name: "EditUser",
  components: {
    CustomDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    // 校验密码
    var validatePass = (rule, value, callback) => {
      console.log(value, "vvvvvvvvv")
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (hz.test(value) || fh.test(value)) {
        // console.log(value);
        callback(new Error("请输入数字或字母"));
      } else {
        if (this.form.repassword !== "") {
          this.$refs.form.validateField("repassword");
        }
        callback();
      }
    };
    // 校验确认密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    // 校验手机号
    var validatePhone = (rule, value, callback) => {
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (!re.test(value)) {
        callback(new Error("手机号格式错误请重新输入"));
      } else {
        callback();
      }
    };
    // 用户名称
    var validateName = (rule, value, callback) => {
      var pattern = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error("请输入用户名称"));
      } else if (pattern.test(value)) {
        callback(new Error("请输入文字、数字或字母"));
      } else {
        callback();
      }
    };
    // 校验账号
    var validateAccount = (rule, value, callback) => {
      let sz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      // console.log(this.form)
      // var sz = /\d/;
      var pattern = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error("请输入账号"));
      } else if (sz.test(value) || pattern.test(value)) {
        callback(new Error("请输入字母、数字或下划线"));
      } else {
        // callback() // 记得注释掉
        // 接口判断账号是否存在
        let param = {
          account: this.form.account,
        };
        checkAccount(param).then((res) => {
          if (res.code === 200) {
            if (res.data.exist) {
              callback(new Error("该账号已存在"));
            } else {
              callback();
            }
          }
        });
      }
      // if(!value){
      // 	callback(new Error('请输入用户名称'));
      // } else if (re.test(value)) {
      // 	callback(new Error('请输入文字、数字或字母'));
      // } else {
      // 	callback();
      // }
    };
    return {
      options: [], // 归属经销商下拉
      belong_options: [{
        label: "中国",
        value: "中国",
        key: "0",
        children: []
      }, {
        label: "国外",
        value: "国外",
        key: "1",
        children: []
      }], // 归属地下拉
      formItem: [
        {
          label: "dialog.userList.user_type",
          key: "accountType",
          inputType: "select",
          selectKey: "option.userList.user_type_options",
        },
        {
          label: "dialog.userList.user_name",
          key: "name",
          inputType: "input",
        },
        {
          label: "dialog.userList.account",
          key: "account",
          inputType: "input",
        },
        {
          label: "dialog.userList.password",
          key: "password",
          inputType: "input",
          inputModel: "pwd",
        },
        {
          label: "dialog.userList.repassword",
          key: "repassword",
          inputType: "input",
          inputModel: "pwd",
        },
        {
          label: "dialog.userList.belong",
          key: "belong",
          inputType: "select",
          selectKey: "belong_options",
        },
        {
          label: "dialog.userList.contact",
          key: "contact",
          inputType: "input",
          inputModel: "tel",
        },
        {
          label: "dialog.userList.dealer",
          key: "dealer",
          inputType: "select",
          selectKey: "option.userList.dealer_options",
        },
      ],
      form: {
        name: "",
        contact: "",
        account: "",
        password: "",
        repassword: "",
        belong: [],
        accountType: "",
        dealer: "" 
      },
      rules: {
        accountType: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        account: [
          { required: true, validator: validateAccount, trigger: "blur" },
        ],
        password: [
          // { required: true, validator: validatePass, trigger: "blur" },
          {
            required: true,
            min: 6,
            message: "密码长度不能小于六位",
            trigger: "blur",
          },
          // { required: true, max:15,message: '密码最大长度为15位', trigger: 'blur'}
        ],
        repassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        contact: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        belong: [
          { required: true, message: "请选择归属地", trigger: "change" },
        ],
        dealer: [
          { required: true, message: "请选择归属经销商", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.rowInfo && this.rowInfo.account !== JSON.parse(localStorage.getItem("username"))) {
        return this.formItem.filter(
          (f) =>
            f.key == "name" ||
            f.key == "contact" ||
            f.key == "dealer" ||
            f.key == "belong"
        );
      } else if (
        this.rowInfo &&
        this.rowInfo.id &&
        this.rowInfo.account == JSON.parse(localStorage.getItem("username"))
      ) {
        return this.formItem.filter(
          (f) => f.key == "name" || f.key == "contact" || f.key == "belong"
        );
      } else {
        return this.formItem;
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    rowInfo: {
      handler(val) {
        if (val) {
          let c = val.class == '0' ? '中国' : "国外"
          this.form = this.$tool.clone(val);
          this.form.accountType = this.form.accountType === "经销商" ? 2 : 1;
          this.form.belong = [c,val.province, val.city, val.district];
          this.form.dealer = val.dealer.id;
          console.log(this.form.belong, "222");
          this.getAgencyList(val.id);
        } else {
          this.form = {
            name: "",
            contact: "",
            belong: "",
            account: "",
            accountType: 1,
            dealer: "",
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    // this.getAgencyList();
  },
  mounted() {
    this.getAgencyList();
    this.getRegionList(0)
    this.getRegionList(1)
  },
  methods: {
    // 获取下拉地区
    getRegionList(type) {
      let param = {
        // belongId: "",
        class: type
      }
      regionList(param).then((res) => {
        if (res.code == 200) {
          // console.log(res.data, "data")
          // this.toTree(res.data)
          res.data.map(item=>{
            item.pid = item.value.length > 2 ? item.value.slice(0, -2) : 0
          })
          if(type == 0){
            this.belong_options[0].children = this.generateOptions(res.data)
          } else {
            this.belong_options[1].children = this.generateOptions(res.data)
          }
        }
      });
    },
    toTree(data){
      let result = []
      data.map(item=>{
        let parent = {}
        if(item.value.length == 2){
          parent = {
            label: item.label,
            value: item.value
          }
          parent.children = this.toChilds(item.value, data)
          result.push(parent)
        }
      })
      console.log(result, "result")
    },
    toChilds(value, arr){
      let childs = []
      arr.map(item=>{
        if(item.value.startsWith(value) && item.value.length - value.length == 2){
          childs.push({
            label: item.label, 
            value: item.value
          })
        }
      })
      childs.map(item=>{
        const chs = this.toChilds(item.value, arr)
        if(chs.length > 0){
          item.children = chs
        }
      })
      return childs
    },
    // 递归处理接口数据
    generateOptions(params) {
      let result = [];
      for (const param of params) {
        if (param.pid == 0) {
          // 判断是否为顶层节点
          let parent = {
            label: param.label,
            value: param.label,
            key: param.value,
          };
          parent.children = this.getchilds(param.value, params); // 获取子节点
          result.push(parent);
        }
      }
      return result;
    },
    getchilds(value, array) {
      const childs = [];
      for (const arr of array) {
        // 循环获取子节点
        if (arr.pid === value) {
          childs.push({
            label: arr.label,
            value: arr.label,
            key: arr.value,
          });
        }
      }
      for (const child of childs) {
        // 获取子节点的子节点
        const childscopy = this.getchilds(child.key, array);
        if (childscopy.length > 0) {
          child.children = childscopy;
        }
      }
      return childs;
    },
    // 获取经销商下拉
    getAgencyList(id) {
      let param = {};
      if (id) {
        param.agent_id = id;
      }
      agentList(param).then((res) => {
        if (res.code === 200) {
          // console.log(res, "ressssssssss")
          // this.options = res.data.list
          this.options = res.data.agent_info.map((item) => {
            return {
              id: item.id,
              username: item.username,
            };
          });
        }
      });
    },
    async handleClose(done) {
      // this.form.contact = ""; 
      // this.form.accountType = 1; 
      // this.form.dealer = ""; 
      // this.form.name = "";
      // this.form.account = "";
      // this.form.password = "";
      // this.form.repassword = "";
      // this.form.belong = [];
      this.form = {
        name: "",
        contact: "",
        account: "",
        password: "",
        repassword: "",
        belong: [],
        accountType: 1,
        dealer: ""
      },
      this.$refs["form"].clearValidate();
      await this.$emit("toggleShow", false);
      await this.$refs["form"].clearValidate();
      this.getAgencyList(); // 关闭重新获取经销商列表
    },
    // 归属经销商
    selectChange2(e) {
      this.form.dealer = e;
    },
    change(e) {
      this.$forceUpdate();
    },
    submitForm() {
      if (this.rowInfo) {
        // 编辑
        let param = {
          id: this.rowInfo.id,
          // password: this.form.password,
          username: this.form.name,
          agent_id: this.form.dealer, // 归属经销商
          province: this.form.belong[1],
          city: this.form.belong[2],
          district: this.form.belong[3],
          type: this.form.accountType, // 用户or经销商
          // account: this.form.account,
          phone: this.form.contact,
        };
        // return
        this.$refs["form"].validate((valid) => {
          if (valid) {
            updUser(param).then((res) => {
              // console.log(res)
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: this.$t("dialog.userList.editUser_success"),
                  duration: 2000,
                });
                // this.$emit("toggleShow", false);
                this.$emit("refresh");
                this.handleClose()
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                  duration: 2000,
                });
              }
            });
          } else {
            return false;
          }
        });
      } else {
        // 新增
        let param = {
          username: this.form.name,
          account: this.form.account,
          password: this.form.password,
          phone: this.form.contact,
          province: this.form.belong[1],
          city: this.form.belong[2],
          district: this.form.belong[3],
          type: this.form.accountType,
          agent_id: this.form.dealer,
        };

        this.$refs["form"].validate((valid) => {
          if (valid) {
            addUser(param).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: this.$t("dialog.userList.addUser_success"),
                  duration: 2000,
                });
                this.$emit("toggleShow", false);
                this.$emit("refresh");
                this.$refs["form"].resetFields();
              } else {
                this.$message({
                  type: "warning",
                  message: res.message,
                  duration: 2000,
                });
                this.$emit("toggleShow", false);
              }
            });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: $main_color;
    border-color: $main_color;
  }

  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}

.form {
  justify-content: center;
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
.MOBIE {
  .el-form-item--small.el-form-item {
    text-align: left;
  }
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss">
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  background-color: rgba(210, 233, 206, 1);
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
}

.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
  background-color: rgba(83, 157, 86, 1);
  font-weight: 400;
}

.el-icon-check:before {
  display: none;
}
</style>

<template>
  <div class="pointsItem">
    <div class="label">
      {{ data.title  || data.id}}
      <i class="el-icon-error delete" v-if="ability.includes('section_del')" @click="_deletePoints(data)"></i>
    </div>
    <div class="items">
      <div class="item" v-for="index in 2" :key="index">
        <el-form label-width="auto" size="small">
          <el-form-item :label="$t('label.positionMap.lon')">
            <el-input readonly="readonly" v-model="data.points[index - 1].lon"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.positionMap.lat')">
            <el-input readonly="readonly"  v-model="data.points[index - 1].lat"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex" style="line-height: 32px;font-size: 14px;color: #606266;" v-if="data.showSpeed">
        <span>穿越流速：</span>
        <p>{{ data.speed||0 }}m/s</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AddPoints",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: null
    },
    ability:{
      type: Array,
      default: () => {
        return []
      }
    },
  },
  inject:["_deletePoints"]
}
</script>

<style lang="scss" scoped>
.pointsItem {
  border-radius: 8px;
  border: 1px solid #999999;
  &+.pointsItem{
    margin-top: 20px;
  }
  &>div {
    padding: 0 20px;
  }

  .label {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    position: relative;
    .delete{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #999999;
      cursor: pointer;
    }
  }

  .items {
    padding-top: 10px;
    padding-bottom: 10px;

    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 2px;
    }
    .item{
      &+.item{
        margin-top: 20px;
      }
    }
  }
}</style>
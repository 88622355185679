<template>
  <custom-dialog
    :show="show"
    :title="$t('dialog.userList.add_equip')"
    @toggleShow="handleClose"
    :appendToBody="true"
    width="398"
  >
    <div class="flex form" slot="main">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="90px"
        label-position="left"
        hide-required-asterisk
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <template v-if="item.inputType == 'select'">
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
            >
              <el-option
                v-for="option in $t(item.selectKey)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button
                  type="primary"
                  class="btn save"
                  @click="add"
                  size="small"
                >
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-input
                size="small"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose()"
      >
        {{ $t("public.close") }}
      </el-button>
      <el-button type="primary" class="btn save" size="small" :loading="done" :disabled="done" @click="add">
        {{ $t("public.save") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import { addDevice, checkSN } from "@/api/index";
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "EditSn",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 校验账号
    var validateSN = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入SN号"));
      } else if (value.length != 10) {
        callback(new Error("请输入10位的SN号"));
      } else {
        let param = {
          sn: value,
          model: 1
        };
        checkSN(param).then((res) => {
          if (res.code == 200) {
            if (res.data.exist) {
              callback(new Error("该SN已存在，请重新输入！"));
            } else {
              callback();
            }
          }
        });
      }
    };
    return {
      done: false,
      formItem: [
        {
          label: "dialog.userList.query_sn",
          key: "sn",
          inputType: "input",
        },
      ],
      form: {
        sn: "",
      },
      rules: {
        sn: [{ required: true, validator: validateSN, trigger: "blur" }],
      },
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.formItem.filter(
          (f) => f.key == "accountType" || f.key == "contact"
        );
      } else {
        return this.formItem;
      }
    },
  },
  watch: {
    show: {
      handler(val) {
        this.form = {
          sn: "",
        };
      },
      deep: true,
    },
  },
  methods: {
    handleClose(done) {
      this.$emit("toggleShow", false);
      this.$refs.form.clearValidate();
      this.done = false
    },
    add() {
      let param = {
        sn: this.form.sn,
        model: 1
        // pn: "123456",
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.done = true
          addDevice(param).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                // message: "设备添加成功！",
                message: this.$t("dialog.userList.add_success_info"),
                duration: 2000,
              });
              this.$emit("toggleShow", false);
              this.$emit("refush");
              this.done = false
            } else {
              this.done = false
              this.$message({
                type: "error",
                message: res.msg,
                duration: 2000,
              });
            }
          });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
    margin-left: 30px;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
  }
}
.form {
  justify-content: center;
}
</style>

<template>
	<div class="receiverList">
		<div class="pageTitle export">
			<span style="font-weight: 600; color: #333333"
				>{{ $t("label.dataManage.page_title") }}：</span
			>
			<el-button @click="exportD" size="small">导出</el-button>
		</div>
		<template v-if="$store.getters.deviceInfo.type === 'PC'">
			<div class="table">
				<el-table
					:data="list"
					ref="tableData"
					stripe
					:max-height="tableHeight"
					:size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'"
				>
					<el-table-column type="selection" width="55"> </el-table-column>
					<el-table-column
						v-for="column in columns"
						:key="column"
						:prop="column"
						:label="$t(`label.receiverList.${column}`)"
						align="center"
						:min-width="column == 'time' ? '150px' : '80px'"
					>
						<template slot-scope="{ row }">
							<!-- <template v-if="column == 'speed'">
                {{ row[column].toFixed(2) }}
              </template>
              <template v-else-if="column == 'azimuth'">
                {{ row[column].toFixed(1) }}
              </template> -->
							<template>
								{{ row[column] }}
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination">
				<el-pagination
					background
					:layout="
						$store.getters.deviceInfo.type == 'PC'
							? 'total, prev, pager, next, jumper'
							: 'prev, pager, next'
					"
					:total="total"
					popper-class="mySelectType"
					:current-page="page"
					@current-change="currentChange"
					:small="$store.getters.deviceInfo.type != 'PC'"
				>
				</el-pagination>
			</div>
		</template>
		<div class="list" v-else>
			<vant-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<div class="item" v-for="(item, i) in list" :key="`${item.sn}_${i}`">
					<van-cell-group>
						<van-cell title="序号" :value="item.index" />
						<van-cell title="设备类型" :value="item.type" />
						<van-cell title="设备SN" :value="item.sn" />
						<van-cell title="测量时间" :value="item.time" />
						<van-cell title="经纬度(°)" :value="`${item.lng},${item.lat}`" />
						<van-cell title="速度(m/s)" :value="item.speed" />
						<van-cell title="方位角(°)" :value="item.azimuth" />
					</van-cell-group>
				</div>
			</vant-list>
		</div>
	</div>
</template>
  
  <script>
	import { List, CellGroup, Cell } from "vant";
	export default {
		name: "List",
		props: {
			current: {
				type: String,
				default: "2",
			},
			list: {
				type: Array,
				default: () => {
					return [];
				},
			},
			page: {
				type: Number,
				default: 1,
			},
			pageSize: {
				type: Number,
				default: 10,
			},
			total: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				dateValue: [],
				columns: [
					"index",
					"type",
					"sn",
					"time",
					"lng",
					"lat",
					"speed",
					"azimuth",
				],
				snValue: [], // 搜索SN
				// total: 20,
				choose: [],
				selectValue: [], // 选择的设备
				tableHeight: 450,
				checkAll: false,
				loading: false,
				finished: false,
			};
		},
		components: {
			VantList: List,
			VanCellGroup: CellGroup,
			VanCell: Cell,
		},
		watch: {
			"$store.getters.deviceInfo": {
				handler(val) {
					if (val.type === "PC") {
						this.$nextTick(() => {
							this.$refs.tableData.doLayout();
						});
					}
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			if (this.$store.getters.deviceInfo.type === "PC") {
				this.$nextTick(() => {
					this.tableHeight =
						window.innerHeight - this.$refs.tableData.$el.offsetHeight - 355;
				});
			}
		},
		methods: {
			// 导出
			exportD() {
				this.$emit("export");
			},
			// 页数
			currentChange(e) {
				this.$emit("update:page", e);
			},
			onLoad() {
				if (this.list.length >= this.total) {
					this.finished = true;
					return;
				}
				this.currentChange(this.page + 1);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
				// console.log(this.list);
			},
		},
	};
</script>
  
  <style lang="scss" scoped>
	.receiverList {
		display: flex;
		flex-direction: column;
		// height: 100%;
		flex: 1;
		.list {
			flex: 1;
      overflow: hidden;
      overflow-y: auto;
      margin-top: 12px;
			// .van-list{
      //   height: 100%;
      //   overflow-y: auto;
      // }
			.item {
				border-radius: 8px;
				border: 1px solid #979797;
				overflow: hidden;
				& + .item {
					margin-top: 10px;
				}
			}
		}
		.table {
			flex: 1;
			margin-bottom: 50px;
			border-radius: 2px 2px 0px 0px;
			overflow: hidden;
			&::v-deep th.el-table__cell {
				background-color: $table_header;
				color: #fff;
			}
			&::v-deep td.el-table__cell {
				background: transparent !important;
			}
			&::v-deep .el-table__row--striped {
				background-color: #d2e9ce;
			}
		}
		.export {
			display: flex;
			justify-content: space-between;
			line-height: 30px !important;
			& > .el-button {
				color: #fff;
				background-color: #539d56;
				border: 1px solid #fff;
			}
		}
		.pagination {
			// display: flex;
			// align-items: center;
			// justify-content: flex-end;
			// margin-top: 12px;
			position: fixed;
			bottom: 0px;
			right: 20px;
			// width: 100%;
			display: flex;
			justify-content: flex-end;
			background-color: #ffffff;
			z-index: 999;
			padding-bottom: 15px;
			width: calc(100% - 230px);
		}
		::v-deep {
			.el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #3b9167;
				color: #fff;
			}
			.el-pagination.is-background .btn-next,
			.el-pagination.is-background .btn-prev,
			.el-pagination.is-background .el-pager li {
				background-color: #ffffff;
				border: 1px solid #e8e8e8;
			}

			.el-pagination.is-background .el-pager li:hover {
				color: #3b9167;
			}

			.el-pagination__sizes .el-input .el-input__inner:hover {
				border-color: #3b9167;
			}

			.el-select .el-input__inner:focus {
				border-color: #3b9167;
			}

			.el-select-dropdown__item.selected {
				color: #3b9167 !important;
			}

			.el-button--success.is-disabled {
				background-color: #c0c4cc;
				border-color: #c0c4cc;
			}

			// .el-pagination.is-background .el-pager li:not(.disabled):hover {
			// 	color: #3b9167;
			// }
		}
	}

	.MOBIE {
		.pagination {
			position: unset;
			width: 100%;
			padding: 0;
			margin-top: 16px;
			// margin-bottom: 16px;
		}
	}
	.selectsLabel {
		margin-left: 10px;
	}
</style>
  
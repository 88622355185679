<template>
	<div class="mainMap">
		<template v-if="showMap">
			<Map
				ref="posMap"
				:modules="modules"
				@inited="init"
				:ability="ability"
				:autoSectionLoad="autoSectionLoad"
				:domId="domId"
				:autoLocate="autoLocate"
			></Map>
		</template>
		<map-popup ref="popup"></map-popup>
	</div>
</template>

<script>
	import Map from "./map.vue";
	import MapPopup from "./components/mapPopup.vue";
	import mapPopup from "./components/mapPopup";
	export default {
		components: {
			Map,
			MapPopup
		},
		props: {
			domId: {
				type: String,
				default: "FlowBallR12PositionMap",
			},
			modules: {
				type: Array,
				default: () => {
					return [];
				},
			},
			autoLocate: {
				type: Boolean,
				default: true,
			},
			ability: {
				type: Array,
				default: () => {
					return [];
				},
			},
			autoSectionLoad: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				showMap: false,
				dict:{
					"ballPoint":"ballDetail",
					"trackPoints":"trackPointDetail",
					"intersectPoints":"intersectPointDetail",
					"sectionLine":"sectionLineDetail",
					"computIntersectPoint":"computIntersectPoint"
				}
			};
		},
		provide(){
			return{
				_openDetail:this.openDetail
			}
		},
		watch: {
			modules: {
				handler(val) {
					// if(val && val.length){
					localStorage.setItem("current_map_modules", val);
					setTimeout(() => {
						this.showMap = true;
					}, 0);
					// }
				},
				deep: true,
				immediate: true,
			},
		},
		methods: {
			init(val) {
				if (this.showMap) {
					this.$emit("inited", val);
				}
			},
			getVm() {
				return new Promise((res) => {
					this.$nextTick(() => {
						let temp = this.$refs.posMap;
						res(temp && temp.push ? temp[0] : temp);
					});
				});
			},
			openDetail(e){
				this.$refs.popup.open({
					...e,
					cells:mapPopup[this.dict[e.data.type]]
				});
			}
		},
	};
</script>

<style scoped lang="scss">
	.mainMap {
		position: relative;
		width: 100%;
		height: 100%;
	}
</style>
<template>
  <div class="timeRule" :class="deviceInfo.type" draggable="false">
    <div class="leftInfo" draggable="false">
      <el-tooltip class="item" effect="dark" :content="this.isPlay ? '暂停播放' : '播放轨迹'" placement="top">
        <i style="font-size: 30px; color: var(--iconColor,#fff)" :class="{
    'el-icon-video-play': !this.isPlay,
    'el-icon-video-pause': this.isPlay,
  }" @click="playHandler"></i>
      </el-tooltip>
    </div>
    <template v-if="deviceInfo.type === 'PC'">
      <div class="speedBox">
        <el-select v-model="playspeed" placeholder="请选择" class="speed" size="small">
          <el-option v-for="item in speedOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="timeRuleContainer" draggable="false">
      <template v-if="deviceInfo.type === 'PC'">
        <div class="ruleBack">
          <div v-for="item in backs" :key="item.key" :data-d="item.end" :style="{
    left: item.left + '%',
    width: item.width + '%',
    backgroundColor: item.color,
  }"></div>
        </div>
        <div ref="ruleBlock" class="ruleBlock" v-drag-x draggable="false">
          <div class="block" draggable="false"></div>
          <div class="blockInfo" v-if="showLabel" :style="isLast ? 'transform: translateX(-35%);' : ''">
            {{
    indexOverviewSingleCurrentDate
      ? $moment(indexOverviewSingleCurrentDate).format(
        "yyyy-MM-DD HH:mm:ss"
      )
      : $moment(indexOverviewSingleEndTime).format(
        "yyyy-MM-DD HH:mm:ss"
      )
  }}
          </div>
        </div>
        <div ref="ruleSymbol" class="ruleSymbol" draggable="false" @click="ruleClickHandler">
          <div v-for="item in stops" :key="item.key" draggable="false"
            :class="{ symbol: true, mainSymbol: item.isMain }"
            :style="{ left: item.left + '%', width: item.width + '%' }">
            <span v-if="item.label" style="position: fixed; margin-top: -4px; margin-left: 2px" draggable="false">{{
    item.label }}</span>
          </div>
        </div>
      </template>
      <div class="flex slider" v-else>
        <van-slider :min="0" bar-height="4px" button-size="12px" active-color="rgba(83,157,86,0.6)" :max="100"
          v-model="progress" :step="0.01" @input="handlerProgress" />
        <span class="label" :class="{
    lastOne: isLast
  }" :style="{
    '--progress': `${progress}%`
  }">{{
    indexOverviewSingleCurrentDate
      ? $moment(indexOverviewSingleCurrentDate).format(
        "yyyy-MM-DD HH:mm:ss"
      )
      : $moment(indexOverviewSingleEndTime).format(
        "yyyy-MM-DD HH:mm:ss"
      )
  }}</span>
      </div>

    </div>
    <template v-if="deviceInfo.type !== 'PC'">
      <div class="speedBox">
        <el-select v-model="playspeed" placeholder="请选择" class="speed" size="small">
          <el-option v-for="item in speedOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="rightInfo" v-else draggable="false"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Slider } from 'vant';
export default {
  name: "TimeRule",
  components: {
    'van-slider': Slider
  },
  data() {
    return {
      indexOverviewSingleCurrentDate: new Date(),
      width: 0,
      stops: [],
      backs: [],
      isPlay: false,
      interval: null,
      playspeed: "1",
      indexOverviewSingleLastDataTime: new Date(this.lastDataTime),
      speedOptions: [
        {
          value: "1",
          label: "×1",
        },
        {
          value: "2",
          label: "×2",
        },
        {
          value: "4",
          label: "×4",
        },
        {
          value: "8",
          label: "×8",
        },
      ],
      progress: 0
    };
  },
  props: {
    daterange: {
      type: Array,
      default: () => {
        return [
          new Date(new Date().getTime() - 3600 * 1000 * 24 * 1),
          new Date(),
        ]
      },
    },
    timestates: {
      type: Array,
      default: () => {
        return []
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    lastDataTime: {
      type: [String, Number],
      default: (new Date()).getTime(),
    }
  },
  computed: {
    ...mapGetters(["deviceInfo"]),
    indexOverviewSingleStartTime() {
      return this.daterange.length
        ? new Date(this.daterange[0])
        : new Date(new Date().getTime() - 3600 * 1000 * 24 * 1);
    },
    indexOverviewSingleEndTime() {
      return this.daterange.length ? new Date(this.daterange[1]) : new Date();
    },
    isLast() {
      return new Date(this.indexOverviewSingleCurrentDate).getTime() === new Date(this.indexOverviewSingleLastDataTime).getTime();
    },
    // indexOverviewSingleCurrentDate(){
    //   return new Date()
    // },
    indexOverviewSingleHistoryStates() {
      return this.timestates;
    },
    totalSeconds() {
      return (
        (this.indexOverviewSingleEndTime.getTime() -
          this.indexOverviewSingleStartTime.getTime()) /
        1000
      );
    },
  },
  watch: {
    daterange: {
      handler(newVal, oldVal) {
        if (newVal.length) {
          this.indexOverviewSingleCurrentDate = new Date(newVal[0]);
          this.indexOverviewSingleLastDataTime = new Date(newVal[1]);
        }
      },
      deep: true,
      immediate: true
    },
    deviceInfo() {
      this.$nextTick(() => {
        this.init();
      });
    },
    indexOverviewSingleEndTime(n, o) {
      this.$nextTick(() => {
        this.init();
      });
    },
    indexOverviewSingleCurrentDate(val) {
      this.updateRuleBlock();
    },
    indexOverviewSingleHistoryStates(val) {
      this.$nextTick(() => {
        this.updateBackground();
      });
    },
    isPlay(val) {
      if (val) {
        this.interval = setInterval(() => {
          let toDate =
            new Date(this.indexOverviewSingleCurrentDate).getTime() + 5 * 1000;
          let lastDate = new Date(this.indexOverviewSingleLastDataTime).getTime();
          // 将当前的经纬度设置给车模型
          if (
            toDate > lastDate
          ) {
            toDate = lastDate;
            this.isPlay = false;
          } else if (
            toDate == lastDate
          ) {
            clearInterval(this.interval);
            this.interval = null;
            this.isPlay = false;
          }
          // this.$store.commit("SET_OVERVIEW_MAP_SINGLECURRENTDATE", new Date(toDate))
          this.setCurrentDate(new Date(toDate));
          return;
        }, 100 / this.playspeed);
      } else {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    },
    playspeed() {
      this.isPlay = false;
      this.$nextTick(() => {
        this.isPlay = true;
      });
    },
  },
  mounted() {
    this.indexOverviewSingleCurrentDate = this.daterange[0]
      ? new Date(this.daterange[0])
      : new Date();
    this.init();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  directives: {
    dragX: {
      bind: (el, binding, vnode) => {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          oDiv.style.cursor = "grabbing";
          let disX = e.clientX - oDiv.offsetLeft;
          document.onmousemove = (e) => {
            let left = e.clientX - disX;
            if (left < 0) {
              left = 0;
            }
            let pwidth = oDiv.parentElement.offsetWidth;
            if (left > pwidth) {
              left = pwidth;
            }

            let date = new Date(
              vnode.context.indexOverviewSingleStartTime.getTime() +
              (left / pwidth) * vnode.context.totalSeconds * 1000
            );
            if (
              date.getTime() >
              new Date(vnode.context.indexOverviewSingleLastDataTime).getTime()
            ) {
              date = vnode.context.indexOverviewSingleLastDataTime;
            }
            vnode.context.setCurrentDate(date);
            // vnode.context.$store.commit("SET_OVERVIEW_MAP_SINGLECURRENTDATE", date)
          };

          document.onmouseup = (e) => {
            oDiv.style.cursor = "grab";
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  methods: {
    init() {
      if (this.deviceInfo.type === 'PC') {
        this.$nextTick(() => {
          this.width = this.$refs.ruleSymbol.offsetWidth;
          this.generateSymbol();
        })

      }
    },
    setCurrentDate(date) {
      this.indexOverviewSingleCurrentDate = date;
      this.$emit("setCurrentLocation", date);
    },
    handlerProgress(e) {
      // console.log(e);
      let x = e / 100 * this.totalSeconds * 1000 + this.indexOverviewSingleStartTime.getTime();
      // console.log(x);
      this.setCurrentDate(new Date(x))
      // ((this.indexOverviewSingleCurrentDate.getTime() -
      //   this.indexOverviewSingleStartTime.getTime()) /
      //   1000 /
      //   this.totalSeconds);
    },
    generateSymbol() {
      let t_pr = (this.totalSeconds / this.width) * 20;
      let pr = parseInt(t_pr - (t_pr % (30 * 60)));
      let hours = 0;
      if (pr / 60 / 60 > 48) {
        hours = 48 * 60 * 60;
      } else if (pr / 60 / 60 > 24) {
        hours = 24 * 60 * 60;
      } else if (pr / 60 / 60 > 12) {
        hours = 12 * 60 * 60;
      } else if (pr / 60 / 60 > 8) {
        hours = 8 * 60 * 60;
      } else if (pr / 60 / 60 > 6) {
        hours = 6 * 60 * 60;
      } else if (pr / 60 / 60 > 4) {
        hours = 4 * 60 * 60;
      } else if (pr / 60 / 60 > 3) {
        hours = 3 * 60 * 60;
      } else if (pr / 60 / 60 > 2) {
        hours = 2 * 60 * 60;
      } else if (pr / 60 / 60 > 1) {
        hours = 60 * 60;
      } else {
        hours = 30 * 60;
      }

      let arr = [],
        count = 0;
      for (
        let t = this.indexOverviewSingleStartTime.getTime() / 1000;
        t <= this.indexOverviewSingleEndTime.getTime() / 1000;
        t = t + hours
      ) {
        arr.push({
          left:
            ((t - this.indexOverviewSingleStartTime.getTime() / 1000) /
              this.totalSeconds) *
            100,
          width: (hours / this.totalSeconds) * 100,
          name: this.$moment(t * 1000).format("yyyy-MM-DD HH:mm:ss"),
          label:
            count % 12 == 0 &&
              t != this.indexOverviewSingleEndTime.getTime() / 1000
              ? this.$moment(t * 1000).format("MM月DD HH时")
              : "",
          isMain: count % 12 == 0 ? true : false,
          key: t,
        });
        count++;
      }
      this.stops = arr;
    },
    updateRuleBlock() {
      // console.log(this.indexOverviewSingleCurrentDate,this.indexOverviewSingleStartTime,'updateRuleBlock')
      let left = 100;
      if (this.indexOverviewSingleCurrentDate) {
        left =
          ((this.indexOverviewSingleCurrentDate.getTime() -
            this.indexOverviewSingleStartTime.getTime()) /
            1000 /
            this.totalSeconds) *
          100;
      }
      if (this.deviceInfo.type === 'PC') {
        this.$refs.ruleBlock.style.left = left + "%";
      } else {
        this.progress = left;
      }
    },
    ruleClickHandler(e) {
      // if (e.offsetX > this.$refs.ruleBlock.offsetLeft) {
      //     this.$store.commit("SET_OVERVIEW_MAP_SINGLECURRENTDATE", new Date(this.indexOverviewSingleCurrentDate.getTime() + 5 * 1000))
      // } else if (e.offsetX < this.$refs.ruleBlock.offsetLeft) {
      //     this.$store.commit("SET_OVERVIEW_MAP_SINGLECURRENTDATE", new Date(this.indexOverviewSingleCurrentDate.getTime() - 5 * 1000))
      // }
    },
    updateBackground() {
      let re = [];
      if (this.indexOverviewSingleHistoryStates.length > 0) {
        this.indexOverviewSingleHistoryStates.forEach((item, index) => {
          // console.log(item.end,'enee')
          if (
            index == 0 &&
            item.start != new Date(this.indexOverviewSingleStartTime).getTime()
          ) {
            // 为啥状态起始事件跟筛选起始时间不同（筛选时间跟轨迹时间不重合）
            let width =
              ((item.start -
                new Date(this.indexOverviewSingleStartTime).getTime()) /
                1000 /
                this.totalSeconds) *
              100;
            // console.log(222,this.indexOverviewSingleStartTime.getTime())
            re.push({
              key: this.indexOverviewSingleStartTime.getTime(),
              left: 0,
              width: width,
              color: "red",
            });
          }
          if (index == this.indexOverviewSingleHistoryStates.length - 1) {
            let end =
              new Date(this.indexOverviewSingleEndTime).getTime() <
                new Date(this.indexOverviewSingleLastDataTime).getTime()
                ? new Date(this.indexOverviewSingleEndTime).getTime()
                : new Date(this.indexOverviewSingleLastDataTime).getTime();
            let width = ((end - item.end) / 1000 / this.totalSeconds) * 100;
            let left =
              ((item.end -
                new Date(this.indexOverviewSingleStartTime).getTime()) /
                1000 /
                this.totalSeconds) *
              100;
            re.push({
              key: item.end,
              left: left,
              width: width,
              color: "red",
            });
          }

          let width =
            ((item.end - item.start) / 1000 / this.totalSeconds) * 100;
          if (width == 0) {
            return;
          }
          let left =
            ((item.start -
              new Date(this.indexOverviewSingleStartTime).getTime()) /
              1000 /
              this.totalSeconds) *
            100;
          //
          let color =
            item.state == -1 ? "red" : item.state == "0" ? "blue" : "green";
          re.push({
            key: item.start,
            left: left,
            width: width,
            color: color,
          });
        });
      }
      this.backs = re;
    },
    playHandler() {
      this.isPlay = !this.isPlay;
    },
  },
};
</script>

<style scoped lang="scss">
.timeRule {
  user-select: none;
  display: flex;
  width: 100%;
  height: 50px;
  background: var(--bgColor, rgba(0, 32, 47, 0.6));
  border-top: var(--borderTopColor, rgba(0, 32, 47, 0.8))solid 1px;

  .leftInfo {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;



  }

  .speedBox {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-16px);

    .speed {
      width: 50px;
      // margin-left: 15px;

      ::v-deep .el-input {
        input {
          background: transparent !important;
          padding: 0px;
          color: var(--mainColor, #fff);
          text-align: center;
        }

        .el-input__suffix {
          display: none;
        }
      }
    }
  }


  .timeRuleContainer {
    width: calc(100% - 220px);
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(50%, -50%);
      width: 1px;
      height: 100%;
      background-color: #1f2d3d;
      pointer-events: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 1px;
      height: 100%;
      background-color: #1f2d3d;
      pointer-events: none;
    }

    .ruleBack {
      height: 100%;
      width: 100%;
      position: absolute;

      div {
        bottom: 0px;
        height: 12px;
        display: inline-block;
        position: absolute;
        opacity: 0.5;
      }
    }

    .ruleBlock {
      position: absolute;
      width: 1px;
      height: 100%;
      border-left: solid 2px #00bab8;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      .block {
        height: 50%;
        width: 15px;
        background: var(--barColor, #00f6ff);
        position: absolute;
        border-radius: 2px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }

      .blockInfo {
        width: 130px;
        padding: 4px;
        position: absolute;
        top: -25px;
        color: var(--mainColor, #fff);
        font-size: 13px;
        background: var(--bgColor, rgba(0, 32, 47, 0.6));
        border: 5px;
      }
    }

    .ruleSymbol {
      position: relative;
      height: 100%;
      overflow-x: hidden;

      .symbol {
        pointer-events: none;
        position: absolute;
        bottom: 0px;
        width: 0px;
        border-left: 1px solid var(--mainColor, #fff);
        font-size: 12px;
        color: var(--mainColor, #fff);
        height: 12px;
        user-select: none;

        &.mainSymbol {
          height: 25px;
        }
      }
    }
  }

  .rightInfo {
    width: 30px;
    height: 100%;
    // border-left: solid 1px #1f2d3d;
  }
}

.MOBIE {
  .timeRuleContainer {

    &::before,
    &::after {
      display: none;
    }
  }

  .slider {
    height: 100%;
    position: relative;

    // &::before {
    //   content: "";
    //   left: 50%;
    //   top: 0;
    //   width: calc(100% + 26px);
    //   height: 100%;
    //   position: absolute;
    //   z-index: 5;
    //   transform: translateX(-50%);
    // }

    .label {
      width: 130px;
      padding: 4px;
      position: absolute;
      top: 0;
      transform: translate(-50%, -100%);
      left: var(--progress);
      color: var(--mainColor, #fff);
      font-size: 13px;
      background: var(--bgColor, rgba(0, 32, 47, 0.6));
      border: 5px;
    }

    ::v-deep {
      .van-slider__button {
        background-color: #539D56;
      }
    }

    .lastOne {
      transform: translate(-35%, -100%);
    }
  }

  .speedBox {
    transform: translateX(0px);
  }
}
</style>

<template>
  <div class="container">
    <div class="container_name">{{ $t("label.nmea.title") }}</div>
    <div v-for="(item, index) in title" :key="index" class="container_item">
      <div class="container_title">{{ item }}：</div>
      <el-select v-model="form[item]" size="mini">
        <el-option
          v-for="item in (item == 'RMC'? $t('option.postSet.RMC_options') : $t('option.postSet.NMEA_options'))"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="container_info">{{ $t(`label.nmea[${index}]`) }}</div>
    </div>
    <div class="btn">
      <el-button type="primary" size="mini" @click="save">{{
        $t("label.nmea.btn")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { getNMEA, setNMEA } from "@/api/index" 
export default {
  data() {
    return {
      title: [
        "GGA", //
        "RMC", //
        // "GLL", //
        // "GSA", //
        // "GST", //
        // "GSV", //
        // "VTG", //
        // "ZDA", //
        // "DTM",
        // "GBS",
        // "GNS",
        // "GRS",
        // "THS",
        // "ROT",
      ],
      form: {
        GGA: "0",
        GLL: "0",
        GSA: "0",
        GST: "0",
        GSV: "0",
        RMC: "0",
        VTG: "0",
        ZDA: "0",
        DTM: "0",
        GBS: "0",

        // GNS: "0",
        // GRS: "0",
        // THS: "0",
        // ROT: "0",
      },
    };
  },

  mounted() {
    this.initNMEA()
  },
  computed: {

  },
  methods: {
    initNMEA(){
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 1
      }
      // 获取NMEA初始数据
      getNMEA(param).then(res=>{
        if(res.code == 200){
          this.form.GGA = res.data.gga + ""
          this.form.RMC = res.data.rmc + ""
        }
      })
    },
    save() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 1,
        gga: this.form.GGA,
        rmc: this.form.RMC
      }
      setNMEA(param).then(res=>{
        if(res.code == 200){
          this.$message({
            type: 'success',
            message: "NMEA消息配置成功！"
          })
        }else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
      // console.log(this.form, "ffff");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  font-size: 14px;
  padding: 10px 20px;
  &_name {
    padding: 10px 0;
  }
  &_title {
    min-width: 50px;
    // margin-right: 10px;
  }
  &_item {
    display: flex;
    align-items: center;
    line-height: 40px;
  }
  &_info {
    margin-left: 20px;
  }
  .btn {
    margin: 10px 0;
    .el-button {
      border: none;
    }
    .el-button--primary {
      background-color: #659b5d;
    }
  }
}
</style>
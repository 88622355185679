/**
 * 混入设置
 */
import ball from "./ball";
import section from "./section";
import track from "./track";
export default class {
  constructor(modules) {
    this.ball = ball;
    this.section = section;
    this.track = track;
    this.modules = modules || [];
  }
  getMixins(types = this.modules) {
    return types.map(f => {
      return this[f];
    })
  }
  async setMixins(types = []) {
    localStorage.setItem("current_map_modules", types);
    return true
  }
}
<template>
	<Popup v-model="currentShow" :close-on-click-overlay="false" @click-overlay="close" @cancel="close" position="bottom" round>
		<van-picker show-toolbar title="标题" :columns="columns"  @confirm="confirm"/>
	</Popup>
</template>

<script>
	import { Popup, Picker } from "vant";
	import getColumns from "./columns";
	export default {
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			defaultValue: {
				type: String,
				default: "00:00:00",
			},
		},
		data() {
			return {
				currentShow: false,
				// columns:getColumns(),
			};
		},
		components: {
			Popup,
			VanPicker: Picker,
		},
		watch: {
			show: {
				handler(val) {
					if (this.currentShow !== val) {
						this.currentShow = val;
					}
				},
				deep: true,
			},
		},
		computed: {
			pickTitle({ currentIndex }) {
				return (
					"选择" +
					(currentIndex === 0
						? this.$t("public.start_date")
						: this.$t("public.end_date"))
				);
			},
			columns() {
				let res = getColumns();
				if (this.defaultValue && this.show) {
					let _check = this.defaultValue.split(":");
					res.forEach((f, i) => {
						let index = Number(_check[i]);
						f.defaultIndex = isNaN(index) ? 0 : index;
					});
				}
				return res;
			},
		},
		methods: {
			close(){
				this.$emit("update:show",false);
			},
			confirm(e){
				this.$emit("confirm",e.join(":"));
				this.close();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.datePicker {
		.cell {
			margin-top: 6px;
		}
		.calendar {
			height: 502px;
		}
	}
</style>
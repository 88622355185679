<template>
  <div class="receiverList" :class="$store.getters.deviceInfo.type">
    <div class="fixed">
      <div class="query">
        <div class="input-width">
          <div class="search_group">
            <div :style="{display: 'flex', width: $store.getters.deviceInfo.type == 'PC' ? '' : '100%'}">
              <span>设备 SN ：</span>
              <el-select
                v-model="snValue"
                multiple
                filterable
                collapse-tags
                :multiple-limit="1"
                style="flex: 1"
                size="small"
                :placeholder="`${$t('label.positionMap.sn')}`"
                @change="handlerSnFilter"
                :filter-method="filterOption"
              >
                <!-- <div class="cell checkAll" v-if="options.length">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  @change="handleCheckAllChange"
                  v-model="checkAll"
                >
                  {{ $t("label.positionMap.checkAll") }}
                </el-checkbox>
              </div> -->
                <el-checkbox-group v-model="snValue">
                  <el-option
                    v-for="item in showOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="cell">
                      <el-checkbox
                        :label="item.value"
                        style="pointer-events: none"
                      />
                      <span class="selectsLabel">{{ item.label }}</span>
                    </div>
                  </el-option>
                </el-checkbox-group>
              </el-select>
              <!-- <el-select v-model="snValue" multiple placeholder="请选择设备SN号" collapse-tags size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
            </div>
            <template v-if="$store.getters.deviceInfo.type == 'PC'">
              <el-date-picker
                size="small"
                :style="
                  $store.getters.deviceInfo.type == 'PC'
                    ? { marginLeft: '20px' }
                    : { width: '100%', marginTop: '6px' }
                "
                v-model="dateValue"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                :picker-options="pickerOptions"
                @change="pickTime"
                end-placeholder="结束日期"
                align="right"
              >
              </el-date-picker>
            </template>
            <template v-else>
              <DatePicker v-model="dateValue" :formatter="handlerDateClass"></DatePicker>
              <!-- <div style="margin-top: 6px">
              <span>{{ $t("public.start_date") }}：</span>
              <el-date-picker
                v-model="dateValue[0]"
                type="datetime"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('public.start_date')"
              >
              </el-date-picker>
            </div>
            <div style="margin-top: 6px">
              <span>{{ $t("public.end_date") }}：</span>
              <el-date-picker
                v-model="dateValue[1]"
                size="mini"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('public.end_date')"
              >
              </el-date-picker>
            </div> -->
            </template>
            <div
              class="btn_group"
              :style="{
                width: $store.getters.deviceInfo.type == 'PC' ? '' : '100%',
                marginTop: $store.getters.deviceInfo.type == 'PC' ? '0' : '6px',
                marginLeft:
                  $store.getters.deviceInfo.type == 'PC' ? '10px' : '0',
              }"
            >
              <el-button
                size="small"
                style="background-color: #539d56"
                @click="searchList"
                :style="{
                  width: $store.getters.deviceInfo.type == 'PC' ? '' : '50%',
                }"
                >查询</el-button
              >
              <el-button
                size="small"
                style="background-color: #ef9a41"
                :style="{
                  width: $store.getters.deviceInfo.type == 'PC' ? '' : '50%',
                }"
                @click="reset"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pageTitle export">
      <span style="font-weight: 600; color: #333333"
        >{{ $t("label.dataManage.page_title") }}：</span
      >
      <el-button @click="exportD" size="small">导出</el-button>
    </div>
    <div class="table" v-if="$store.getters.deviceInfo.type == 'PC'">
      <el-table
        :data="tableData"
        ref="tableData"
        stripe
        :max-height="tableHeight"
        :size="$store.getters.deviceInfo.type == 'PC' ? 'normal' : 'mini'"
      >
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.receiverList.${column}`)"
          align="center"
          :min-width="column == 'time' ? '150px' : '80px'"
        >
          <template slot-scope="{ row }">
            <!-- <template v-if="column == 'speed'">
              {{ row[column].toFixed(2) }}
            </template>
            <template v-else-if="column == 'azimuth'">
              {{ row[column].toFixed(1) }}
            </template> -->
            <template>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <van-list
        v-model="isLoading"
        :finished="finished"
        finished-text="暂无数据"
        @load="onLoad"
      >
        <div class="MBCard" v-for="(item,index) in MBData" :key="index">
          <!-- <el-checkbox @change="tbChange($event, item)"></el-checkbox> -->
          <div class="item">
            <span>设备类型：</span>
            <span>{{ item.type }}</span>
          </div>
          <div class="item">
            <span>设备SN：</span>
            <span>{{ item.sn }}</span>
          </div>
          <div class="item">
            <span>测量时间：</span>
            <span>{{ item.time }}</span>
          </div>
          <div class="item">
            <span>经度(°)：</span>
            <span>{{ item.lng }}</span>
          </div>
          <div class="item">
            <span>纬度(°)：</span>
            <span>{{ item.lat }}</span>
          </div>
          <div class="item">
            <span>速度(m/s)：</span>
            <span>{{ item.speed }}</span>
          </div>
          <div class="item">
            <span>方位角(°)：</span>
            <span>{{ item.azimuth }}</span>
          </div>
        </div>
      </van-list>
    </div>
    <div class="pagination" v-if="$store.getters.deviceInfo.type == 'PC'">
      <el-pagination
        background
        :layout="
          $store.getters.deviceInfo.type == 'PC'
            ? 'total, prev, pager, next, jumper'
            : 'prev, pager, next'
        "
        :total="total"
        popper-class="mySelectType"
        :current-page="page"
        @current-change="currentChange"
        :small="$store.getters.deviceInfo.type != 'PC'"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { List } from "vant";
import { dataList, getSN, exportData } from "@/api/index";
import tool from "@/utils/tool.js";
import DatePicker from "@/components/public/baseDatePicker";
let _minTime = "";
let _maxTime = "";
export default {
  data() {
    let that = this;
    return {
      dateValue: [],
      columns: [
        "index",
        "type",
        "sn",
        "time",
        "lng",
        "lat",
        "speed",
        "azimuth",
      ],
      options: [
        // {
        //   value: "选项1",
        //   label: "111256478966",
        // }
      ],
      snValue: [], // 搜索SN
      tableData: [
        // {
        //   index: 1,
        //   type: 1,
        //   sn: 111111,
        //   time: 11,
        //   lng: 111,
        //   lat: 111,
        //   speed: 111,
        //   azimuth: 111,
        // },
      ],
      total: 20,
      page: 1,
      pageSize: 10,
      choose: [],
      selectValue: [], // 选择的设备
      tableHeight: 450,
      checkAll: false,
      snKeyWord: "",
      pickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 1 * 24 * 60 * 60 * 1000; // 6天
            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
        cellClassName(date) {
          return that.handlerDateClass(date);
        },
      },
      MBData: [],
      isLoading: true,
      finished: true,
    };
  },
  components: {
    DatePicker,
    VanList: List,
  },
  computed: {
    showOption() {
      if (this.snKeyWord) {
        return this.options.filter(
          (f) => f.value.indexOf(this.snKeyWord) !== -1
        );
      } else {
        return this.options;
      }
    },
    isIndeterminate() {
      // console.log(this.queryData.sns);
      if (this.snValue.length && this.options.length != this.snValue.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.tableData?.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
    dateValue: {
      handler(newV, oldV) {
        console.log(newV, "vallllll2");
      },
    },
    snValue: {
      handler(val) {
        if (val.length) {
          if (this.options.length == val.length) {
            this.checkAll = true;
          }
        } else {
          this.checkAll = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.getSNList();
  },
  mounted() {
    this.getParamData();
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
        window.innerHeight - this.$refs.tableData?.$el.offsetHeight - 140;
      this.tableHeight = height;
    });
    // this.getSNList();
  },
  methods: {
    onLoad() {
      this.page++;
      this.isLoading = true;
      this.getReceiverList();
    },
    filterOption(e) {
      this.snKeyWord = e;
    },
    getParamData() {
      if (!this.$route.query.sn) {
        return;
      }
      this.snValue = [];
      this.snValue.push(this.$route.query.sn);
      this.dateValue = this.$route.query.date;
      this.getReceiverList();
    },
    pickTime(e) {
      console.log(e);
    },
    handlerDateClass(date) {
      let { yy, mm, dd } = this.$tool.formartDate(date);
      return this._currentDates && this._currentDates[`${yy}-${mm}-${dd}`]
        ? "mark"
        : "";
    },
    handleCheckAllChange(e) {
      if (e) {
        this.snValue = this.options.map((f) => f.value);
      } else {
        this.snValue = [];
      }
      this.handlerSnFilter(this.snValue);
    },
    clearDateMask() {
      this._currentDates = null;
      let tds = document.querySelectorAll(this.$store.getters.deviceInfo.type === 'PC' ? "td.mark" : "div.mark");
      for (let index = 0; index < tds.length; index++) {
        const element = tds[index];
        element.className = element.className.replace("mark", "");
      }
    },
    getDateMark() {
      this.clearDateMask();
      if (!this.snValue.length) {
        return;
      }
      this.$api.trackMark(this.snValue[0]).then((res) => {
        this._currentDates = res.data.reduce((t, e) => {
          t[e] = e;
          return t;
        }, {});
      });
    },
    handlerSnFilter(e) {
      this.snValue = e;
      this.getDateMark();
      // console.log(this.snValue, "eeeee")
      // console.log(e, "eeee")
    },
    getSNList() {
      let param = {
        sn: "",
        model: 1,
      };
      getSN(param).then((res) => {
        if (res.code == 200) {
          this.options = res.data.sn.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        }
      });
    },
    // 搜索
    searchList() {
      this.page = 1;
      this.getReceiverList()
    },
    reset() {
      this.snValue = [];
      this.dateValue = [];
      this.page = 1;
      // this.getReceiverList();
    },
    getReceiverList() {
      if (!this.snValue.length || !this.dateValue.length) {
        this.$message({
          type: "warning",
          message: "请选择SN号和查询时间",
        });
        return;
      }
      this.finished = false; 
      let param = {
        model: 1,
        page_num: this.page,
        page_size: this.pageSize,
        sn: this.snValue,
        begin_time: this.dateValue ? this.dateValue[0] : "",
        end_time: this.dateValue ? this.dateValue[1] : "",
      };
      dataList(param).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.tableData = res.data.track_info.map((item, index) => {
            return {
              index: index + 1,
              id: item.id,
              type: "R12",
              sn: item.sn,
              time: item.time,
              lng: this.$tool.toFixedFn(item.lng, 8, false),
              lat: this.$tool.toFixedFn(item.lat, 8, false),
              speed: this.$tool.toFixedFn(item.speed, 2, true),
              azimuth: this.$tool.toFixedFn(item.angel, 1, false),
            };
          });
          this.finished = res.data.track_info.length ? false : true;
          this.MBData = this.MBData.concat(this.tableData);
          this.isLoading = false;
        }
      });
    },
    // 导出
    exportD() {
      if (!this.snValue.length || !this.dateValue.length) {
        this.$message({
          type: "warning",
          message: "请选择SN号和查询时间",
        });
        return;
      } else if (this.total > 40000) {
        this.$message({
          type: "warning",
          message: "数据大小不可超过4W条！",
          duration: 2000,
        });
        return;
      } else {
        let param = {
          sn: this.snValue,
          begin_time: this.dateValue ? this.dateValue[0] : "",
          end_time: this.dateValue ? this.dateValue[1] : "",
          model: 1,
        };
        exportData(param).then((res) => {
          // tool.exportOut(res, this.getNowTime() + '.xlsx');
          tool.exportEx(res, this.getNowTime(), ".csv");
        });
      }
    },
    //获取当前时间
    getNowTime() {
      var date = new Date();
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var second =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year + "" + month + "" + day + "" + hour + "" + minute + "" + second
      );
    },

    toggleShowAddSn() {},
    // 页数
    currentChange(e) {
      this.page = e;
      this.getReceiverList();
    },
    // 提交清除数据的选中状态
    searchTableData() {
      this.$refs.tableData.clearSelection(); //清除选中的数据
    },
  },
};
</script>

<style lang="scss" scoped>
.receiverList {
  padding: 0 20px 20px;
  .fixed {
    background-color: #fff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 9;
    // top: 20px;
    // padding: 20px 0;
  }
  .MBCard {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #979797;
    border-radius: 8px;
    margin: 10px 0;
    .item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 32px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
    }
  }

  .query {
    display: flex;
    justify-content: space-between;
  }

  .input-width {
    max-width: 1000px;

    .search_group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
    }

    .btn_group {
      margin-left: 20px;
      display: flex;

      & > .el-button {
        min-width: 60px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 4px;
      }
    }
  }

  .operate-btn {
    display: flex;
  }

  .btn {
    min-width: 80px;
    height: 32px;
  }

  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  .export {
    display: flex;
    justify-content: space-between;
    line-height: 30px !important;

    & > .el-button {
      color: #fff;
      background-color: #539d56;
      border: 1px solid #fff;
      border-radius: 4px;
    }
  }

  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: 0px;
    right: 20px;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding-bottom: 15px;
    width: calc(100% - 230px);
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    .el-select .el-input__inner:focus {
      border-color: #3b9167;
    }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }

    .el-button--success.is-disabled {
      background-color: #c0c4cc;
      border-color: #c0c4cc;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }
}

.MOBIE {
  .pagination {
    position: unset;
    width: 100%;
    padding: 0;
    margin-top: 16px;
    // margin-bottom: 16px;
  }
}
.selectsLabel {
  margin-left: 10px;
}
</style>
<style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>
<style lang="scss">
.el-checkbox .el-checkbox__label {
  display: none;
}

.selectsLabel {
  margin-left: 10px;
}

.checkAll {
  padding: 0 20px;
  line-height: 34px;
  font-size: 14px;
  color: #606266;

  .el-checkbox .el-checkbox__label {
    display: inline-block;
  }

  .is-checked {
    .el-checkbox__label {
      font-weight: 700;
    }
  }
}
</style>

<template>
	<div class="flex cardItem">
		<el-checkbox v-model="checked" @change="_handlerSelect(source.id)" />
		<div class="item" @click="_handlerSelect(source.id)">
			<van-cell-group>
				<van-cell v-for="item in cells" :key="item.valueKey" :title="item.label"
					:value="source[item.valueKey] || '-'" />
				<div class="btn_group">
					<el-button size="small" style="background-color: #539d56" @click.stop="_toReview(source)">{{
			$t("label.taskManage.review") }}</el-button>
					<el-button size="small" style="background-color: #539d56" @click.stop="_toMapView(source)">{{
			$t("label.taskManage.mapView") }}</el-button>
					<el-button size="small" style="background-color: #539d56" @click.stop="_toTaskInfo(source)">{{
			$t("label.taskManage.taskInfo") }}</el-button>
					<el-button size="small" style="background-color: #539d56" @click.stop="_toEdit(source)">{{
			$t("label.taskManage.edit") }}</el-button>
				</div>
			</van-cell-group>
		</div>
	</div>
</template>

<script>
import { CellGroup, Cell } from "vant";
export default {
	name: "CardItem",
	props: {
		selects: {
			// index of current item
			type: Array,
			default(){
				return []
			}
		},
		source: {
			// here is: {uid: 'unique_1', text: 'abc'}
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		VanCellGroup: CellGroup,
		VanCell: Cell,
	},
	inject: [
		"_handlerSelect",
		"_toReview",
		"_toMapView",
		"_toTaskInfo",
		"_toEdit",
	],
	data() {
		return {
			checked: false,
			cells: [
				{
					label: "任务名称",
					valueKey: "taskName",
				},
				{
					label: "创建时间",
					valueKey: "cTime",
				},
				{
					label: "开始时间",
					valueKey: "sTime",
				},
				{
					label: "结束时间",
					valueKey: "eTime",
				},
				{
					label: "关联设备",
					valueKey: "connect",
				},
				{
					label: "坐标系统",
					valueKey: "system",
				},
			],
		};
	},
	watch: {
		selects: {
			handler(val) {
				this.checked = val.includes(this.source.id);
			},
			deep: true,
			immediate:true
		}
	},
	methods: {
	},
};
</script>

<style lang="scss" scoped>
.cardItem {
	align-items: flex-start;
	margin-bottom: 12px;
	gap: 12px;

	.item {
		flex: 1;
		border-radius: 8px;
		border: 1px solid #979797;
		overflow: hidden;

		::v-deep {

			.van-cell__value,
			.van-cell {
				color: #333333 !important;
			}
		}
	}

	.btn_group {
		display: flex;
		padding: 0 10px;
		padding-bottom: 10px;
		justify-content: center;

		&>.el-button {
			min-width: 50px;
			text-align: center;
			color: #fff;
			border: none;
			padding: 9px 10px;
		}
	}
}
</style>
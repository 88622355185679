  <template>
  <custom-dialog
    :show="show"
    :title="$t('label.postSet.page_title')"
    width="700"
    @toggleShow="handleClose"
  >
    <div class="flex form maxHigh" slot="main">
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        :rules="rules"
        :label-position="
          lang == 'ru-RU' || lang == 'tr-TR' || lang == 'es' ? 'top' : 'left'
        "
        label-width="100px"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <h3 style="margin-bottom: 10px">数据链路配置</h3>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('dialog.postSet.data_link') + ':'">
              <el-input disabled size="small" v-model="form.data_link">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('dialog.postSet.transmit_type') + ':'">
              <el-select
                class="select_input"
                v-model="form.transmit_type"
                size="small"
                style="width: 100%"
                :placeholder="$t('dialog.postSet.transmit_type')"
              >
                <el-option
                  v-for="option in $t('option.postSet.transmit_type_options')"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
              <!-- <el-input v-model="inputValue" size="small" disabled> </el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12" v-for="item in currentFormItem" :key="item.key">
            <el-form-item :label="$t(item.label) + '：'" :prop="item.key">
              <template v-if="item.inputType == 'select'">
                <el-select
                  v-model="form[item.key]"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t(item.label)"
                >
                  <el-option
                    v-for="option in $t(item.selectKey)"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else-if="item.inputType == 'input'">
                <template v-if="item.key == 'mount_point'">
                  <div class="flex" style="max-width: 200px">
                    <!-- :style="{width: form.transmit_type != '3'?'120px':''}" -->
                    <!-- :maxlength="item.key == 'mount_point' ? '15' : ''"  // 限制输入框长度-->
                    <el-input
                      v-if="form.transmit_type !== '2'"
                      size="small"
                      style="flex: 1"
                      v-model="form[item.key]"
                      :placeholder="$t(item.label)"
                    ></el-input>
                    <el-select
                      v-else
                      v-model="form.inputPointValue"
                      size="small"
                      style="width: 100%"
                      :placeholder="$t(item.label)"
                    >
                      <el-option
                        v-for="option in options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      style="margin-left: 10px"
                      type="primary"
                      v-if="form.transmit_type == '2'"
                      class="btn save"
                      size="small"
                    >
                      {{ $t("public.gain") }}
                    </el-button>
                  </div>
                </template>
                <template v-else>
                  <!-- :maxlength="item.key == 'IP' || item.key == 'password' || item.key == 'username' ? '15' : '8'" -->
                  <el-input
                    :show-password="item.key == 'password'"
                    :maxlength="item.key == 'IP' || item.key == 'password' || item.key == 'username' ? '15' : '8'"
                    size="small"
                    v-model="form[item.key]"
                    :placeholder="$t(item.label)"
                  ></el-input>
                </template>
              </template>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-if="
              ['3', '1'].includes(form.transmit_type) &&
              form.output_format == '1' &&
              form.transmit_type == '1'
            "
          >
            <h3 style="margin-bottom: 10px">数据列表</h3>
          </el-col>
          <div class="overflow_h">
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GGA') + '：'">
                <el-select
                  v-model="form.GGA"
                  size="small"
                  style="width: 100%"
                  @change="testChange"
                  :placeholder="$t('dialog.postSet.GGA')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GGA_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.RMC') + '：'">
                <el-select
                  v-model="form.RMC"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.RMC')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.RMC_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GST') + '：'">
                <el-select
                  v-model="form.GST"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GST')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GST_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GSA') + '：'">
                <el-select
                  v-model="form.GSA"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GSA')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GSA_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.ZDA') + '：'">
                <el-select
                  v-model="form.ZDA"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.ZDA')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.ZDA_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GSV') + '：'">
                <el-select
                  v-model="form.GSV"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GSV')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GSV_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.VTG') + '：'">
                <el-select
                  v-model="form.VTG"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.VTG')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.VTG_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GLL') + '：'">
                <el-select
                  v-model="form.GLL"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GLL')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GLL_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GEDOP') + '：'">
                <el-select
                  v-model="form.GEDOP"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GEDOP')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GEDOP')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GEREF') + '：'">
                <el-select
                  v-model="form.GEREF"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GEREF')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GEREF_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GESNR') + '：'">
                <el-select
                  v-model="form.GESNR"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GESNR')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GESNR_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                ['3', '1'].includes(form.transmit_type) &&
                form.output_format == '1' &&
                form.transmit_type == '1'
              "
            >
              <el-form-item :label="$t('dialog.postSet.GEVCV') + '：'">
                <el-select
                  v-model="form.GEVCV"
                  size="small"
                  style="width: 100%"
                  :placeholder="$t('dialog.postSet.GEVCV')"
                >
                  <el-option
                    v-for="option in $t('option.postSet.GEVCV_options')"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <!-- <el-col
            :span="12"
            v-if="
              ['3', '1'].includes(form.transmit_type) &&
              form.output_format == '2'
            "
          >
            <el-form-item :label="$t('dialog.postSet.RTCM_version') + '：'">
              <el-select
                v-model="form.version"
                size="small"
                style="width: 190px"
                :placeholder="$t('dialog.postSet.RTCM_version')"
              >
                <el-option
                  v-for="option in $t('option.postSet.RTCM_version_options')"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="save">
        {{ $t("dialog.userList.dispose") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import { receiverNetConfig } from "@/api/index";
export default {
  name: "EditPost",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    var validateIP = (rule, value, callback) => {
      var ip =
        /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
      if (value && !ip.test(value)) {
        return callback(new Error("请输入正确的IP地址"));
      } else {
        callback();
      }
    };
    var validatePort = (rule, value, callback) => {
      var port = /^\d{1,}$/;
      if (!port.test(value)) {
        return callback(new Error("请输入数字端口号"));
      } else {
        callback();
      }
    };
    var validateUserNamePWD = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        if (rule.field == "username") {
          callback(new Error("请输入账号"));
        } else {
          callback(new Error("请输入密码"));
        }
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error("请输入数字或字母"));
      } else {
        callback();
      }
    };
    var validateJRD = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (hz.test(value) || fh.test(value)) {
        callback(new Error("请输入数字或字母"));
      } else {
        if (this.form.repassword !== "") {
          this.$refs.form.validateField("repassword");
        }
        callback();
      }
    };
    return {
      formItem: [
        // {
        //   label: "dialog.postSet.remote_ip",
        //   key: "remote_ip",
        //   inputType: "input",
        //   transmit_type_query: ["1"],
        // },
        {
          label: "dialog.postSet.IP",
          key: "IP",
          inputType: "input",
          transmit_type_query: ["1", "2", "3"],
        },
        {
          label: "dialog.postSet.post",
          key: "post",
          inputType: "input",
          transmit_type_query: ["2", "1", "3"],
        },

        {
          label: "dialog.postSet.username",
          key: "username",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.password",
          key: "password",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.mount_point",
          key: "mount_point",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.output_format",
          key: "output_format",
          inputType: "select",
          selectKey: "option.postSet.output_format_options",
          transmit_type_query: ["1", "3"],
        },
        // {
        // 	label: "dialog.postSet.RTCM_version",
        // 	key: "version",
        // 	inputType: "select",
        // 	selectKey: "option.postSet.RTCM_version_options",
        // 	transmit_type_query: ["1", "3"],
        // },
      ],
      form: {
        data_link: "",
        transmit_type: "1",
        remote_ip: "",
        IP: "",
        post: "",
        username: "",
        password: "",
        mount_point: "",
        output_format: "",
        version: "",
        GGA: "10Hz",
        RMC: "",
        GST: "",
        //
        GSA: "",
        ZDA: "",
        GSV: "",
        VTG: "",
        GLL: "",
        GEDOP: "",
        GEREF: "",
        GESNR: "",
        GEVCV: "",
        inputPointValue: "",
      },
      inputValue: "",
      rules: {
        IP: [{ validator: validateIP, trigger: "blur" }],
        post: [
          { validator: validatePort, trigger: "blur" },
          { max: 8, message: "端口号不能超过8位", trigger: "blur" },
        ],
        username: [
          { validator: validateUserNamePWD, trigger: "blur" },
          { max: 15, message: "用户名长度不能超过15位", trigger: "blur" },
        ],
        password: [
          { validator: validateUserNamePWD, trigger: "blur" },
          { max: 15, message: "密码长度不能超过15位", trigger: "blur" },
        ],
        mount_point: [
          { validator: validateJRD, trigger: "blur" },
          { max: 15, message: "接入点长度不能超过15位", trigger: "blur" },
        ],
      },
      options: [
        {
          // 接入点设置下拉框
          value: "0",
          label: "无",
        },
        {
          value: "1",
          label: "双皮奶",
        },
        {
          value: "2",
          label: "蚵仔煎",
        },
        {
          value: "3",
          label: "龙须面",
        },
      ],
      // inputPointValue:"", // 接入点设置
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.form.transmit_type) {
        return this.formItem.filter((f) =>
          f.transmit_type_query.includes(this.form.transmit_type)
        );
      } else {
        return this.formItem;
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    rowInfo: {
      handler(val) {
        if (val) {
          this.form = {
            ...this.$tool.clone(val),
            IP: val.IPAddress,
            data_link: "内置网络",
            GGA: "10Hz",
            GSA: "1Hz",
            GST: "10S",
            RMC: "60s",
            ZDA: "5Hz",
            GSV: "2S",
            VTG: "15S",
            GLL: "0",
            GEDOP: "2Hz",
            GEREF: "5s",
            GESNR: "30S",
            GEVCV: "0",
            output_format: "2",
            transmit_type: "1",
          };
          // console.log(this.form) // 后期优化成==
          if (this.form.type.startsWith("TCP")) {
            this.form.transmit_type = "1";
            // this.form.output_format = "2"
          } else if (
            this.form.type.startsWith("Ntrip") &&
            this.form.type.includes("Server")
          ) {
            // debugger
            this.form.transmit_type = "3";
          } else {
            // debugger
            this.form.transmit_type = "2";
          }
        } else {
          this.form = {
            name: "",
            contact: "",
            account: "",
            accountType: "",
            dealer: "",
            // output_format:"2"
          };
        }
      },
      deep: true,
    },
    // "form.transmit_type": {
    //   handler(val) {
    //     this.inputValue = this.$t('option.postSet.transmit_type_options')[val - 1].label
    //   },
    //   deep: true,
    // },
  },
  mounted() {

  },
  activated() {
    // console.log(this.rowInfo)
  },
  methods: {
    testChange(e) {

    },
    handleClose(done) {
      this.$emit("toggleShow", false);
      this.$refs["form"].resetFields();
    },
    save() {
      let ipArr = this.form.IP.split('.')
      ipArr.forEach((item,index)=>{
        if(item.length == 1){
          ipArr[index] = "00" + item
        }else if(item.length == 2){
          ipArr[index] = "0" + item
        }
      })
      this.form.IP = ipArr.join('.')
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // let param = {
          //   deviceid: JSON.parse(localStorage.getItem("receiverInfo")).sn,
          //   net_config: {
          //     server_id: this.rowInfo.id,
          //     lsh: this.form.IP,
          //     lsp: this.form.post,
          //     protoc: this.form.transmit_type, //设置传输模式
          //   },
          // };
          // receiverNetConfig(param).then((res) => {
          //   console.log(res);
          //   if (res.code === 200) {
          //     this.$message({
          //       // message: "指令下发成功！",
          //       message: this.$t('dialog.userList.edit_io_success'),
          //       type: "success",
          //       duration: 2000,
          //     });
          //     this.handleClose();
          //     this.$emit('refush')
          //     // this.$parent.getIOConfig()
          //   } else {
          //     // this.$message.error('网络错误，请重新保存！');
          //     this.$message({
          //       message: res.msg,
          //       type: "error",
          //       duration: 2000,
          //     });
          //   }
          // });
          this.$emit("toggleShow", false);
          this.$refs["form"].resetFields();
        } else {
          // debugger
          // this.$refs['form'].resetFields();
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
.form {
  justify-content: center;
}
.overflow_h {
  max-height: 200px;
  width: 100%;
  overflow: auto;
}
// .select_input{
// 	::v-deep{
// 		.el-input__suffix{
// 			display: none;
// 		}
// 	}
// }
</style>
import { render, staticRenderFns } from "./satellite.vue?vue&type=template&id=46081c58&scoped=true&"
import script from "./satellite.vue?vue&type=script&lang=js&"
export * from "./satellite.vue?vue&type=script&lang=js&"
import style0 from "./satellite.vue?vue&type=style&index=0&id=46081c58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46081c58",
  null
  
)

export default component.exports
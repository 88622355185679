<template>
	<el-drawer
		title="123123"
		:visible="show"
		direction="rtl"
		:size="deviceInfo.type == 'PC' ? '95%' : '100%'"
		@close="close"
		:wrapperClosable="false"
		custom-class="taskInfo"
	>
		<template slot="title">
			<span class="drawer_title">{{ $t("label.taskManage.taskInfo") }}</span>
		</template>
		<div class="main" :class="deviceInfo.type">
			<!-- <div class="card" :style="this.openC ? 'flex:1;' : ''"> -->
			<div class="card" :style="this.openC ? 'height: 60%;' : ''">
				<!-- 展开箭头 -->
				<!-- <div
            class="arrow"
            @click="open"
            :style="this.openC ? 'transform: rotate(270deg);' : ''"
          >
            <i
              class="el-icon-d-arrow-right"
              :style="this.openC ? 'height: 60%;' : ''"
            ></i>
          </div> -->
				<div class="item">
					<div v-for="item in colunm" :key="item">
						<span>{{ $t(`label.taskManage.${item}`) }}：</span>
						<span>{{ detail[item] }}</span>
					</div>
					<div>
						<span>{{ $t(`label.taskManage.connect`) }}：</span>
						<span>{{ detail.connect }}</span>
					</div>
				</div>

				<div class="info">
					<div
						class="info_item"
						v-for="(item, index) in trackDetail"
						:key="index"
					>
						<div class="infoD" v-if="trackDetail.length">
							<div>
								<span>{{ $t(`label.taskManage.section`) }}：</span>
								<span>{{ item.section }}</span>
							</div>
						</div>
					</div>
					<div v-if="!trackDetail.length" class="noData">暂无截面</div>
				</div>
			</div>
			<!-- <div v-show="!openC"> -->
			<div v-show="!openC" class="btn_group">
				<el-button
					size=""
					@click="dataCount"
					:class="current == '2' ? 'focus' : ''"
					>{{ $t("label.taskManage.dataCount") }}</el-button
				>
				<el-button
					size=""
					@click="dataList"
					:class="current == '1' ? 'focus' : ''"
					>{{ $t("label.taskManage.dataList") }}</el-button
				>
			</div>
			<List
				v-show="current == '1' && !openC"
				:current.sync="current"
				:list="list"
				:page.sync="page"
				:pageSize.sync="pageSize"
				:total="total"
				@export="exportD"
			></List>
			<Charts
				ref="charts"
				v-show="current == '2' && !openC"
				:current.sync="current"
				:chartData="charts"
				:sn="rowInfo.connect ? rowInfo.connect.split(',') : []"
				:show="show"
			></Charts>
			<!-- </div> -->
		</div>
	</el-drawer>
</template>
    
<script>
	import { getTaskInfo, chartsList, dataList, exportData } from "@/api/index";
	import Charts from "./charts.vue";
	import List from "./list.vue";
	import tool from "@/utils/tool.js";
	export default {
		data() {
			return {
				colunm: ["taskName", "cTime", "sTime", "eTime", "system"],
				current: "2",
				openC: false,
				detail: [],
				charts: null,
				trackDetail: [],
				page: 1,
				pageSize: 10,
				list: [],
				total: 0,
			};
		},
		components: {
			Charts,
			List,
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			id: {
				type: String,
				default: "",
			},
			rowInfo: {
				type: Object,
				default: null,
			},
		},
		computed: {
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
		},
		mounted() {
			console.log(this.deviceInfo.type);
		},
		watch: {
			// show: {
			//   async handler(val){await this.$nextTick(()=>{
			//     // this.$refs.charts.charts.resize()
			//     // console.log(this.$refs.charts, "ccccc")
			//   });
			//     // console.log(this.$refs.charts.charts.resize(), "ccccc")
			//   }
			// },
			rowInfo: {
				handler(val) {
					if (val && this.show) {
						this.getInfo().then((res) => {
							this.getCharts();
						});

						// this.dataList()
					}
				},
				deep: true,
			},
			page: {
				handler(val) {
					console.log(val, "pageeee");
					this.dataList();
				},
			},
			pageSize: {
				handler(val) {
					console.log(val, "pageSize");
				},
			},
		},
		methods: {
			exportD() {
				if (this.total > 40000) {
					this.$message({
						type: "warning",
						message: "数据大小不可超过4W条！",
						duration: 2000,
					});
					return;
				} else {
					let param = {
						coordinate_id: this.rowInfo.system_id,
						sn: this.rowInfo.connect.split(","),
						begin_time: this.rowInfo.sTime,
						end_time: this.rowInfo.eTime,
						model: 1,
					};
					exportData(param).then((res) => {
						tool.exportEx(res, this.getNowTime(), ".csv");
					});
				}
			},
			getNowTime() {
				var date = new Date();
				var year = date.getFullYear();
				var month =
					date.getMonth() + 1 < 10
						? "0" + (date.getMonth() + 1)
						: date.getMonth() + 1;
				var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				var minute =
					date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				var second =
					date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				return (
					year + "" + month + "" + day + "" + hour + "" + minute + "" + second
				);
			},
			getInfo() {
				let param = {
					task_id: this.rowInfo.id,
				};
				// return Promise.resolve(
				return getTaskInfo(param).then((res) => {
					// console.log(res, "rrrrrr")
					if (res.code == 200) {
						this.detail = {
							taskName: res.data.name,
							cTime: res.data.created_at,
							sTime: res.data.begin_at,
							eTime: res.data.end_at,
							system: res.data.coordinate_info.coordinate_name
								? res.data.coordinate_info.coordinate_name
								: "-",
							connect: res.data.sn.join(","),
						};
						this.trackDetail = res.data.line_info.map((item) => {
							return {
								section: item.line_name,
								p1_lon: item.begin_lon + "°",
								p2_lon: item.end_lon + "°",
								p1_lat: item.begin_lat + "°",
								p2_lat: item.end_lat + "°",
							};
						});
					}
				});
				// );
			},
			getCharts() {
				let param = {
					// related_sns: ["1222299991"],
					// begin_at: 1690867699,
					// end_at: 1693546099,
					related_sns: this.rowInfo.connect.split(","),
					begin_at: this.getTimeFormat(this.rowInfo.sTime),
					end_at: this.getTimeFormat(this.rowInfo.eTime),
				};
				// console.log(param, "ppp");
				chartsList(param).then((res) => {
					this.charts = res.data;
					console.log(this.charts, "ccc");
				});
			},
			// 年月日时分秒转时间戳
			getTimeFormat(timeS) {
				let time = new Date(timeS).getTime() / 1000; //除1000 是变成秒级的时间戳 不除就是毫秒级
				return time;
			},
			close() {
				this.$emit("update:show", false);
				this.$emit("update:rowInfo", {});
				this.current = "2";
			},
			dataList() {
				console.log(3123213);
				if (this.current != "1") {
					this.current = "1";
				}
				let param = {
					model: 1,
					page_num: this.page,
					page_size: this.pageSize,
					sn: this.rowInfo.connect.split(","),
					begin_time: this.rowInfo.sTime,
					end_time: this.rowInfo.eTime,
				};
				dataList(param).then((res) => {
					this.total = res.data.total;
					let list = res.data.track_info.map((item, index) => {
						return {
							index: index + 1,
							id: item.id,
							type: "R12",
							sn: item.sn,
							time: item.time,
							lng: this.$tool.toFixedFn(item.lng, 8, false),
							lat: this.$tool.toFixedFn(item.lat, 8, false),
							speed: this.$tool.toFixedFn(item.speed, 2, true),
							azimuth: this.$tool.toFixedFn(item.angel, 1, false),
						};
					});
					if(this.$store.getters.deviceInfo.type === "PC"){
						this.list = list;
					}else{
						this.list.push(...list);
					}
					
				});
				// console.log("数据列表");
			},
			dataCount() {
				this.current = "2";
				// console.log("数据统计");
			},
		},
	};
</script>
    
<style lang="scss" scoped>
	.drawer_title {
		flex: 1;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
	}
	.main {
		padding: 0 15px 10px 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.card {
			position: relative;
			width: 100%;
			// max-height: 220px;
			height: 130px;
			z-index: 99;
			overflow: auto;
			border: 1px solid #979797;
			border-radius: 10px;
			font-size: 14px;
			padding: 5px;
			display: flex;
			flex-direction: column;
			// flex: 1;
			.arrow {
				position: absolute;
				bottom: -10px;
				left: 49%;
				width: 20px;
				height: 20px;
				border-radius: 10px;
				font-size: 16px;
				transform: rotate(90deg);
				background-color: #f2f2f2;
				i {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					color: #539d56;
					font-weight: 600;
				}
				//   background-color: #000;
				cursor: pointer;
			}
			.item {
				display: flex;
				// flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				//   padding: 5px 0;
				margin: 10px 0;
			}
			.info {
				width: 100%;
				display: flex;
				// justify-content: space-between;
				// align-items: center;
				flex-wrap: wrap;
				// overflow-x: hidden;
				height: 120px;
				z-index: 9;
				flex: 1;
				.noData {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f2f2f2;
					width: 100%;
					height: 100%;
				}
				.info_item {
					width: 24%;
					height: 50px;
					margin: 0 10px 10px 0;

					// margin: 10px 0;
					background-color: #f2f2f2;
					// border-radius: 10px;
					.infoD {
						margin: 15px 5px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						div {
							// width: 60%;
							white-space: nowrap;
						}
					}
				}
			}
		}
		.btn_group {
			margin: 10px 0 5px 0;
			display: flex;
			.focus {
				background-color: rgba(83, 157, 86, 0.2);
				color: #539d56;
				border: 1px solid #539d56;
			}
			& > .el-button {
				min-width: 60px;
				//   color: #fff;
				//   border: 1px solid #fff;
			}
		}
	}
	.MOBIE {
		overflow-y: auto;
		.card {
			height: auto;
			overflow: unset;
			.item {
				flex-direction: column;
				align-items: stretch;
				margin: 0;
				padding: 0 10px;
				& > div {
					min-height: 35px;
					display: flex;
					position: relative;
					justify-content: space-between;
					border-bottom: 1px solid #efefef;
					align-items: center;
				}
				span:last-child {
					max-width: 60%;
					overflow: hidden;
					word-break: break-word;
					text-align: right;
					line-height: 2;
					padding: 2px 0;
				}
			}
			.info {
				flex-direction: column;
				height: auto;
				padding: 0 10px;
				.info_item {
					width: 100%;
				}
			}
		}
		.charts {
			display: block;

			&::v-deep {
				.detail {
					width: 100%;
					height: auto;
					background-color: #fff;
					margin: 4px 0;
					.item {
						flex-direction: column;
						text-align: center;
						line-height: 2;
						& div {
							&:first-child {
								transform: translateY(100%);
							}
							&:last-child {
								transform: translateY(-100%);
							}
						}
					}
				}
				.myCharts {
					min-height: 350px;
				}
			}
		}
		.receiverList {
			height: 414px;
			flex: none;
		}
	}
</style>
<style lang="scss">
	.taskInfo {
		//  ::v-deep{
		.el-drawer__header {
			margin-bottom: 20px;
			padding: 10px 20px 0 10px;
		}
		//  }
	}
</style>
    
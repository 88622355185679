<template>
  <div class="locationInfo" :class="[deviceInfo.type]">
    <div class="locationInfo-item" :class="[deviceInfo.type]" v-for="item in detailList" :key="item.label">
      <div class="title">
        {{ item.label }}
      </div>
      <div class="flex content">
        <div class="item" v-for="cell in item.items" :key="`${item.label}_${cell.value}`">
          <span class="locationInfo-item-label"> {{ cell.label }}: </span>
          <span class="locationInfo-item-value">
            <template v-if="cell.value == 'ram'">
              {{ $t('label.info.data_total') }}
              {{ $t('public.total') }}
              {{ (detail && detail.baseBoard ? detail.baseBoard.totalStorage : '') || 'N/A' }}G
              {{ $t('public.surplus') }}
              {{ (detail && detail.baseBoard ? detail.baseBoard.remainStorage : '') || 'N/A' }}G
            </template>
            <template v-else>
              {{ handleData(cell.value) || 'N/A' }}
            </template>

          </span>
        </div>
      </div>
    </div>
    <div class="locationInfo-item">
      <div class="flex locationInfo-btns">
        <el-button v-for="btn in btns" :key="btn.type" type="success" class="btn cancel" size="small"
          @click="openMessageDialog(btn.type)" :loading="btn.type === 'reset' ? resetLoading : restartModelLoading">
          {{ $t(btn.label) }}
        </el-button>
      </div>
    </div>
    <ConfirmDialog :title="message.title" :message="message.content" :show="showMessageDialog" @toggleShow="closeDia"
      @submit="handleBtnClick"></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from "../../components/public/confirmDialog";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      detailList: [
        {
          label: this.$t("label.receiverInfo.baseplate"),
          items: [
            {
              label: this.$t("label.receiverInfo.type"),
              value: ["model"],
            }, {
              label: this.$t("label.receiverInfo.mainboard_version"),
              value: ["mainboard_version"],
            }, {
              label: this.$t("label.receiverInfo.PN"),
              value: ['pn'],
            }, {
              label: this.$t("label.receiverInfo.SN"),
              value: ['sn'],
            },

          ]
        },
        {
          label: this.$t("label.receiverInfo.mainboard"),
          items: [
            {
              label: this.$t("label.receiverInfo.gnss_version"),
              // value: ["mainboard_version"],
              value: ["baseboard_version"],
            },
          ]
        },
        // {
        //   label: this.$t("label.receiverInfo.4G"),
        //   items: [
        //     {
        //       label: this.$t("label.receiverInfo.firmware_version"),
        //       // value: ["fourthGenerationFirmwareVar"],
        //       value: ["net_version"],
        //     },
        //   ]
        // },
      ],
      detail: null,
      showMessageDialog: false,
      message: {
        title: this.$t("public.tip"),
        content: "",
      },
      btns: [
        {
          label: "public.restart",
          type: "reset",
          loading: 'resetLoading'
        },
        {
          label: "public.restartModel",
          type: "restartModel",
          loading: 'restartModelLoading'
        }
      ],
      resetLoading: false,
      restartModelLoading: false,
      currentType: "",
    };
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let param = {
        sn: this.$store.getters.currentSn,
        model: 1
      };
      this.$api.deviceDetail(param).then((res) => {
        if (res.code === 200) {
          // 对返回字段的key处理
          this.detail = res.data;
          this.detail.model = this.detail.model == 1 ? "R12" : "R51" 
          this.$store.commit("SET_RECEIVER", res.data)
          sessionStorage.setItem("currentPn", res.data.pn)
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    openMessageDialog(type) {
      switch (type) {
        case "reset":
          this.message.content = this.$t("message.info.reset");
          break;
        case "restartModel":
          this.message.content = this.$t("message.info.resetModel");
          break;
        default:
          break;
      }
      if (this.message.content) {
        this.currentType = type
        this.showMessageDialog = true;
      } else {
        this.currentType = ''
      }
    },
    handleData(key) {
      if (!this.detail) {
        return ''
      }
      if (key.length == 1) {
        return this.detail[key[0]]
      }
      if (key.length == 2) {
        return this.detail[key[0]] ? this.detail[key[0]][key[1]] : ""
      }
    },
    closeDia() {
      this.showMessageDialog = false;
    },
    handleBtnClick() {
      let methods = null;
      let msg = '';
      let msgErr = '';
      let query = {
        sn: this.$store.getters.currentSn,
        model: 1
      }
      let loading = '';
      switch (this.currentType) {
        case "reset":
          // query.model = "1";
          query.num = 1
          msg = this.$t("label.info.reset_success");
          msgErr = this.$t("label.info.reset_err");
          methods = this.$api.deviceReset;
          loading = "resetLoading"
          break;
        case "restartModel":
          query.num = 2;
          // query.model = "1";
          msg = this.$t("label.info.reset_model_success");
          msgErr = this.$t("label.info.reset_model_err");
          methods = this.$api.deviceReset;
          loading = "restartModelLoading"
          break;
        default:
          break;
      }
      console.log(loading);
      if (methods) {
        this[loading] = true;
        methods(query).then((res) => {
          setTimeout(() => {
            this.$message.success(msg)
            this[loading] = false;
          }, 1000)
        }).catch(() => {
          setTimeout(() => {
            this.$message.warning(msgErr)
            this[loading] = false;
          }, 1500)
        })
      }
      this.closeDia();
    }
  },
};
</script>

<style lang="scss" scoped>
.locationInfo {
  padding: 10px;

  &-item {
    font-size: 14px;

    .content {
      flex-wrap: wrap;
      margin-top: 0px !important;


    }

    &-value {
      line-height: 32px;
    }
  }

  &-btns {
    justify-content: space-between;
    padding: 10px 0;
    // margin-top: 30px;

    .btn {
      min-width: 110px;
      height: 32px;

    }

    .save {
      background-color: $main_color;
      border-color: $main_color;
    }

    .cancel {
      border-color: $main_color;
      background-color: #539D56;
      color: #fff;
    }
  }
}

.PC {
  .locationInfo-item {
    max-width: 538px;
    border-radius: 4px;
    border: 1px solid #539D56;
    padding: 0 10px;

    &+.locationInfo-item {
      margin-top: 20px;
    }

    .title {
      line-height: 40px;
      height: 40px;
      border-bottom: 1px solid #539D56;
    }

    .content {
      flex-wrap: wrap;
      margin-top: 0px !important;

      .item {
        width: 50%;
      }
    }
  }
}

.MOBIE {
  .locationInfo-item {
    justify-content: space-between;

    .title {
      height: 34px;
      line-height: 34px;
    }

    .content {
      flex-wrap: wrap;
      margin-top: 0px !important;
      padding: 0 10px;
      border: 1px solid #539D56;
      border-radius: 8px;

      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    }

    .locationInfo-btns {
      flex-wrap: wrap;
      // gap: 20px;

      .btn {
        width: 48%;
        margin-top: 20px;

        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }

  }
}
</style>
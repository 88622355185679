<template>
  <div class="baseMap" v-loading="loading">
    <div class="map" :id="id"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language"; // 设置中文语言包
import Measure from "./measure";
import "mapbox-gl/src/css/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { getHtmlFromVM } from "./popup";
export default {
  props: {
    // 地图dom id
    id: {
      type: String,
      required: true,
      default: "baseMap",
    },
    // 默认中心点
    center: {
      type: Array,
      default: () => {
        return [121.32417, 31.16974];
      },
    },
    // 自定义初始化参数
    initOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      mapTool: {
        measure: false,
      },
      defaultOption: {
        measure: true,
      },
      points: {},
    };
  },
  created() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaHllb25namlua2ltIiwiYSI6ImNpZXh4dXp5eDA2YjFzaGtyOGR2dnBza2oifQ.a5K673tSr0cOcYoX1rpPhg";
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  watch: {
    "$store.getters.sideNavOpen": {
      handler() {
        setTimeout(() => {
          if (this.map) {
            this.map.resize();
          }
        }, 500);
      },
    },
    "$store.getters.deviceInfo": {
      handler() {
        setTimeout(() => {
          if (this.map) {
            this.map.resize();
          }
        }, 500);
      },
      deep: true,
    },
  },
  methods: {
    initMap() {
      this.map = new mapboxgl.Map({
        container: this.id,
        style: {
          "version": 8,
          //字体，不写，聚合内字会消失
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
          "sources": {
            "raster-tiles": {
              "type": "raster",
              'tiles': [
                "https://t#{channel}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=#{token}",
                // "https://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
              ],
              "tileSize": 256
            },
            // "raster-tiles-road": {
            //   "type": "raster",
            //   'tiles': [
            //     "http://t0.tianditu.com/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
            //     // "https://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
            //     // "http://thematic.geoq.cn/arcgis/rest/services/StreetThematicMaps/PurplishBlue_Reference/MapServer/tile/{z}/{y}/{x}"
            //   ],
            //   "tileSize": 256
            // },
            "raster-tiles-dm": {
              "type": "raster",
              "tiles": ["https://t5.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1"],
              "tileSize": 256
            },
            "raster-tiles-bj": {
              "type": "raster",
              "tiles": ["https://t5.tianditu.gov.cn/DataServer?T=ibo_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1"],
              "tileSize": 256
            }
          },
          "layers": [
            {
              "id": "simple-tiles",
              "type": "raster",
              "source": "raster-tiles",
              "minzoom": 0,
              "maxzoom": 24
            },
            // {
            //   "id": "simple-tiles-load",
            //   "type": "raster",
            //   "source": "raster-tiles-road",
            //   "minzoom": 0,
            //   "maxzoom": 24
            // },
            {
              "id": "simple-tiles-bj",
              "type": "raster",
              "source": "raster-tiles-bj",
              "minzoom": 0,
              "maxzoom": 18
            }, {
              "id": "simple-tiles-dm",
              "type": "raster",
              "source": "raster-tiles-dm",
              "minzoom": 0,
              "maxzoom": 18
            }
          ]
        },
        center: this.center,
        zoom: 6,
        maxZoom: 24, // 最大缩放
        minZoom: 0, // 最小缩放
        pitch: 0, //地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
        bearing: 0, //地图的初始方向，值是北的逆时针度数，默认是0，即是正北
        antialias: true, //抗锯齿，通过false关闭提升性能
        ...this.initOption,
        transformRequest:(url, resourceType) => {
          return {
            url:url.replace("#{channel}", this.$tool.getMapChannal()).replace("#{token}", this.$tool.getMapKey())
          }
        }
      });

      this.map.on("load", () => {
        this.map.addControl(
          new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: "metric",
          }),
          "bottom-right"
        );
        let geolocation = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: false,
          showUserHeading: false
        })
        geolocation.on('geolocate', () => {
          console.log('A geolocate event has occurred.');
        });
        this.map.addControl(geolocation, "bottom-left");
        this.map.addControl(new mapboxgl.NavigationControl(), "bottom-left");
        this.initMeasureTool();
        this.map.doubleClickZoom.disable(); // 禁止鼠标双击缩放
        this.addMethods();
        // this.handlerUnit();
        // this.map.on("move", () => {
        //   this.handlerUnit();
        // });
      });
      this.map.on("style.load", () => {
        this.$emit("styleLoad");
      });
      // this.map.addControl(new MapboxLanguage({ defaultLanguage: "zh-Hans" }));
    },
    handlerUnit() {
      if (document.getElementsByClassName("mapboxgl-ctrl-scale").length) {
        let t = document.getElementsByClassName("mapboxgl-ctrl-scale")[0]
          .innerHTML;
        if (t.indexOf("米") != -1) {
          document.getElementsByClassName("mapboxgl-ctrl-scale")[0].innerHTML =
            t.slice(0, t.length - 2) + "m";
        } else {
          document.getElementsByClassName("mapboxgl-ctrl-scale")[0].innerHTML =
            t.slice(0, t.length - 2) + "km";
        }
      }
    },
    initMeasureTool() {
      // this.measureTools = new Measure(this.map, this.measureComplete);
      // this.map._measureTools = this.measureTools;
      this.map._measureTools = {
        point: this.choosePoint,
        clear: this.clearPoint,
      };
    },
    addMethods() {
      // this.map.tp = new this.$trackPlay().init(false, 0.2);
      // this.map.tp = new TrackPlay(false, 0.2);
      this.map._loadImage = (image) => {
        return new Promise((resolve, reject) => {
          this.map.loadImage(image, (error, image) => {
            if (error) {
              resolve({
                success: false,
                error,
              });
            } else {
              resolve({
                success: true,
                data: image,
              });
            }
          });
        });
      };

      // 组件转html
      this.map._vmToPopup = this.handlerVMToPopup;
      this.map.updateSize = () => {
        this.map.resize();
      };

      this.map.getUUID = this.getUUID;
      this.$emit("load", this.map);
    },
    baseClick(e) {
      let pop = new mapboxgl.Popup({
        className: "overview-pop-class",
        closeButton: true,
        closeOnClick: false,
      });
      let position = [
        Number(e.lngLat.lng).toFixed(8),
        Number(e.lngLat.lat).toFixed(8),
      ];
      let key = this.getUUID();
      pop
        .setLngLat(position)
        .setHTML(
          '<div style="margin-top:15px">经纬度：' +
          position.join(",") +
          " </div>"
        )
        .addTo(this.map);
      pop.on("close", (e) => {
        delete this.points[e.target.key];
      });
      pop.key = key;
      this.points[key] = { pop: pop, type: "point" };
      if (this.cb) {
        this.cb(position);
      }
      setTimeout(() => {
        this.clearPoint(false);
      }, 0);
    },
    clearPoint(flag = true) {
      if (flag) {
        for (let key in this.points) {
          if (this.points[key].pop) {
            this.points[key].pop.remove();
          }
        }
        this.points = {};
      }
      this.map.getCanvas().style.cursor = "";
      this.map.off("click", this.baseClick);
      this.cb = null;
    },
    choosePoint(cb) {
      this.cb = cb;
      this.map.on("click", this.baseClick);
    },
    handleMapImg(url, width = 20, height = 20) {
      return new Promise((res) => {
        let image = new Image(width, height);
        // 这里的imageUrl采用require方式调用的base64格式
        image.src = url;
        image.onload = () => {
          res(image);
        };
      });
    },
    handlerVMToPopup(Option) {
      return new mapboxgl.Popup({ className: "customPopup" }).setDOMContent(
        getHtmlFromVM(Option)
      );
    },

    getUUID() {
      return Math.random().toString(36).substring(3, 10);
    },
    rangeMeasure() {
      this.measureTools.distance();
    },
    pointMeasure() {
      this.measureTools.point();
    },
    areaMeasure() {
      this.measureTools.area();
    },
    measureComplete(e) {
      this.$emit("measure", e);
    },
    operationMap(type) {
      this.mapTool[type] = !this.mapTool[type];
    },
  },
};
</script>

<style lang="scss" scoped>
.baseMap {
  width: 100%;
  height: 100%;
  position: relative;

  .map {
    height: 100%;
  }
}
</style>

<style lang="scss">
a.mapboxgl-ctrl-logo {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  transform: translateY(-50%);
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}
</style>

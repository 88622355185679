<template>
	<el-drawer :title="(currentSn ? `${currentSn}/` : '') + $t('dialog.historyTrack.title')
		" custom-class="addSectionPoint" :visible="show" direction="rtl" :size="`calc(100% - ${$store.getters.deviceInfo.type == 'PC'
		? $store.getters.sideNavOpen
			? '210px'
			: '64px'
		: '0px'
		})`" @close="close" :destroy-on-close="true">
		<div class="flex-dc historyTrack" :style="{
		'--bgColor': '#fff',
		'--mainColor': '#666',
		'--barColor': '#539D56',
		'--iconColor': '#539D56',
	}" :class="$store.getters.deviceInfo.type" v-loading="!init || loading">
			<div class="historyTrack-map" :class="$store.getters.deviceInfo.type">
				<main-map v-if="init" ref="map" @inited="loaded" domId="trackMap"></main-map>
				<div class="flex sns" :class="{
		flexCenter: !_customDetail
	}" v-if="detail.sn && detail.sn.length">
					<div class="dateIcon" v-if="_customDetail && $store.getters.deviceInfo.type !== 'PC'">
						<DatePicker v-model="dateArr" @confirm="getTrackData" :formatter="handlerDateClass"></DatePicker>
					</div>
					<div class="flex item">
						<el-radio-group v-model="currentSn" @change="getTrackData" size="small">
							<template v-if="$store.getters.deviceInfo.type !== 'PC'">
								<el-radio-button v-for="item in detail.sn" :key="item" :label="item">
									{{ item }}
								</el-radio-button>
							</template>
							<template v-else>
								<el-radio v-for="item in detail.sn" :key="item" :label="item">
									{{ item }}
								</el-radio>
							</template>
						</el-radio-group>
					</div>
					<div class="flex item date" v-if="_customDetail && $store.getters.deviceInfo.type === 'PC'">
						<el-date-picker v-model="dateArr" size="small" :picker-options="pickerOptions" type="datetimerange"
							:range-separator="$t('public.separator')" :start-placeholder="$t('public.start_date')"
							:end-placeholder="$t('public.end_date')" value-format="yyyy-MM-dd HH:mm:ss">
						</el-date-picker>
						<el-button size="small" class="btn" @click="getTrackData">
							{{ $t("public.query") }}
						</el-button>
					</div>

				</div>
			</div>
			<time-rule v-if="!loading && daterange.length" :show-label="true" :daterange="daterange"
				@setCurrentLocation="setCurrentLocation"></time-rule>
		</div>
	</el-drawer>
</template>

<script>
import MainMap from "../mainMap";
import TimeRule from "../timeRule";
import XEUtils from "xe-utils";
import { point, lineString } from "@turf/turf";
import DatePicker from "@/components/public/baseDatePicker"
export default {
	name: "historyTrack",
	components: {
		MainMap,
		TimeRule,
		DatePicker
	},
	data() {
		let that = this;
		return {
			init: false,
			loading: false,
			detail: {},
			currentSn: "",
			daterange: [],
			pickerOptions: {
				cellClassName(date) {
					return that.handlerDateClass(date);
				},
			},
			trajectoryData: [],
			dateArr: [],
			isShowPopup: false,
		};
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		info: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getDetail();
			} else {
				this.init = false;
			}
		},
	},
	inject: {
		_customDetail: {
			name: "_customDetail",
			default: null,
		},
	},
	provide() {
		return {
			_custom_create_popup: this.handlerPop,
		};
	},
	mounted() { },
	methods: {
		showPopup() {
			this.isShowPopup = true;
		},
		closePopup() {
			this.isShowPopup = false;
		},
		close() {
			this.$emit("update:show", false);
			this.$emit("update:info", {});
		},
		handlerDateClass(date) {
			let { yy, mm, dd } = this.$tool.formartDate(date);
			return this._currentDates && this._currentDates[`${yy}-${mm}-${dd}`]
				? "mark"
				: "";
		},
		clearDateMask() {
			this._currentDates = null;
			let tds = document.querySelectorAll(this.$store.getters.deviceInfo.type === 'PC' ? "td.mark" : "div.mark");
			for (let index = 0; index < tds.length; index++) {
				const element = tds[index];
				element.className = element.className.replace("mark", "");
			}
		},
		getDateMark(arr) {
			this.clearDateMask();
			this.$api.trackMark(this.currentSn).then((res) => {
				this._currentDates = res.data.reduce((t, e) => {
					t[e] = e;
					return t;
				}, {});
				this.loading = false;
			});
		},
		async getDetail() {
			this.loading = true;
			if (this._customDetail) {
				const detail = await this._customDetail();
				this.currentSn = detail.sn[0];
				this.detail = detail;
				// console.log( currentSn, detail);
				this.$nextTick(() => {
					this.init = true;

				});
				this.getDateMark();
				return;
			}
			this.$api
				.getTaskInfo({
					task_id: this.info.id,
				})
				.then((res) => {
					this.dateArr = [res.data.begin_at, res.data.end_at];
					this.currentSn = res.data.sn[0];
					this.detail = res.data;

					this.init = true;
				});
		},
		handlerPop(option) {
			option.data.detail.angle =
				this.$tool.toFixedFn(Number(option.data.detail.angel), 1) + "";
			return option;
		},
		setCurrentLocation: XEUtils.throttle(
			function (date) {
				// 设置当前车辆定位
				let ff = this.trajectoryData;
				if (ff.length < 1) return;
				let pos = null;
				let index = XEUtils.findIndexOf(ff, (item) => {
					return new Date(item.time).getTime() >= date.getTime();
				});
				if (index >= 0) {
					pos = ff[index];
				}
				if (pos) {
					// console.log(pos);
					const data = this._orgMap.getSource("playTrack")._data;
					let arr = data.geometry.coordinates.slice(0, 2);
					arr.push(
						...ff.slice(0, index).map((f) => {
							return [f.lng, f.lat];
						})
					);
					data.geometry.coordinates = arr;
					this._orgMap.getSource("playTrack").setData(data);
					this.setBallPos([pos.lng, pos.lat])
				}
			},
			1,
			{
				leading: false,
				trailing: true,
			}
		),
		loaded(map) {
			this._orgMap = map;
			// console.log(this._orgMap.hasImage("ballIcon"));
			if (!this._orgMap.getSource("currentPoint")) {
				this._orgMap.addSource("currentPoint", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: [],
					},
				});
			}
			if (!this._orgMap.getLayer("currentPoint")) {
				// 流速球图标
				this._orgMap.addLayer({
					id: "currentPoint",
					type: "symbol",
					metadata: {
						type: "ball",
					},
					source: "currentPoint", // reference the data source
					layout: {
						"icon-allow-overlap": true,
						"icon-image": "ballIcon",
						"icon-anchor": "center",
						"icon-size": 0.4,
					},
				});
			}
			if (!this._customDetail) {
				this.getTrackData(map);
			}
		},
		changeSn() {
			this.getTrackData();
		},
		setBallPos(arr) {
			this._orgMap.getSource("currentPoint").setData(point(arr));
			// console.log(point);
		},
		setDefaultLayer() {
			if (!this._map) {
				return;
			}
			if (this._map.map.getSource("playTrack")) {
				this._map.map.getSource("playTrack").setData({
					type: "FeatureCollection",
					features: [],
				});
			} else {
				this._map.map.addSource("playTrack", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: [],
					},
				});
			}
			if (!this._map.map.getLayer("playTrack")) {
				this._map.map.addLayer({
					id: "playTrack",
					type: "line",
					source: "playTrack",
					metadata: {
						type: "playTrack",
					},
					layout: {
						"line-cap": "round",
					},
					paint: {
						"line-color": "#FFFF80",
						"line-width": 8,
						"line-blur": 0.5,
						"line-opacity": 0.8,
					},
				});
			}
		},
		async getTrackData(e) {
			if (e) {
				this.loading = true;
			}
			this._map = await this.$refs.map.getVm();
			if (!this._map.map || !this.dateArr.length) {
				this.loading = false;
				return;
			}
			this._map.list = this.detail.line_info.map((f, i) => {
				let temp = {
					...f,
					title: f.line_name,
					id: f.line_id,
					points: [
						{
							lon: this.$tool.toFixedFn(f.begin_lon, 8),
							lat: this.$tool.toFixedFn(f.begin_lat, 8),
						},
						{
							lon: this.$tool.toFixedFn(f.end_lon, 8),
							lat: this.$tool.toFixedFn(f.end_lat, 8),
						},
					],
				};
				this._map.handlerSetLayerData(temp);
				return temp;
			});
			const {
				data: [res],
			} = await this.$api.chartsList({
				related_sns: [this.currentSn],
				begin_at: new Date(this.dateArr[0]).getTime() / 1000,
				end_at: new Date(this.dateArr[1]).getTime() / 1000,
			});
			this.trajectoryData = res.rows;
			this._map.map.setPaintProperty("trackLine", "line-width", 8);
			this._map.map.setLayoutProperty("trackLine", "line-cap", "round");
			this._map.map.setLayoutProperty("trackPoints", "visibility", "none");
			this.setDefaultLayer();
			let daterange = [];
			if (res.rows.length) {
				daterange = [
					new Date(res.rows[0].time).getTime(),
					new Date(res.rows[res.rows.length - 1].time).getTime(),
				];
				this._map.map.setCenter([res.rows[0].lng, res.rows[0].lat]);
				this._orgMap.getSource("playTrack").setData(
					lineString([
						[res.rows[0].lng, res.rows[0].lat],
						[res.rows[0].lng, res.rows[0].lat],
					])
				);
				this.setBallPos([res.rows[0].lng, res.rows[0].lat])
				this._map.map.setZoom(20);
			}
			this.daterange = daterange;

			this._map.originTrackData = this._map.handlerTrackData(
				this.$tool.clone(res.rows)
			);
			this._map.handlerTrackLine();
			setTimeout(() => {
				this.loading = false;
			}, 1000);
			return [];
		},
	},
};
</script>

<style lang="scss" scoped>
.historyTrack {
	height: 100%;
	width: 100%;
	padding: 20px;

	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(100% - 20px - 20px);
		height: calc(100% - 20px - 20px);
		border: 1px solid #999;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	&-map {
		flex: 1;
		position: relative;
		// ::v-deep .blockInfo {

		//   display: none;

		// }
	}

	.sns {
		position: absolute;
		top: 0;
		left: 0;
		gap: 8px;
		width: 100%;
		align-items: flex-start;

		// border-radius: 8px;
		.icon {
			display: none;
		}

		.item {
			padding: 12px 16px;
			background-color: #fff;
		}

		.date {
			flex: 1;
			gap: 8px;
		}

		&::v-deep .el-radio-group {
			display: flex;
			flex-direction: column;

			.el-radio {
				margin-right: 0;

				&+.el-radio {
					margin-top: 10px;
				}
			}
		}
	}
}

.MOBIE {
	padding: 0;

	&::before {
		display: none;
	}

	.sns {
		top: 0px;
		left: 0;
		width: calc(100%);
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 8px;

		.item {
			padding: 0;
		}

		.date {
			display: none;
		}

		.dateIcon {
			display: block;
			padding: 4px;
			background: #fff;
		}

		&::v-deep .el-radio-group {
			flex-direction: row;
		}
	}

	.flexCenter {
		flex-direction: row;
		justify-content: center;
	}
}
</style>
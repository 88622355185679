<template>
  <div>
    <el-drawer
      :visible="show"
      direction="rtl"
      :size="deviceInfo.type == 'PC' ? '45%' : '100%'"
      @close="close"
      :wrapperClosable="false"
    >
      <template slot="title">
        <!-- <span class="drawer_title">{{ $t("label.taskManage.taskInfo") }}</span> -->
        <span class="drawer_title">{{
          disabled && rowInfo.id
            ? "查看坐标系统"
            : rowInfo.id
            ? "编辑坐标系统"
            : "新建坐标系统"
        }}</span>
      </template>
      <div class="main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="110px"
          size="small"
          :disabled="disabled"
        >
          <el-form-item label="坐标系：" prop="name">
            <el-input v-model="form.name"> </el-input>
          </el-form-item>
          <div class="title">
            <span class="ball"></span>
            <span class="text">椭球基准</span>
          </div>
          <el-form-item label="椭球：" prop="ballN">
            <el-select v-model="form.ballN" placeholder="请选择">
              <el-option
                v-for="item in ballOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="长轴：" prop="long">
            <el-input disabled v-model="form.long">
              <template slot="append">m</template>
            </el-input>
          </el-form-item>
          <el-form-item label="扁率倒数：" prop="bian">
            <el-input disabled v-model="form.bian"></el-input>
          </el-form-item>
          <div class="title">
            <span class="ball"></span>
            <span class="text">基准转换</span>
          </div>
          <el-form-item label="方法：" prop="way">
            <el-select v-model="form.way" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="form.way == 2">
            <div style="display: flex; margin-left: 50px">
              <el-form-item label="DX：" prop="dx" label-width="50px">
                <el-input v-model="form.dx">
                  <template slot="append">m</template>
                </el-input>
              </el-form-item>
              <el-form-item label="RX：" prop="rx" label-width="50px">
                <el-input v-model="form.rx">
                  <template slot="append">″</template>
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; margin-left: 50px">
              <el-form-item label="DY：" prop="dy" label-width="50px">
                <el-input v-model="form.dy">
                  <template slot="append">m</template>
                </el-input>
              </el-form-item>
              <el-form-item label="RY：" prop="ry" label-width="50px">
                <el-input v-model="form.ry">
                  <template slot="append">″</template>
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; margin-left: 50px">
              <el-form-item label="DZ：" prop="dz" label-width="50px">
                <el-input v-model="form.dz">
                  <template slot="append">m</template>
                </el-input>
              </el-form-item>
              <el-form-item label="RZ：" prop="rz" label-width="50px">
                <el-input v-model="form.rz">
                  <template slot="append">″</template>
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; margin-left: 50px">
              <el-form-item label="S：" prop="s" label-width="50px">
                <el-input v-model="form.s">
                  <template slot="append">ppm</template>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="title">
            <span class="ball"></span>
            <span class="text">投影变换</span>
          </div>
          <el-form-item label="方法：" prop="way2">
            <el-select v-model="form.way2" placeholder="请选择">
              <el-option
                v-for="item in wayOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="中央经线：" prop="center">
            <el-input v-model="form.center">
              <template slot="append">°</template>
            </el-input>
          </el-form-item>
          <el-form-item label="东平移：" prop="east">
            <el-input v-model="form.east">
              <template slot="append">m</template>
            </el-input>
          </el-form-item>
          <el-form-item label="北平移：" prop="north">
            <el-input v-model="form.north">
              <template slot="append">m</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="footer" v-if="!disabled">
          <el-button @click="close" size="small">取 消</el-button>
          <el-button
            @click="submit"
            type="primary"
            size="small"
            v-if="!rowInfo.id"
            >新建</el-button
          >
          <el-button @click="edit" type="primary" size="small" v-else
            >编辑</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
      
  <script>
import { addSystem, ballParam, systemInfo, editSystem } from "@/api/index";
import {
  validateXYZ,
  validateRXYZ,
  validateS,
  validateCen,
  validateName,
  validateOffset,
} from "./validate";
export default {
  data() {
    return {
      colunm: ["taskName", "cTime", "sTime", "eTime", "system"],
      current: "1",
      openC: false,
      form: {
        name: "",
        ballN: "1",
        long: "",
        bian: "",
        way: "2",
        dx: "0",
        rx: "0",
        dy: "0",
        ry: "0",
        dz: "0",
        rz: "0",
        s: "0",
        way2: "1",
        center: "",
        east: "500000",
        north: "0",
      },
      rules: {
        dx: [{ validator: validateXYZ, trigger: "blur" }],
        dy: [{ validator: validateXYZ, trigger: "blur" }],
        dz: [{ validator: validateXYZ, trigger: "blur" }],
        rx: [{ validator: validateRXYZ, trigger: "blur" }],
        ry: [{ validator: validateRXYZ, trigger: "blur" }],
        rz: [{ validator: validateRXYZ, trigger: "blur" }],
        s: [{ validator: validateS, trigger: "blur" }],
        east: [{ validator: validateOffset, trigger: "blur" }],
        north: [{ validator: validateOffset, trigger: "blur" }],
        name: [{ validator: validateName, trigger: "blur" }],
        center: [{ validator: validateCen, trigger: "blur" }],
      },
      options: [
        {
          value: "1",
          label: "无",
        },
        {
          value: "2",
          label: "七参数",
        },
      ],
      wayOptions: [
        {
          value: "1",
          label: "高斯-克吕格",
        },
      ],
      ballOptions: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  watch: {
    "form.ballN": {
      handler(newValue, oldValue) {
        this.ballOptions.map((item) => {
          console.log(newValue, item.value);
          if (newValue == item.value) {
            this.form.long = item.long;
            this.form.bian = item.reciprocal;
          }
          // console.log(this.form, "ff");
        });
      },
      immediate: true,
      deep: true,
    },
    rowInfo: {
      handler(val) {
        if (val.id && this.show) {
          this.getSysInfo();
          console.log(val, "vvv");
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getBallParam();
  },
  methods: {
    getSysInfo() {
      let param = {
        id: this.rowInfo.id,
      };
      systemInfo(param).then((res) => {
        // console.log(res, "ressss")
        this.form.name = res.data.coordinate_name;
        // this.form.ballN = res.data.ellipsoid_info.ellipsoid_name;
        this.form.ballN = res.data.ellipsoid_info.ellipsoid_id;
        this.form.long = res.data.ellipsoid_info.long_axis;
        this.form.bian = res.data.ellipsoid_info.reciprocal;
        this.form.way = res.data.datum_transformation + "";
        this.form.dx = res.data.dx_excursion + "";
        this.form.rx = res.data.rx_spin + "";
        this.form.dy = res.data.dy_excursion + "";
        this.form.ry = res.data.ry_spin + "";
        this.form.dz = res.data.dz_excursion + "";
        this.form.rz = res.data.rz_spin + "";
        this.form.s = res.data.s_factor + "";
        this.form.way2 = res.data.projection_transformation + "";
        this.form.center = res.data.central_meridian + "";
        this.form.east = res.data.east_translation + "";
        this.form.north = res.data.north_translation + "";
      });
    },
    getBallParam() {
      ballParam().then((res) => {
        this.ballOptions = res.data.map((item) => {
          return {
            value: item.id,
            label: item.ellipsoid_name,
            long: item.long_axis,
            reciprocal: item.reciprocal,
          };
        });
        this.form.long = this.ballOptions[0].long;
        this.form.bian = this.ballOptions[0].reciprocal;
      });
    },
    //
    open() {
      this.openC = !this.openC;
      console.log(this.openC, "展开");
    },
    close() {
      this.$emit("update:show", false);
      this.$emit("update:disabled", false);
      this.$emit("update:rowInfo", {});
      this.form = {
        name: "",
        ballN: "1",
        long: "",
        bian: "",
        way: "2",
        dx: "0",
        rx: "0",
        dy: "0",
        ry: "0",
        dz: "0",
        rz: "0",
        s: "0",
        way2: "1",
        center: "",
        east: "500000",
        north: "0",
      };
      this.$refs.form.clearValidate();
    },
    edit() {
      // editSystem
      // console.log("新建")
      this.$refs["form"].validate((valid) => {
        let param = {
          coordinate_id: this.rowInfo.id,
          coordinate_name: this.form.name,
          ellipsoid_id: this.form.ballN,
          datum_transformation: this.form.way,
          dx_excursion: this.form.way == 2 ? Number(this.form.dx) : 0,
          dy_excursion: this.form.way == 2 ? Number(this.form.dy) : 0,
          dz_excursion: this.form.way == 2 ? Number(this.form.dz) : 0,
          rx_spin: this.form.way == 2 ? Number(this.form.rx) : 0,
          ry_spin: this.form.way == 2 ? Number(this.form.ry) : 0,
          rz_spin: this.form.way == 2 ? Number(this.form.rz) : 0,
          s_factor: this.form.way == 2 ? Number(this.form.s) : 0,
          projection_transformation: this.form.way2,
          central_meridian: this.form.center,
          east_translation: this.form.east,
          north_translation: this.form.north,
        };
        if (valid) {
          editSystem(param).then((res) => {
            console.log(res, "add");
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "编辑坐标系统成功",
              });
              this.close();
              this.$emit("update:show", false);
              this.$emit("refush");
            } else {
              this.$message({
                type: "warning",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 新建
    submit() {
      //
      this.$refs["form"].validate((valid) => {
        let param = {
          coordinate_name: this.form.name,
          ellipsoid_id: this.form.ballN,
          datum_transformation: this.form.way,
          dx_excursion: this.form.way == 2 ? Number(this.form.dx) : 0,
          dy_excursion: this.form.way == 2 ? Number(this.form.dy) : 0,
          dz_excursion: this.form.way == 2 ? Number(this.form.dz) : 0,
          rx_spin: this.form.way == 2 ? Number(this.form.rx) : 0,
          ry_spin: this.form.way == 2 ? Number(this.form.ry) : 0,
          rz_spin: this.form.way == 2 ? Number(this.form.rz) : 0,
          s_factor: this.form.way == 2 ? Number(this.form.s) : 0,
          projection_transformation: this.form.way2,
          central_meridian: this.form.center,
          east_translation: this.form.east,
          north_translation: this.form.north,
        };
        if (valid) {
          addSystem(param).then((res) => {
            console.log(res, "add");
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "新建坐标系统成功",
              });
              this.close();
              this.$emit("update:show", false);
              this.$emit("refush");
            } else {
              this.$message({
                type: "warning",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
      // console.log("提交");
    },
    dataList() {
      this.current = "1";
      // console.log("数据列表");
    },
    dataCount() {
      this.current = "2";
      // console.log("数据统计");
    },
  },
};
</script>
      
  <style lang="scss" scoped>
.drawer_title {
  flex: 1;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.main {
  position: relative;
  padding: 0 15px 10px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
    .ball {
      width: 15px;
      height: 15px;
      border-radius: 10px;
      background-color: #357437;
      margin: 0 10px;
    }
    .text {
      // user-select: none;
      font-size: 14px;
    }
  }
  .el-select {
    width: 100%;
  }

  .footer {
    // position: fixed;
    // bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 20px;
    .el-button {
      width: 40%;
    }
  }
  ::v-deep {
    .el-form-item__error {
      white-space: nowrap;
    }
  }
}
</style>
      